import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { DeepPartial, IAllowedCountry, IAllowedCountryListQueryParams, IAllowedMcc, IAllowedMccListQueryParams, IListResponse, IMutateAllowedMcc } from '@lib/interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class AllowedListService {
    public readonly environment_url: string;

    public constructor(private readonly http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    /**
     * Allowed MCC
     */
    public fetchAllowedMccsList(allowedMccQueryParams: DeepPartial<IAllowedMccListQueryParams>): Observable<IListResponse<IAllowedMcc>> {
        const url: URL = new URL(`${this.environment_url}nfront/v1/proxy/allowedmccs`);

        if (allowedMccQueryParams === null || allowedMccQueryParams === undefined) {
            throw new Error('Something went wrong');
        }

        const queryParams: URLSearchParams = new URLSearchParams(
            Object.entries(allowedMccQueryParams).map(([key, value]: [string, string | number]): [string, string] => {
                return [key, value ? value.toString() : ''];
            }),
        );

        url.search = queryParams.toString();

        return this.http.get<IListResponse<IAllowedMcc>>(url.toString()).pipe(
            catchError(() => {
                return throwError('Something went wrong.');
            }),
        );
    }

    public fetchAllowedMccsById(allowedMccId: string): Observable<IAllowedMcc> {
        return this.http.get<IAllowedMcc>(`${this.environment_url}nfront/v1/proxy/allowedmccs/${allowedMccId}`);
    }

    public createAllowedMcc(mutateAllowedMcc: DeepPartial<IMutateAllowedMcc>): Observable<IAllowedMcc> {
        return this.http.post<IAllowedMcc>(`${this.environment_url}nfront/v1/proxy/allowedmccs`, mutateAllowedMcc);
    }

    public updateAllowedMcc(allowedMccId: string, mutateAllowedMcc: DeepPartial<IMutateAllowedMcc>): Observable<IAllowedMcc> {
        return this.http.put<IAllowedMcc>(`${this.environment_url}nfront/v1/proxy/allowedmccs/${allowedMccId}`, mutateAllowedMcc);
    }

    /**
     * Allowed Countries
     */
    public fetchAllowedCountriesList(allowedCountryQueryParams: DeepPartial<IAllowedCountryListQueryParams>): Observable<IListResponse<IAllowedCountry>> {
        const url: URL = new URL(`${this.environment_url}nfront/v1/proxy/allowedcountries`);

        const queryParams: URLSearchParams = new URLSearchParams(
            Object.entries(allowedCountryQueryParams).map(([key, value]: [string, string | number]): [string, string] => {
                return [key, value.toString()];
            }),
        );

        url.search = queryParams.toString();

        return this.http.get<IListResponse<IAllowedCountry>>(url.toString());
    }

    public fetchAllowedCountriesById(allowedCountryId: string): Observable<IAllowedCountry> {
        return this.http.get<IAllowedCountry>(`${this.environment_url}nfront/v1/proxy/allowedcountries/${allowedCountryId}`);
    }
}
