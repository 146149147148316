import { Pipe, PipeTransform } from '@angular/core';
import { IAddress, Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'address',
})
export class AddressPipe implements PipeTransform {
    transform(value: Optional<IAddress>): string {
        if (!value) return '';

        const addressComponents: Array<string> = [];

        if (value.address_line1) addressComponents.push(value.address_line1);
        if (value.address_line2) addressComponents.push(value.address_line2);
        if (value.city) addressComponents.push(value.city);
        if (value.state) addressComponents.push(value.state);
        if (value.country) addressComponents.push(value.country);
        if (value.postal_code) addressComponents.push(value.postal_code);

        return addressComponents.join(', ').trim();
    }
}
