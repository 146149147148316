import { Injectable } from '@angular/core';
import { FilterOperator } from '@lib/enums';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DeepPartial, ICursor, IFormArraySchema, IFormGroupSchema, ISearchBooleanQuery, ISearchBooleanQueryMust, ISearchBooleanQueryMustBool, ISearchBooleanQueryMustBoolShould, ISearchCashbackWalletRequest, ISearchTransactionRequest, ITransactionFilter, Nullable, Optional } from '@lib/interfaces';

export type ITransactionFilterForm = IFormGroupSchema<DeepPartial<ITransactionFilter>>;
export type ITransactionFilterArray = IFormArraySchema<DeepPartial<ITransactionFilter>>;
export type ISearchTransactionRequestForm = IFormGroupSchema<DeepPartial<ISearchTransactionRequest>>;
export type ISearchCashbackWalletRequestForm = IFormGroupSchema<DeepPartial<ISearchCashbackWalletRequest>>;
export type ICursorForm = IFormGroupSchema<DeepPartial<ICursor>>;
export type ISearchBooleanQueryForm = IFormGroupSchema<DeepPartial<ISearchBooleanQuery>>;
export type ISearchBooleanQueryMustFormArray = IFormArraySchema<DeepPartial<ISearchBooleanQueryMust>>;
export type ISearchBooleanQueryMustFormGroup = IFormGroupSchema<DeepPartial<ISearchBooleanQueryMust>>;
export type ISearchBooleanQueryMustBoolForm = IFormGroupSchema<DeepPartial<ISearchBooleanQueryMustBool>>;
export type ISearchBooleanQueryMustBoolShouldFormArray = IFormArraySchema<DeepPartial<ISearchBooleanQueryMustBoolShould>>;
export type ISearchBooleanQueryMustBoolShouldFormGroup = IFormGroupSchema<DeepPartial<ISearchBooleanQueryMustBoolShould>>;

@Injectable({
    providedIn: 'root',
})
export class TransactionSearchService {
    public constructor(private readonly formBuilder: FormBuilder) {}

    public getFilter(searchPayload: FormGroup<ISearchTransactionRequestForm>, filterName: string, operator: FilterOperator): Nullable<FormGroup<ITransactionFilterForm>> {
        if (!searchPayload.controls.filters) throw new Error('Filters are not set in search payload!');

        const filterIndex: number = this.findFilterIndex(searchPayload.controls.filters, filterName, operator);

        if (filterIndex === -1) return null;

        return searchPayload.controls.filters.controls[filterIndex];
    }

    public addFilter(searchPayload: FormGroup<ISearchTransactionRequestForm>, filterName: string, operator: FilterOperator, filterValues: Array<string>, emitEvent: boolean): void {
        if (!searchPayload.controls.filters) throw new Error('Filters are not set in search payload!');

        const filterGroup: FormGroup<ITransactionFilterForm> = this.createFilterGroup(filterName, operator, filterValues);

        searchPayload.controls.filters.push(filterGroup, { emitEvent });
    }

    public removeFilter(searchPayload: FormGroup<ISearchTransactionRequestForm>, filterName: string, operator: FilterOperator, emitEvent: boolean): void {
        if (!searchPayload.controls.filters) throw new Error('Filters are not set in search payload!');

        const filterIndex: number = this.findFilterIndex(searchPayload.controls.filters, filterName, operator);

        if (filterIndex === -1) return;

        searchPayload.controls.filters.removeAt(filterIndex, { emitEvent });
    }

    private createFilterGroup(filterName: string, filterOperator: FilterOperator, filterValues: Array<string>): FormGroup<ITransactionFilterForm> {
        return this.formBuilder.group<ITransactionFilterForm>({
            field: this.formBuilder.nonNullable.control<string>(filterName),
            operator: this.formBuilder.nonNullable.control<FilterOperator>(filterOperator),
            values: this.formBuilder.array<IFormArraySchema<Optional<string>>>(filterValues.map((value: string): FormControl<Optional<string>> => this.formBuilder.nonNullable.control(value))),
        });
    }

    private findFilterIndex(searchFilters: FormArray<ITransactionFilterArray>, filterName: string, operator: FilterOperator): number {
        return searchFilters.controls.findIndex((filterGroup: FormGroup<ITransactionFilterForm>): boolean => {
            if (!filterGroup.controls.field || !filterGroup.controls.operator) return false;

            return filterGroup.controls.field.value === filterName && filterGroup.controls.operator.value === operator;
        });
    }

    public removeAllFilters(searchPayload: FormGroup<ISearchTransactionRequestForm>, emitEvent = false): void {
        if (!searchPayload.controls.filters) throw new Error('Filters are not set in search payload!');

        searchPayload.controls.filters.clear({ emitEvent });
    }
    
    public addCashbackWalletFilter(searchPayload: FormGroup<ISearchCashbackWalletRequestForm>,filter:ISearchBooleanQueryMust){
        if (!searchPayload.controls.boolean_query?.controls.must) throw new Error('Filters are not set in search payload!');

        const filterGroup: FormGroup<ISearchBooleanQueryMustFormGroup> = this.createCashbackWalletFilterGroup(filter);

        searchPayload.controls.boolean_query.controls.must.push(filterGroup);
    }

    public createCashbackWalletFilterGroup(filter:ISearchBooleanQueryMust):FormGroup<ISearchBooleanQueryMustFormGroup>{
        return this.formBuilder.group<ISearchBooleanQueryMustFormGroup>({
            queryObjectType:this.formBuilder.nonNullable.control<string>(filter.queryObjectType),
            bool:this.createCashbackWalletFilterBoolGroup(filter.bool)
        })
    }

    public createCashbackWalletFilterBoolGroup(bool:ISearchBooleanQueryMustBool):FormGroup<ISearchBooleanQueryMustBoolForm>{
        return this.formBuilder.group<ISearchBooleanQueryMustBoolForm>({
            should:this.createCashbackWalletFilterBoolShouldArray(bool.should)
        })
    }

    public createCashbackWalletFilterBoolShouldArray(should:Array<ISearchBooleanQueryMustBoolShould>):FormArray<ISearchBooleanQueryMustBoolShouldFormArray>{
        const shouldArray:FormArray<ISearchBooleanQueryMustBoolShouldFormArray> = this.formBuilder.array<ISearchBooleanQueryMustBoolShouldFormArray>([]);
        should.forEach((shouldFilter:ISearchBooleanQueryMustBoolShould) => {
            let shouldGroup:FormGroup<ISearchBooleanQueryMustBoolShouldFormGroup> = this.createCashbackWalletFilterBoolShouldGroup(shouldFilter);
            shouldArray.push(shouldGroup);
        });
        return shouldArray;
    }

    public createCashbackWalletFilterBoolShouldGroup(should:ISearchBooleanQueryMustBoolShould):FormGroup<ISearchBooleanQueryMustBoolShouldFormGroup>{
        return this.formBuilder.group<ISearchBooleanQueryMustBoolShouldFormGroup>({
            queryObjectType: this.formBuilder.nonNullable.control<string>(should.queryObjectType),
            fieldName: this.formBuilder.nonNullable.control<string>(should.fieldName),
            fieldValue: this.formBuilder.nonNullable.control<string>(should.fieldValue)
        })
    }
}
