<div class="alias-table">
    <app-table
        [dataColumns]="columns"
        [dataDefaultColumns]="defaultColumns"
        [dataHasMoreRecords]="hasMoreRecords"
        [dataLoading]="aliasListLoading"
        [dataSource]="aliasList"
        class="card-table"
        dataAbsenceText="No Aliases"
        dataName="Aliases"
        svgIcon="card-table"
    >
        <ng-template [appTableColumnDataSource]="aliasList" appTableColumn="created" let-card>
            <p>{{ card.created | date }}</p>
        </ng-template>
    </app-table>
</div>
