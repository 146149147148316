import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { CardProductType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'cardProductType',
})
export class CardProductTypePipe implements PipeTransform {
    public transform(value: Optional<CardProductType>): string {
        switch (value) {
            case CardProductType.CREDIT:
                return 'Credit';

            case CardProductType.PREPAID:
                return 'Prepaid';

            default:
                return '';
        }
    }
}
