import { AuthExpiredByType, CardEntry, ConfirmationType, EcommerceSecurityLevel, FilterMatcher, FilterOperator, LoadTransactionType, MessageType, PosEnvironment, TransactionExtendedType, TransferSourceChannel } from '@lib/enums';

export interface ITransactionFilter {
    field: string;
    operator: FilterOperator;
    values: Array<string>;
}

export interface ITransaction {
    amount: string;
    count: string;
}

export interface ITransactionStatus {
    approved: ITransaction;
    declined: ITransaction;
    settled: ITransaction;
    value: string;
}

export interface IFeeDetail {
    fee_id: string;
    description: string;
    amount: number;
    currency: string;
}

export interface IFee {
    active: boolean;
    amount: number;
    created: Date;
    currency: string;
    description: string;
    name?: string;
    id: string;
    modified: Date;
    percentage?: number;
    range: any[];
    source_channel: string;
    transaction_origin: string;
    transaction_type: string;
    transfer_type: any[];
    type: string;
}

export interface ISweepDetail {
    debit_from_currency: string;
    debit_from_amount: number;
    credit_to_currency: string;
    credit_to_amount: number;
    exchange_rate: number;
}

export interface ITransactionAdvanceFilter {
    card_id: string;
    user_id: string;
    account_id1: string;
    card_product_1: string;
    billing_currency_account: string;
    parent_transaction_id: string;
    status_code: string;
    transaction_type: TransactionExtendedType;
    transfer_id: string;
    transmission_date_time: string;
    auth_expired_by: AuthExpiredByType;
    network_transaction_id: string;
    auth_expired_at: string;
    rrn: string;
    sender_user_id: string;
    transaction_amount: number;
    transaction_currency: string;
    mcc: string;
    billing_amount: number;
    merchant_name: string;
    billing_currency: string;
}

export interface ITransactionIndex {
    id: string;
    transaction_timestamp: string;
    parent_transaction_id: string;
    network: string;
    message_type: MessageType;
    transaction_type: TransactionExtendedType;
    transaction_description: string;
    transmission_date_time: string;
    date_time_acquirer: string;
    card_id: string;
    card_first6_digits: string;
    card_last4_digits: string;
    card_expiry_date: string;
    user_id: string;
    account_id1: string;
    card_product1: string;
    account_id2: string;
    card_product2: string;
    acquirer_id: string;
    merchant_id: string;
    mcc: string;
    merchant_name: string;
    merchant_city: string;
    merchant_country: string;
    terminal_id: string;
    stan: string;
    rrn: string;
    auth_id_response: string;
    network_transaction_id: string;
    transaction_amount: number;
    transaction_currency: string;
    billing_amount: number;
    billing_currency: string;
    billing_amount_account: number;
    billing_currency_account: string;
    conversion_rate_billing_account: number;
    acquirer_fee_amount: number;
    conversion_rate_billing: string;
    fee_amount: number;
    fee_details: Array<IFeeDetail>;
    original_amount: number;
    original_amount_billing: string;
    status_code: string;
    status_description: string;
    transfer_id: string;
    load_id: string;
    unload_id: string;
    eci: string;
    ecommerce_security_level: EcommerceSecurityLevel;
    card_entry: CardEntry;
    pos_environment: PosEnvironment;
    fallback: boolean;
    pin_present: boolean;
    moto: boolean;
    recurring: boolean;
    installment_transaction: boolean;
    auth_expired_at: string;
    auth_expired_by: AuthExpiredByType;
    is_reversed: ConfirmationType;
    is_settled: ConfirmationType;
    source_channel: TransferSourceChannel;
    notes: string;
    sender_user_id: string;
    external_reference_number: string;
    external_reference_date: string;
    reference_number: string;
    sweep_details: Array<ISweepDetail>;
    type: LoadTransactionType;
    quote_id: string;
    receiver_user_id: string;
}

export interface ICursor {
    after: string;
}

export interface ISearchTransactionRequest {
    query: string;
    sort_by: string;
    order_by: string;
    matcher: FilterMatcher;
    filters: Array<ITransactionFilter>;
    cursors: ICursor;
    limit: number;
}

export interface IPaging {
    cursors: ICursor;
}

export interface ISearchTransactionResponse {
    paging?: IPaging;
    data: Array<ITransactionIndex>;
}

export interface ILinkFeeForm {
    fee: string;
}

export interface ISearchCashbackWalletRequest {
    cursors: ICursor;
    sort_by: string;
    order_by: string;
    limit: number;
    boolean_query: ISearchBooleanQuery
}

export interface ISearchBooleanQuery {
    must: Array<ISearchBooleanQueryMust>
}

export interface ISearchBooleanQueryMust {
    queryObjectType: string;
    bool: ISearchBooleanQueryMustBool
}

export interface ISearchBooleanQueryMustBool {
    should: Array<ISearchBooleanQueryMustBoolShould>;
}

export interface ISearchBooleanQueryMustBoolShould {
    queryObjectType: string;
    fieldName: string,
    fieldValue: string
}

export interface IWalletIndex {
    id: string,
    to_amount: number,
    from_amount: number,
    to_currency: string,
    from_currency: string,
    transaction_timestamp: string,
    sender_account_id: string,
    sender_name: string,
    sender_user_id: string,
    type: string,
    merchant_name: string,
    sku: string,
    sku_name: string,
    payment_mode: string,
    status_code: string,
    rrn: string,
}

export interface ICashbackIndex {
    id: string,
    parent_transaction_id: string,
    to_amount: number,
    from_amount: number,
    to_currency: string,
    from_currency: string,
    fee_amount: number,
    transaction_timestamp: string,
    recipient_account_id: string,
    recipient_user_id: string,
    type: string,
    parent_transaction_type: string,
    status_code: string,
    rrn: string,
}

export interface ISearchWalletResponse {
    paging?: IPaging;
    data: Array<IWalletIndex>;
}

export interface ISearchCashbackResponse {
    paging?: IPaging;
    data: Array<ICashbackIndex>;
}

export const FeeTypes = [
    { key: 'FLAT', value: 'Flat' },
    { key: 'PERCENTAGE', value: 'Percentage' },
    { key: 'MAX_PERC_OR_FLAT', value: 'Max Percentage or Flat' },
    { key: 'MIN_PERC_OR_FLAT', value: 'Min Percentage or Flat' },
    { key: 'MAX_PERC_OR_FLAT_W_CAP', value: 'Max Percentage or Flat With Capped Amount' },
    { key: 'MIN_PERC_OR_FLAT_W_CAP', value: 'Min Percentage or Flat With Capped Amount' },
    { key: 'RANGE', value: 'Range' },
    { key: 'RANGE_FLAT', value: 'Range Flat' },
    { key: 'RANGE_PERCENTAGE', value: 'Range Percentage' },
    { key: 'RANGE_MAX_PERC_OR_FLAT', value: 'Range Max Percentage Or Flat' },
    { key: 'RANGE_MIN_PERC_OR_FLAT', value: 'Range Min Percentage Or Flat' },
    { key: 'RANGE_MAX_PERC_OR_FLAT_W_CAP', value: 'Range Max Percentage Or Flat With Capped Amount' },
    { key: 'RANGE_MIN_PERC_OR_FLAT_W_CAP', value: 'Range Min Percentage Or Flat With Capped Amount' },
];

export const TransactionTypes = [
    { key: 'PURCHASES', value: 'Purchases' },
    { key: 'WITHDRAWALS', value: 'Withdrawals' },
    { key: 'BALANCE_INQUIRIES', value: 'Balance Inquiries' },
    { key: 'TRANSFERS', value: 'Transfers' },
    { key: 'LOADS', value: 'Loads' },
    { key: 'UNLOADS', value: 'Unloads' },
    { key: 'PURCHASE_FOREIGN_CURRENCY', value: 'Purchase Foreign Currency' },
    { key: 'CURRENCY_MARKUP', value: 'Currency Markup' },
    { key: 'SCHEME_CREDIT', value: 'Scheme Credit' },
    { key: 'OCT', value: 'OCT' },
    { key: 'ATM_DEPOSIT', value: 'ATM Deposit' },
    { key: 'ATM_DEPOSIT_FOREIGN_CURRENCY', value: 'ATM Deposit Foreign Currency' },
    { key: 'CASH_IN', value: 'Cash In' },
    { key: 'CASH_IN_FOREIGN_CURRENCY', value: 'Cash In Foreign Currency' },
    { key: 'CASH_OUT', value: 'Cash Out' },
    { key: 'CASH_OUT_FOREIGN_CURRENCY', value: 'Cash Out Foreign Currency' },
];

export const TransactionOrigins = [
    { key: 'DOMESTIC', value: 'Domestic' },
    { key: 'FOREIGN', value: 'Foreign' },
    { key: 'ANY', value: 'Any' },
];

export const SourceChannels = [
    { key: 'ANY', value: 'Any' },
    { key: 'MPGS', value: 'MPGS' },
    { key: 'DAPI', value: 'DAPI' },
    { key: 'LEAN', value: 'LEAN' },
];
export const WalletTypes = [
    {
        "queryObjectType": "match",
        "fieldName": "type",
        "fieldValue": "FUNDS_TRANSFER"
    },
    {
        "queryObjectType": "match",
        "fieldName": "type",
        "fieldValue": "BILL_PAYMENT"
    },
    {
        "queryObjectType": "match",
        "fieldName": "type",
        "fieldValue": "CURRENCY_EXCHANGE"
    },
    {
        "queryObjectType": "match",
        "fieldName": "type",
        "fieldValue": "LOAD_CHECKOUT"
    },
    {
        "queryObjectType": "match",
        "fieldName": "type",
        "fieldValue": "LOAD_LEAN"
    },
    {
        "queryObjectType": "match",
        "fieldName": "type",
        "fieldValue": "REMITTANCES"
    }
]
