import { HttpErrorResponse } from '@angular/common/http';
import { Nullable } from '@lib/interfaces/common.interface';

export class ApiResponseError extends HttpErrorResponse {
    public override readonly error: Nullable<IResponseErrorFromServer> = null;
}

export interface IResponseErrorFromServer {
    detail: string;
    status: number;
    title: string;
    type: string;
    violations: Array<IViolation>;
}

export interface IViolation {
    field: string;
    message: string;
}

export interface ApiCustomErrorMessage {
    status: number;
    message: string;
}
