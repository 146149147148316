<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center position-relative">
        <div class="d-flex flex-row align-items-center">
            <div
                #selectBox
                #selectBoxTrigger="matMenuTrigger"
                (keydown.space)="selectBoxTrigger.toggleMenu()"
                (menuOpened)="adjustMenuOptionWidth()"
                (menuClosed)="cleanUpMenuScrollSubscription()"
                [class.select-box-disabled]="control.disabled"
                [class.select-box-invalid]="isInvalid(control)"
                [matMenuTriggerFor]="selectOptionMenu"
                class="w-100 d-flex flex-row select-box value-field-text"
                tabindex="0"
            >
                <ng-container *ngIf="selectedOptions.length > 0; else emptyValue">
                    <div
                        *ngFor="let selectedOption of selectedOptions"
                        class="ps-2 d-flex flex-row justify-content-between align-items-center rounded-pill select-box-pill"
                    >
                        <ng-container [ngTemplateOutlet]="selectedOption.templateRef"></ng-container>
                        <mat-icon (click)="toggleSelectedOption(selectedOption)" class="pointer select-box-pill-remove" fontIcon="cancel"></mat-icon>
                    </div>
                </ng-container>
                <ng-template #emptyValue>
                    <span class="select-box-placeholder">{{ placeholderText }}</span>
                </ng-template>
            </div>
            <mat-icon (click)="selectBoxTrigger.toggleMenu()" *ngIf="control.enabled" class="position-absolute select-icon" fontIcon="expand_more"></mat-icon>
        </div>
        <ul *ngIf="isInvalid(control) && showError" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>

    <mat-menu #selectOptionMenu class="select-option">
        <div
            (click)="toggleSelectedOption(inputOption)"
            (keydown.enter)="toggleSelectedOption(inputOption)"
            *ngFor="let inputOption of inputOptions"
            [disableRipple]="true"
            mat-menu-item
        >
            <div class="d-flex flex-row align-items-center column-gap-3">
                <input [checked]="control.value.includes(inputOption.value)" class="rounded-circle checkbox" type="checkbox" />
                <ng-container [ngTemplateOutlet]="inputOption.templateRef"></ng-container>
            </div>
        </div>
        <div *ngIf="optionsLoading" [disableRipple]="true" [disabled]="true" class="d-flex flex-row justify-content-center" mat-menu-item>
            <div class="option-loader rounded-circle"></div>
        </div>
    </mat-menu>
</ng-template>
