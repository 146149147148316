<div class="confirm-dialog">
    <div class="d-flex justify-content-between align-items-center pe-3">
        <h1 class="title fw-semibold" mat-dialog-title>{{ data.title | titlecase }}</h1>
        <mat-icon fontIcon="close" mat-dialog-close></mat-icon>
    </div>
    <mat-dialog-content class="description">{{ data.description }}</mat-dialog-content>
    <mat-dialog-actions class="ms-2 my-2">
        <button mat-button mat-dialog-close class="cancel-btn" (click)="setConfirmed(false)">{{ data.cancelBtnText ? data.cancelBtnText : 'No' }}</button>
        <button mat-button mat-dialog-close cdkFocusInitial class="confirm-btn" (click)="setConfirmed(true)">
            {{ data.confirmBtnText ? data.confirmBtnText : 'Yes' }}
        </button>
    </mat-dialog-actions>
</div>
