import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { CardManagementService } from '@lib/services/card-managament/card-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ApiResponseError, ILimit } from '@lib/interfaces';
import { BaseComponent } from '@lib/components/base';

@Component({
    selector: 'app-velocity-limit-widget',
    standalone: true,
    templateUrl: './velocity-limit-widget.component.html',
    styleUrls: ['./velocity-limit-widget.component.scss'],
    imports: [CommonModule, MatProgressBarModule, MatSlideToggleModule, FormsModule, MatIconModule, ReactiveFormsModule, FormsModule, SpinnerComponent],
})
export class VelocityLimitWidgetComponent extends BaseComponent implements OnInit {
    @Input() limit: any;
    @Input() cardId = '';
    @Output() fetchLimitsEvent = new EventEmitter();

    color: string | undefined;
    consumedLimitPercentage: number | undefined;
    leftLimit: number | undefined;
    limitForm!: FormGroup;
    isEditable = false;
    actionLoading = false;
    isEditIconShow = true;

    constructor(private cardSrv: CardManagementService, private snakbar: MatSnackBar) {
        super();
    }

    ngOnInit(): void {
        this.consumedLimitPercentage = (100 * this.limit.consumed_limit) / this.limit.max_amount;

        if (this.consumedLimitPercentage < 50) this.color = 'accent';
        if (this.consumedLimitPercentage > 50) this.color = 'primary';
        if (this.consumedLimitPercentage > 80) this.color = 'warn';

        this.leftLimit = this.limit.max_amount - this.limit.consumed_limit;

        this.limitForm = new FormGroup({
            limit_amount: new FormControl(),
        });
    }

    editLimit(limit: ILimit): void {
        this.limitForm.get('limit_amount')?.setValue(limit.max_amount);
        this.isEditable = true;
        this.isEditIconShow = false;
    }

    modifyLimit(limit: ILimit): void {
        const customLimitAmount = this.limitForm.get('limit_amount')?.value;
        if (customLimitAmount > limit.max_amount) {
            this.snakbar.open('Amount should not be exceeds Product maximum amount', 'Close', { duration: 5000 });
            return;
        }

        this.actionLoading = true;

        const currentTimeString = new Date().getTime().toString();
        let newLimitDescp = `${limit.description}|${currentTimeString}`;
        if (newLimitDescp.length > 40) {
            newLimitDescp = newLimitDescp.slice(0, 40);
        }

        const payload = {
            id: limit.id,
            max_amount: customLimitAmount,
            min_amount: limit.min_amount,
            active: true,
            frequency: limit.frequency,
        };

        if (limit.linked_to === 'CARD') {
            this.updateLimit(payload);
        } else {
            const { max_amount, description, ...modLimit } = limit;
            this.cloneLimit({
                max_amount: customLimitAmount,
                description: newLimitDescp,
                ...modLimit,
            });
        }
    }

    cloneLimit(payload: any): void {
        this.addSubscription(
            this.cardSrv.createLimitOnCard(this.cardId, payload).subscribe({
                next: () => {
                    this.emitFetchLimits();

                    this.limit = undefined;
                    this.actionLoading = false;
                    this.isEditable = false;
                    this.isEditIconShow = true;

                    this.createApiSuccessSnackBar(this.snakbar, 'Limit successfully updated!');
                },
                error: (error: ApiResponseError) => {
                    this.actionLoading = false;
                    this.isEditable = false;
                    this.isEditIconShow = true;

                    this.createApiErrorSnackBar(this.snakbar, error);
                },
            }),
        );
    }

    updateLimit(payload: any): void {
        this.addSubscription(
            this.cardSrv.updateLimitOnCard(this.cardId, payload).subscribe({
                next: () => {
                    this.emitFetchLimits();

                    this.limit = undefined;
                    this.actionLoading = false;
                    this.isEditable = false;
                    this.isEditIconShow = true;

                    this.createApiSuccessSnackBar(this.snakbar, 'Limit successfully updated!');
                },
                error: (error: ApiResponseError) => {
                    this.actionLoading = false;
                    this.isEditable = false;
                    this.isEditIconShow = true;

                    this.createApiErrorSnackBar(this.snakbar, error);
                },
            }),
        );
    }

    revertLimit(limit: any): void {
        this.actionLoading = true;
        this.deactivateLimit(limit);
    }

    deactivateLimit(limit: any): void {
        const payload = {
            id: limit.id,
            active: false,
        };

        this.addSubscription(
            this.cardSrv.updateLimitOnCard(this.cardId, payload).subscribe({
                next: () => {
                    this.limit = undefined;
                    this.emitFetchLimits();
                    this.actionLoading = false;

                    this.createApiSuccessSnackBar(this.snakbar, 'Limit reverted successfully');
                },
                error: (error: ApiResponseError) => {
                    this.actionLoading = false;

                    this.createApiErrorSnackBar(this.snakbar, error);
                },
            }),
        );
    }

    closeEditor(): void {
        this.isEditable = false;
        this.isEditIconShow = true;
    }

    emitFetchLimits(): void {
        this.fetchLimitsEvent.emit();
    }
}
