<span class="checkbox">
    <label
        class="checkbox-label"
        [ngClass]="{
           'checkbox-active': checked,
       }"
    >
        <input class="checkbox-input" type="checkbox" [ngModel]="checked" (ngModelChange)="onModelChange($event)" />
        <span class="checkbox-text"><ng-content class="checkbox-text"></ng-content></span>
        <span class="checkbox-input-custom">
            <mat-icon class="checkbox-icon" svgIcon="checkbox"></mat-icon>
        </span>
    </label>
</span>
