import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appAccessControlHideElementDirective]',
})
export class AccessControlHideElementDirective {
    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    @Input() set appAccessControlHideElementDirective(permissions: string[] | undefined) {
        this.viewContainer.clear();
        const allPermissions = localStorage.getItem('permissions')?.split(',');

        if (!permissions || !permissions.length) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }

        if (permissions.length > 1) {
            const hasParentPermission = allPermissions?.includes(permissions[0]);
            const hasChildPermission = allPermissions?.some((permission) => permissions.includes(permission));

            if (hasParentPermission && hasChildPermission) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        } else {
            const hasPermission = allPermissions?.some((permission) => permissions.includes(permission));

            if (hasPermission) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        }
    }
}
