import { IAddress } from '@lib/interfaces';

export interface ICardStatus {
    id: string;
    user_id: string;
    card_type: string;
    card_product_id: string;
    name_on_card: string;
    card_product_name: string;
    shipping_details: IShippingDetails;
    status: string;
    status_reason: string;
    fulfillment_status: FulfillmentStatus;
    bin: string;
    last_four: string;
    expiry_date: string;
    pin_retries: number;
    created: string;
    modified: string;
    auth_settings: IAuthSettings;
}

enum FulfillmentStatus {
    CREATED = 'CREATED',
    ORDERED = 'ORDERED',
}

interface IAuthSettings {
    allow_atm: boolean;
    allow_pos: boolean;
    allow_chip: boolean;
    allow_magstripe: boolean;
    allow_chip_fallback: boolean;
    allow_without_pin: boolean;
    allow_ecommerce_non_3DS: boolean;
    allow_ecommerce_3DS: boolean;
    allow_ecommerce_without_CVV: boolean;
    allow_account_verification_without_CVV: boolean;
    allow_quasi_cash: boolean;
    allow_scheme_account_funding: boolean;
    allow_scheme_credits: boolean;
    allow_contactless: boolean;
    allowed_mccs_id: string;
}

interface IShippingDetails {
    shipping_address: IShippingAddress;
}

interface IShippingAddress extends IAddress {
    first_name: string;
    middle_name: string;
    last_name: string;
    mobile: string;
}

export type ICardStatusResponse = ICardStatus;
