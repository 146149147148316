import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { IAuthResponse, ILoginResponse } from '@lib/interfaces/auth.interface';
import { environment } from '../../../../environments/environment';
import { DeepPartial } from '@lib/interfaces';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private environment_url: string;

    constructor(private http: HttpClient, private httpHandler: HttpBackend) {
        this.environment_url = environment.api_url;
    }

    loginWithCredentials(credentials: DeepPartial<any>, tenantId?: string): Observable<IAuthResponse> {
        if (tenantId) {
            const headers = new HttpHeaders({
                'X-Consumer-Username': tenantId,
            });

            return this.http.post<IAuthResponse>(`${this.environment_url}nfront/v1/admins/login`, credentials, { headers });
        }
        return this.http.post<IAuthResponse>(`${this.environment_url}nfront/v1/admins/login`, credentials);
    }

    continueWithVerification(id: string, code: string, verificationType: 'mobile' | 'email'): Observable<IAuthResponse> {
        let url = `${this.environment_url}nfront/v1/admins/login/${id}:continueWithMobileVerification?code=${code}`;

        if (verificationType === 'email') {
            url = `${this.environment_url}nfront/v1/admins/login/${id}:continueWithEmailVerification?code=${code}`;
        }

        return this.http.put<IAuthResponse>(url, '').pipe(
            catchError((error) => {
                console.error('Verification error', error);
                return throwError(() => new Error('Verification failed'));
            }),
        );
    }

    signinWithCustomToken(loginResponse: IAuthResponse, tenantId?: string): Observable<ILoginResponse> {
        this.setLocalStorage(loginResponse);

        if (tenantId) {
            const params = new HttpParams({ fromObject: { custom_token: loginResponse.custom_token.token, 'X-Consumer-Username': tenantId || '' } });
            const headers = new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-no-authorization': 'true',
                'X-Consumer-Username': tenantId,
            });
            const url = `${this.environment_url}nfront/v1/oauth2/token:signinWithCustomToken`;

            return this.http.post<ILoginResponse>(url, params, { headers });
        }

        const params = new HttpParams({ fromObject: { custom_token: loginResponse.custom_token.token, 'X-Consumer-Username': tenantId || '' } });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-no-authorization': 'true',
        });
        const url = `${this.environment_url}nfront/v1/oauth2/token:signinWithCustomToken`;
        return this.http.post<ILoginResponse>(url, params, { headers });
    }

    changePassword(adminId: string | null, body: { old_password: string; new_password: string }): Observable<any> {
        const url = `${this.environment_url}nfront/v1/admins/login/${adminId}:continueWithPasswordChange`;
        const headers = new HttpHeaders({
            'X-Consumer-Username': localStorage.getItem('tenantKey') || '',
            apikey: localStorage.getItem('tenantKey') || '',
        });
        return this.http.put<any>(url, body, { headers });
    }

    changePasswordV2(adminId: string | null, token: string, body: { old_password: string; new_password: string }): Observable<any> {
        const url = `${this.environment_url}nfront/v1/admins/sessions/password/forgot:changePassword?id=${adminId}&token=${token}`;
        const headers = new HttpHeaders({
            'X-Consumer-Username': localStorage.getItem('tenantKey') || '',
            apikey: localStorage.getItem('tenantKey') || '',
        });
        return this.http.post<any>(url, { password: body.new_password }, { headers });
    }

    forgotPassword(email: string): Observable<any> {
        const url = `${this.environment_url}nfront/v1/admins/sessions/password/forgot/${email}`;
        const headers = new HttpHeaders({
            'X-Consumer-Username': localStorage.getItem('tenantKey') || '',
            apikey: localStorage.getItem('tenantKey') || '',
        });
        return this.http.post<any>(url, {}, { headers });
    }

    private setLocalStorage(loginResponse: IAuthResponse): void {
        localStorage.setItem('adminId', loginResponse.id);

        if (loginResponse.custom_token) {
            localStorage.setItem('customToken', loginResponse.custom_token.token);
            localStorage.setItem('permissions', loginResponse.custom_token.scopes);
        }

        if (loginResponse.admin) {
            localStorage.setItem('id', loginResponse.admin.id);
        }
    }
}
