<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex flex-row align-items-center">
            <div
                *ngFor="let inputOption of inputOptions; first as first; last as last"
                [ngClass]="{ 'me-4': first, 'ms-4': last, 'mx-4': !first && !last }"
                class="d-flex flex-row align-items-center"
            >
                <input
                    [class.disabled]="control.disabled"
                    [formControl]="control"
                    [id]="createInputOptionIdentifier(inputOption)"
                    [name]="createMinifiedName(controlDisplayName)"
                    [value]="inputOption.value"
                    type="radio"
                />
                <label [class.disabled]="control.disabled" [for]="createInputOptionIdentifier(inputOption)" class="mx-2">
                    <ng-container [ngTemplateOutlet]="inputOption.templateRef" />
                </label>
            </div>
        </div>
        <ul *ngIf="isInvalid(control)" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>
</ng-template>
