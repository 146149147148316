import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TabP2pComponent } from './components/tab-p2p/tab-p2p.component';
import { TabRemittanceComponent } from './components/tab-remittance/tab-remittance.component';
import { TabBillersComponent } from './components/tab-billers/tab-billers.component';

@Component({
  standalone: true,
  selector: 'app-tab-beneficiary',
  templateUrl: './tab-beneficiary.component.html',
  styleUrls: ['./tab-beneficiary.component.scss'],
  imports: [
    CommonModule,
    TabP2pComponent,
    TabRemittanceComponent,
    TabBillersComponent
  ],
})
export class TabBeneficiaryComponent {

  @Input() customerId = '';
  protected tabHeaders: Array<any> = [
    { tabName: 'P2P'},
    { tabName: 'Remittance'},
    { tabName: 'Billers'},
  ];
  protected activeTabIndex = 0;

  protected switchTab(activeTabIndex: number): void {
    this.activeTabIndex = activeTabIndex;
  }

}
