/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, forwardRef, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../../material.module';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectboxComponent),
    multi: true,
};

enum DropdownMouseState {
    INSIDE,
    OUTSIDE,
}

@Component({
    selector: 'app-selectbox',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, AngularMaterialModule],
    templateUrl: './selectbox.component.html',
    styleUrls: ['./selectbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectboxComponent),
            multi: true,
        },
    ],
})
export class SelectboxComponent implements ControlValueAccessor {
    showMenu: boolean;
    selectedItem: any;
    @Input() sticky = false;
    @Input() id = '';
    onChange: any = () => {};
    onTouch: any = () => {};

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};

    state: any;

    @Input()
    data: any;

    @Input()
    width: any;

    @Input()
    isDisabled = false;

    @Input('labelText')
    label: any;

    @HostListener('document:click') clickedOutside() {
        if (this.state == DropdownMouseState.OUTSIDE) {
            this.showMenu = false; // hide the dropdown...
        }
    }

    constructor() {
        this.showMenu = false;
        this.state = DropdownMouseState.OUTSIDE;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    valueChange(item: any) {
        this.selectedItem = item;
        this.propagateChange(item.id);
        this.showMenu = false;
    }

    get value(): any {
        return this.selectedItem.id;
    }

    set value(v: any) {
        if (v !== this.selectedItem.id) {
            this.selectedItem = v;
        }
    }

    writeValue(value: any): void {
        if (value == null) {
            this.selectedItem = null;
        } else if (value !== undefined) {
            this.selectedItem = value;
        } else {
            const item = this.data.find((w: any) => w.id == value);
            this.selectedItem = item;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
}
