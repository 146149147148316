<div class="change-password-modal">
    <div class="modal-wrapper">
        <div class="modal-header mb-2rem justify-content-between">
            <h5 class="title">Change Password</h5>
            <mat-icon (click)="dialogRef.close()" fontIcon="close" />
        </div>
        <div class="modal-content">
            <form [formGroup]="passwordForm" class="form">
                <div class="inline-form-field d-flex align-items-center mb-2rem">
                    <label class="form-field-label">Current Password</label>
                    <mat-form-field appearance="outline" class="form-field-input">
                        <input [type]="showCurrentPassword ? 'text' : 'password'" formControlName="old_password" matInput />
                        <button (click)="showCurrentPassword = !showCurrentPassword" aria-label="Show password" disableRipple="true" mat-icon-button matSuffix>
                            <mat-icon [fontIcon]="showCurrentPassword ? 'visibility' : 'visibility_off'" />
                        </button>
                        <mat-error *ngIf="passwordForm.controls['old_password'].invalid  && passwordForm.controls['old_password'].errors && (passwordForm.controls['old_password'].dirty || passwordForm.controls['old_password'].touched)">This Field is required.</mat-error>
                    </mat-form-field>
                </div>
                <div class="inline-form-field d-flex align-items-center mb-2">
                    <label class="form-field-label">New Password</label>
                    <mat-form-field appearance="outline" class="form-field-input">
                        <input [type]="showNewPassword ? 'text' : 'password'" formControlName="new_password" matInput />
                        <button (click)="showNewPassword = !showNewPassword" aria-label="Show password" disableRipple="true" mat-icon-button matSuffix>
                            <mat-icon [fontIcon]="showNewPassword ? 'visibility' : 'visibility_off'" />
                        </button>
                        <mat-error *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)">
                            <div *ngIf="passwordForm.controls['new_password'].hasError('required')">This Field is required.</div>
                            <div *ngIf="!passwordForm.get('new_password')?.value?.match('^(?=.*[A-Z])')">At least one uppercase letter.</div>
                            <div *ngIf="!passwordForm.get('new_password')?.value?.match('(?=.*[a-z])')">At least one lowercase letter.</div>
                            <div *ngIf="!passwordForm.get('new_password')?.value?.match('(.[0-9].*)')">At least one digit.</div>
                            <div *ngIf="!passwordForm.get('new_password')?.value?.match('(?=.*[!@#$%^&*])')">At least one special character.</div>
                            <div *ngIf="!passwordForm.get('new_password')?.value?.match('.{8,}')">At least 8 characters long.</div>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="password-meter">
                    <mat-password-strength (onStrengthChanged)="strengthChange($event)" [password]="passwordForm.get('new_password')?.value"/>
                    <div class="password-strength">
                        {{ passwordStrength }}
                    </div>
                </div>

                <div class="inline-form-field d-flex align-items-center mb-2rem">
                    <label class="form-field-label">Confirm Password</label>
                    <mat-form-field
                        [ngClass]="{ confirm_password_field: passwordForm.get('confirm_password')?.value !== '' }"
                        appearance="outline"
                        class="form-field-input"
                    >
                        <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirm_password" matInput />
                        <button (click)="showConfirmPassword = !showConfirmPassword" aria-label="Show password" disableRipple="true" mat-icon-button matSuffix>
                            <mat-icon [fontIcon]="showConfirmPassword ? 'visibility' : 'visibility_off'" />
                        </button>
                        <mat-error *ngIf="passwordForm.controls['confirm_password'].invalid || (passwordForm.controls['confirm_password'].dirty || passwordForm.controls['confirm_password'].touched)">
                            <span *ngIf="passwordForm.controls['confirm_password'].hasError('required')">This Field is required.</span>
                            <span *ngIf="!matchPasswords()">Both Password need to match.</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-action mb-3">
                    <button (click)="changePassword()" [disabled]="isLoading || passwordForm.invalid" class="form-action-button" color="primary" mat-raised-button>
                        Change Password
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
