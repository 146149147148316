<div [ngClass]="{ sidebar: !isCollapseNav, 'sidebar-collapsed': isCollapseNav }">
    <div class="sidebar-content">
        <div class="d-flex align-items-center sidebar-header-logo" [ngClass]="!isCollapseNav ? 'justify-content-between' : 'justify-content-center'">
            <div><img *ngIf="!isCollapseNav" [src]="imgUrl" /></div>
            <div (click)="toggleCollapseNav()" class="mt-2 side-bar-toggle cursor-pointer side-bar-toggle-icon"
                [matTooltip]="isCollapseNav ? 'Expand Menu' : 'Collapse Menu'">
                <mat-icon class="sidebar-icon-color">menu</mat-icon>
            </div>
        </div>

        <mat-list>
            <ng-container *ngFor="let item of menuItems">
                <span *ngIf="!isCollapseNav && item.section" class="sidebar-sub-menu">{{ item.section }}</span>
                <span *ngIf="isCollapseNav && item.section" class="sidebar-sub-menu"></span>
               <div *ngIf="tenantKey!='tenant0_api' && item.title!='Call Center'">
                 <div *appAccessControlHideElementDirective="item.permissions">
                    <mat-list-item *ngIf="!item.children" class="sidebar-item" [routerLink]="item.link"
                        routerLinkActive="active" (click)="handleMenuClick(item)"
                        [matTooltip]="isCollapseNav ? item.title : ''">
                        <div class="sidebar-item-content">
                            <div *ngIf="!item.external" class="d-flex justify-content-between align-items-center">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span *ngIf="!isCollapseNav">{{ item.title }}</span>
                            </div>
                            <a *ngIf="item.external" [href]="item.link" target="_blank">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span *ngIf="!isCollapseNav">{{ item.title }}</span>
                            </a>
                        </div>
                    </mat-list-item>
                </div>
              </div>  
             <div *ngIf="tenantKey=='tenant0_api' && activeTabForTenant0.includes(item.title)">
                <div *appAccessControlHideElementDirective="item.permissions">
                   <mat-list-item *ngIf="!item.children" class="sidebar-item" [routerLink]="item.link"
                       routerLinkActive="active" (click)="handleMenuClick(item)"
                       [matTooltip]="isCollapseNav ? item.title : ''">
                       <div class="sidebar-item-content">
                           <div *ngIf="!item.external" class="d-flex justify-content-between align-items-center">
                               <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                               <span *ngIf="!isCollapseNav">{{ item.title }}</span>
                           </div>
                           <a *ngIf="item.external" [href]="item.link" target="_blank">
                               <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                               <span *ngIf="!isCollapseNav">{{ item.title }}</span>
                           </a>
                       </div>
                   </mat-list-item>
               </div>
             </div>  
            </ng-container>
        </mat-list>

        <mat-list class="mt-auto">
            <ng-container *ngFor="let item of lowerMenuItems">
                <span *ngIf="!isCollapseNav && item.section" class="sidebar-sub-menu">{{ item.section }}</span>
                <span *ngIf="isCollapseNav && item.section" class="sidebar-sub-menu"></span>

                <div *appAccessControlHideElementDirective="item.permissions">
                    <mat-list-item *ngIf="!item.children" class="sidebar-item" [routerLink]="item.link"
                        routerLinkActive="active" [matTooltip]="isCollapseNav ? item.title : ''">
                        <div class="sidebar-item-content">
                            <div *ngIf="!item.external" class="d-flex justify-content-between align-items-center">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span *ngIf="!isCollapseNav">{{ item.title }}</span>
                            </div>
                            <a *ngIf="item.external" [href]="item.link" target="_blank">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span *ngIf="!isCollapseNav">{{ item.title }}</span>
                            </a>
                        </div>
                    </mat-list-item>
                    <mat-expansion-panel *ngIf="item.children" class="sidebar-item-expand"
                        [class.mat-elevation-z0]="true" [class.active-expand]="checkExpandedItem('control-center')"
                        hideToggle (click)="handleMenuClick(item)">
                        <mat-expansion-panel-header class="sidebar-item-expand-header"
                            (click)="handleExpandClick(item, $event)" [matTooltip]="isCollapseNav ? item.title : ''">
                            <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                            <span *ngIf="!isCollapseNav" class="sidebar-item-expand-title">{{ item.title }}</span>
                        </mat-expansion-panel-header>
                        <mat-nav-list>
                            <div *ngFor="let childItem of item.children">
                                <mat-list-item *appAccessControlHideElementDirective="childItem.permissions"
                                    class="sidebar-item-expand-sub" [routerLink]="childItem.link"
                                    routerLinkActive="active-sub" [matTooltip]="isCollapseNav ? childItem.title : ''">
                                    <span *ngIf="!isCollapseNav" class="sidebar-item-expand-text">{{ childItem.title
                                        }}</span>
                                </mat-list-item>
                            </div>
                        </mat-nav-list>
                    </mat-expansion-panel>
                </div>
            </ng-container>
        </mat-list>
    </div>
</div>