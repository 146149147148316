import { Pipe, PipeTransform } from '@angular/core';
import { Optional, UserStatusReason } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'statusReason',
})
export class StatusReasonPipe implements PipeTransform {
    public transform(value: Optional<UserStatusReason>): string {
        switch (value) {
            case UserStatusReason.I0:
                return 'Initial status.';

            case UserStatusReason.A0:
                return 'First time activation: when activated from PENDINGKYC to ACTIVE status.';

            case UserStatusReason.A1:
                return 'Resumed from Suspended status: when user is moved back to ACTIVE status.';

            case UserStatusReason.A2:
                return 'Admin Activated the user from status suspended.';

            case UserStatusReason.A3:
                return 'Admin activated the user by manual intervention from the dashboard.';

            case UserStatusReason.A4:
                return 'Admin Activated the business user from status suspended.';

            case UserStatusReason.A5:
                return 'Admin Activated.';

            case UserStatusReason.A6:
                return 'Document expired or about to expire.';

            case UserStatusReason.A7:
                return 'ID renewed.';

            case UserStatusReason.A8:
                return 'ID re-verification failed after document expiration.';

            case UserStatusReason.B0:
                return 'Documents are uploaded successfully for identity verification.';

            case UserStatusReason.B1:
                return 'Identity is not verified, manual intervention required.';

            case UserStatusReason.B2:
                return 'SDK token is no longer valid, please generate a new SDK token.';

            case UserStatusReason.B3:
                return 'Admin approved, proceed to KYC verification flow.';

            case UserStatusReason.B4:
                return 'Name mismatched.';

            case UserStatusReason.B5:
                return 'Identity verification consider with caution status and name mismatched.';

            case UserStatusReason.B6:
                return 'Identity verification consider.';

            case UserStatusReason.B7:
                return 'Date of birth consider.';

            case UserStatusReason.B8:
                return 'Documents are uploaded successfully for identity verification (where provider is not Onfido).';

            case UserStatusReason.B9:
                return 'Document issuing country mismatch.';

            case UserStatusReason.B10:
                return 'Identity verification consider with caution status and document issuing country mismatch.';

            case UserStatusReason.B11:
                return 'Documents are uploaded successfully for identity verification (multiple providers).';

            case UserStatusReason.B2E:
                return 'Generate SDK token, document expired (same as B2).';

            case UserStatusReason.B0E:
                return 'Documents re-uploaded after document expiration for identity verification (same as B0).';

            case UserStatusReason.B9E:
                return 'Document issuing country mismatch upon IDV re-verification after document expiration.';

            case UserStatusReason.B10E:
                return 'Identity verification consider with caution status and document issuing country mismatch upon IDV re-verification after document expiration.';

            case UserStatusReason.B11E:
                return 'Documents re-uploaded after document expiration for identity verification (Onfido) - same as B0E (multiple providers configured).';

            case UserStatusReason.B12:
                return 'Resubmission of document (configured provider is not Onfido).';
            
            case UserStatusReason.B19:
                return 'Identity verification consider, contact Admin.';

            case UserStatusReason.S0:
                return 'Temporary suspension: initiated by the user or system user or the system.';

            case UserStatusReason.S1:
                return 'Inactivity over time.';

            case UserStatusReason.S2:
                return 'Suspicious activity was identified.';

            case UserStatusReason.S3:
                return 'Admin rejected due to ID verification failed.';

            case UserStatusReason.S4:
                return 'Admin suspended due to fraudulent activity.';

            case UserStatusReason.S5:
                return 'Admin rejected due to ID verification failed.';

            case UserStatusReason.S6:
                return 'Admin suspended due to certain reason.';

            case UserStatusReason.S7:
                return 'IDV not renewed and document expiration grace period';

            case UserStatusReason.T0:
                return 'Closed by user.';

            case UserStatusReason.T1:
                return 'Closed by issuer.';

            case UserStatusReason.T2:
                return 'Failed KYC.';

            case UserStatusReason.T3:
                return 'Fraudulent activity was identified.';

            case UserStatusReason.T4:
                return 'Matched with an Office of Foreign Assets Control list.';

            case UserStatusReason.T5:
                return 'Admin terminated due to ID verification failed.';

            case UserStatusReason.T6:
                return 'Admin terminated due to KYC failed.';

            case UserStatusReason.T7:
                return 'Admin terminated due to limited time exceeded and action taken for ID verification fail.';

            case UserStatusReason.T8:
                return 'Admin terminated due to KYC OGS response.';

            case UserStatusReason.T9:
                return 'Admin terminated due to ID verification failed.';

            case UserStatusReason.T10:
                return 'Admin terminated due to limited time exceeded and action taken for KYB fail.';

            case UserStatusReason.T11:
                return 'Admin terminated due to certain reason.';

            case UserStatusReason.T12:
                return 'System terminated due to ID verification rejected.';

            case UserStatusReason.T13:
                return 'System terminated due to ID verification suspected.';

            case UserStatusReason.T12E:
                return 'System terminated due to ID verification rejected after document expiration for identity verification (same as T12).';

            case UserStatusReason.T13E:
                return 'System terminated due to ID verification suspected after document expiration for identity verification (same as T13).';

            case UserStatusReason.O1:
                return 'Other reason.';

            default:
                return '';
        }
    }
}
