<div class="admin-profile-modal">
    <div class="modal-wrapper">
        <div class="modal-header mb-2rem justify-content-between">
            <div class="modal-heading d-flex">
                <h5 class="title">Profile Detail</h5>
                <button mat-flat-button class="flat-btn header-edit-btn" [disabled]="isEditBtnDisabled" (click)="enableFields()">
                    <mat-icon fontIcon="edit" /> Edit
                </button>
            </div>
            <mat-icon (click)="dialogRef.close()" fontIcon="close" class="close_modal" />
        </div>
        <div class="modal-content">
            <div *ngIf="!isLoading" class="form-body">
                <div class="field d-flex flex-row align-items-center">
                    <p class="w-40 field-label required">First Name</p>
                    <div class="w-60 field-value">
                        <app-value-field [editMode]="true">
                            <app-value-field-input
                                *ngIf="adminProfileForm.controls.first_name"
                                [controlDisplayName]="'First Name'"
                                [control]="adminProfileForm.controls.first_name"
                                appValueField="editField"
                            />
                        </app-value-field>
                    </div>
                </div>
                <div class="field d-flex flex-row align-items-center">
                    <p class="w-40 field-label required">Last Name</p>
                    <div class="w-60 field-value">
                        <app-value-field [editMode]="true">
                            <app-value-field-input
                                *ngIf="adminProfileForm.controls.last_name"
                                [controlDisplayName]="'Last Name'"
                                [control]="adminProfileForm.controls.last_name"
                                appValueField="editField"
                            />
                        </app-value-field>
                    </div>
                </div>
                <div class="field d-flex flex-row align-items-center">
                    <p class="w-40 field-label required">Status</p>
                    <div class="w-60 field-value">
                        <app-status
                            [ngClass]="{ disabled: !isEditMode, terminated: adminProfileForm.get('status')?.value === 'TERMINATED' }"
                            [isDropdown]="isEditMode"
                            [matMenuTriggerFor]="menu"
                            [status]="adminProfileForm.get('status')?.value"
                        />
                    </div>
                </div>
                <div class="field d-flex flex-row align-items-center">
                    <p class="w-40 field-label required">Phone</p>
                    <div class="w-60 field-value">
                        <form [formGroup]="adminProfileForm" class="form">
                            <ngx-mat-intl-tel-input
                                [enablePlaceholder]="true"
                                [enableSearch]="true"
                                [searchPlaceholder]="'Search country..'"
                                [class.input-invalid]="isInvalid(adminProfileForm.controls.mobile)"
                                class="w-100"
                                formControlName="mobile"
                                name="mobile"
                            />
                        </form>
                        <div class="validation-error">
                            <small
                                *ngIf="
                                    adminProfileForm.get('mobile')?.hasError('required') &&
                                    (adminProfileForm.get('mobile')?.touched || adminProfileForm.get('mobile')?.dirty)
                                "
                                class="text-danger w-100"
                            >
                                Phone number is required.
                            </small>
                            <small
                                *ngIf="
                                    adminProfileForm.get('mobile')?.hasError('validatePhoneNumber') &&
                                    (adminProfileForm.get('mobile')?.touched || adminProfileForm.get('mobile')?.dirty)
                                "
                                class="text-danger w-100"
                            >
                                Phone number is invalid.
                            </small>
                            <small
                                *ngIf="
                                    adminProfileForm.get('mobile')?.hasError('pattern') &&
                                    (adminProfileForm.get('mobile')?.touched || adminProfileForm.get('mobile')?.dirty)
                                "
                                class="text-danger w-100"
                            >
                                Please enter a valid phone number.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="field d-flex flex-row align-items-center">
                    <p class="w-40 field-label required">Email</p>
                    <div class="w-60 field-value">
                        <app-value-field [editMode]="true">
                            <app-value-field-input
                                *ngIf="adminProfileForm.controls.email"
                                [controlDisplayName]="'Email'"
                                [control]="adminProfileForm.controls.email"
                                appValueField="editField"
                            />
                        </app-value-field>
                    </div>
                </div>
                <div class="field d-flex flex-row align-items-center">
                    <p class="w-40 field-label required">Two factor authentication</p>
                    <div class="w-60 field-value">
                        <app-value-field [editMode]="true" class="w-100">
                            <app-value-field-switch
                                [control]="adminProfileForm.controls.two_fa_required"
                                appValueField="editField"
                                [controlDisplayName]="'Two-factor Authentication'"
                            />
                        </app-value-field>
                    </div>
                </div>
            </div>
            <app-spinner [isLoading]="isLoading" />
        </div>
        <div class="m_footer">
            <button mat-flat-button class="flat-btn" (click)="dialogRef.close()">Close</button>
            <button
                mat-raised-button
                class="save-btn"
                [disabled]="!adminProfileForm.dirty || adminProfileForm.invalid || !isEditMode"
                (click)="updateAdminProfileDetails()"
            >
                Save
            </button>
        </div>
    </div>
    <mat-menu #menu="matMenu">
        <button (click)="changeStatus(userStatus.ACTIVE)" mat-menu-item>Active</button>
        <button (click)="changeStatus(userStatus.INACTIVE)" mat-menu-item>Suspended</button>
        <button (click)="changeStatus(userStatus.TERMINATED)" mat-menu-item>Terminated</button>
    </mat-menu>
</div>
