import { Pipe, PipeTransform } from '@angular/core';
import { Optional, TNotificationsCategory } from '@lib/interfaces';

@Pipe({
    name: 'notificationType',
    standalone: true,
})
export class NotificationTypePipe implements PipeTransform {
    public transform(value: Optional<TNotificationsCategory>): string {
        switch (value) {
            case TNotificationsCategory.THREE_D_SECURE_OTP:
                return '3D Secure OTP';

            case TNotificationsCategory.ITSP_OTP:
                return 'ITSP OTP';

            default:
                return '';
        }
    }
}
