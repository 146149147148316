import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerStatus, CustomerStatusLabels } from '@lib/enums/customer.enum';

interface CustomerStatusEnum {
    [key: string]: CustomerStatus;
}

interface CustomerStatusLabelsEnum {
    [key: string]: CustomerStatusLabels;
}

@Component({
    selector: 'app-chips-dropdown',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './chips-dropdown.component.html',
    styleUrls: ['./chips-dropdown.component.scss'],
})
export class ChipsDropdownComponent {
    @Input() status?: string = '';
    @Input() isDropDown = false;
    openChips = false;
    statusEnum: CustomerStatusEnum = CustomerStatus;
    statusEnumLabels: CustomerStatusLabelsEnum = CustomerStatusLabels;

    toggleChipsDropDown(): void {
        this.openChips = !this.openChips;
    }
}
