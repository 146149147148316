import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { TransferSourceChannel } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'sourceChannel',
})
export class SourceChannelPipe implements PipeTransform {
    public transform(value: Optional<TransferSourceChannel>): string {
        switch (value) {
            case TransferSourceChannel.API:
                return 'API';

            case TransferSourceChannel.DAPI:
                return 'DAPI';

            case TransferSourceChannel.MPGS:
                return 'MPGS';

            default:
                return '';
        }
    }
}
