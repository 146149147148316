import { Directive, Injector, Input, TemplateRef } from '@angular/core';
import { Nullable } from '@lib/interfaces';
import { BaseValueFieldComponent } from '@lib/modules/value-field/base';

export type TValueFieldType = 'display' | 'editField';

@Directive({
    selector: '[appValueField]',
})
export class ValueFieldDirective {
    @Input({ required: true }) public appValueField: TValueFieldType;

    public get editableField(): Nullable<BaseValueFieldComponent> {
        return this.injector.get(BaseValueFieldComponent, null, { host: true, optional: true });
    }

    public get displayTemplate(): Nullable<TemplateRef<void>> {
        return this.injector.get(TemplateRef, null, { optional: true });
    }

    public constructor(private readonly injector: Injector) {}
}
