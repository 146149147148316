import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-header-left',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './header-left.component.html',
    styleUrls: ['./header-left.component.scss'],
})
export class HeaderLeftComponent {}
