import { Pipe, PipeTransform } from '@angular/core';
import { EmploymentType, Optional } from '@lib/interfaces';

@Pipe({
    name: 'employmentType',
    standalone: true,
})
export class EmploymentTypePipe implements PipeTransform {
    public transform(value: Optional<EmploymentType>): string {
        switch (value) {
            case EmploymentType.SALARIED:
                return 'Salaried';

            case EmploymentType.SELF_EMPLOYED:
                return 'Self Employed';

            case EmploymentType.RETIRED:
                return 'Retired';

            case EmploymentType.NOT_EMPLOYED:
                return 'Not Employed';

            case EmploymentType.BUSINESS_OWNER:
                return 'Business Owner';

            case EmploymentType.STUDENT:
                return 'Student';

            case EmploymentType.HOMEMAKER:
                return 'Homemaker';

            case EmploymentType.OTHER:
                return 'Other';

            default:
                return '';
        }
    }
}
