import { AfterContentInit, Component, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { IValueFieldOption, ValueFieldOptionDirective } from '@lib/modules/value-field/directives';
import { Nullable, Optional } from '@lib/interfaces';
import { BaseValueFieldComponent } from '@lib/modules/value-field/base';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

export type FormMultiSelectControlInput<T> = FormArray<FormControl<Optional<T>>>;

@Component({
    selector: 'app-value-field-multi-select',
    templateUrl: './value-field-multi-select.component.html',
    styleUrls: ['./value-field-multi-select.component.scss'],
    providers: [{ provide: BaseValueFieldComponent, useExisting: ValueFieldMultiSelectComponent }],
})
export class ValueFieldMultiSelectComponent<T> extends BaseValueFieldComponent implements AfterContentInit {
    @Input({ required: true }) public controlDisplayName: string;
    @Input() public placeholder = '';
    @Input() showError = true;

    @Output() public scrollBottom: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('selectBox') protected selectBox: ElementRef<HTMLDivElement>;
    @ViewChild('selectBoxTrigger') protected selectBoxTrigger: MatMenuTrigger;
    @ViewChild('selectOptionMenu') protected selectOptionsMenu: Optional<MatMenu>;

    @ContentChildren(ValueFieldOptionDirective<T>) protected valueFieldOptionList: Optional<QueryList<ValueFieldOptionDirective<T>>>;

    protected inputOptions: Array<IValueFieldOption<T>> = [];
    protected selectedOptions: Array<IValueFieldOption<T>> = [];

    private _control: FormMultiSelectControlInput<T>;

    protected scrollEventSubscription: Nullable<Subscription> = null;

    public get control(): FormMultiSelectControlInput<T> {
        return this._control;
    }

    @Input({ required: true })
    public set control(control: FormMultiSelectControlInput<T>) {
        this._control = control;

        this.subscribeToControlValueChanges(control);
    }

    protected get placeholderText(): string {
        if (this.placeholder) return this.placeholder;

        return this.controlDisplayName;
    }

    private _optionsLoading = false;

    public get optionsLoading(): boolean {
        return this._optionsLoading;
    }
    @Input()
    public set optionsLoading(optionsLoading: boolean) {
        this._optionsLoading = optionsLoading;

        if (optionsLoading) this.scrollOptionsMenuToBottom();
    }

    public constructor(private readonly formBuilder: FormBuilder) {
        super();
    }

    public ngAfterContentInit(): void {
        this.subscribeToSelectOptionChanges();
    }

    protected adjustMenuOptionWidth(): void {
        this.control.markAsTouched();

        const optionMenu: Nullable<HTMLElement> = document.querySelector('.select-option');
        if (!optionMenu) return;

        optionMenu.style.minWidth = `${this.selectBox.nativeElement.clientWidth}px`;

        this.subscribeToOptionsMenuScrollEvent();
    }

    protected toggleSelectedOption(inputOption: IValueFieldOption<T>): void {
        const selectedOptionIndex: number = this.selectedOptions.findIndex((selectionOption: IValueFieldOption<T>): boolean => selectionOption.value === inputOption.value);

        this[selectedOptionIndex === -1 ? 'pushSelectedOption' : 'removeSelectedOption'](inputOption);

        this.control.markAsDirty();

        this.selectBoxTrigger.closeMenu();
    }

    private pushSelectedOption(inputOption: IValueFieldOption<T>): void {
        this.control.push(this.formBuilder.nonNullable.control(inputOption.value));
    }

    private removeSelectedOption(inputOption: IValueFieldOption<T>): void {
        const formControlOptionIndex: number = this.control.controls.findIndex((optionControl: FormControl<Optional<T>>): boolean => optionControl.value === inputOption.value);

        if (formControlOptionIndex > -1) this.control.removeAt(formControlOptionIndex);
    }

    private subscribeToControlValueChanges(control: FormMultiSelectControlInput<T>): void {
        this.setSelectedValues();

        if (this.controlValueChangeSubscription && !this.controlValueChangeSubscription.closed) this.controlValueChangeSubscription.unsubscribe();

        this.controlValueChangeSubscription = control.valueChanges.subscribe((): void => {
            this.setSelectedValues();
        });
    }

    private subscribeToSelectOptionChanges(): void {
        if (!this.valueFieldOptionList) return;

        this.createInputOptions(this.valueFieldOptionList);

        this.addSubscription(
            this.valueFieldOptionList.changes.subscribe((valueFieldOptionList: QueryList<ValueFieldOptionDirective<T>>): void => {
                this.createInputOptions(valueFieldOptionList);
            }),
        );
    }

    private createInputOptions(valueFieldOptionList: QueryList<ValueFieldOptionDirective<T>>): void {
        this.inputOptions = valueFieldOptionList.map(({ appValueFieldOption, templateRef }: ValueFieldOptionDirective<T>): IValueFieldOption<T> => {
            return { value: appValueFieldOption, templateRef };
        });

        this.setSelectedValues();
    }

    private setSelectedValues(): void {
        const controlValues: Array<Optional<Nullable<T>>> = this.control.value as Array<Optional<Nullable<T>>>;

        this.selectedOptions = this.inputOptions.filter((inputOption: IValueFieldOption<T>): boolean => controlValues.includes(inputOption.value));
    }

    private subscribeToOptionsMenuScrollEvent(): void {
        if (!this.selectOptionsMenu) return;

        const optionsMenuElement: Nullable<Element> = document.querySelector(`#${this.selectOptionsMenu.panelId}`);

        if (!optionsMenuElement) return;

        this.scrollEventSubscription = fromEvent(optionsMenuElement, 'scroll')
            .pipe(debounceTime(250))
            .subscribe((event: Event): void => {
                const { scrollHeight, clientHeight, scrollTop }: HTMLDivElement = event.target as HTMLDivElement;

                const hasReachedBottom: boolean = Math.floor(scrollHeight - clientHeight - scrollTop) === 0;

                if (hasReachedBottom) this.scrollBottom.emit();
            });
    }

    private scrollOptionsMenuToBottom(): void {
        if (!this.selectOptionsMenu) return;

        const optionsMenuElement: Nullable<Element> = document.querySelector(`#${this.selectOptionsMenu.panelId}`);

        if (!optionsMenuElement) return;

        optionsMenuElement.scrollTop = optionsMenuElement.scrollHeight;
    }

    protected cleanUpMenuScrollSubscription(): void {
        if (!this.scrollEventSubscription) return;

        if (!this.scrollEventSubscription.closed) this.scrollEventSubscription.unsubscribe();

        this.scrollEventSubscription = null;
    }
}
