import { Pipe, PipeTransform } from '@angular/core';
import { OperationType } from '@lib/interfaces/otp-configuration.interface';
import { Optional } from '@lib/interfaces';

@Pipe({
    name: 'otpOperationType',
    standalone: true,
})
export class OtpOperationTypePipe implements PipeTransform {
    public transform(value: Optional<OperationType>): string {
        switch (value) {
            case OperationType.TRANSACTION:
                return 'Transaction';

            default:
                return '';
        }
    }
}
