import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { TransactionTypePriority } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'transactionTypePriority',
})
export class TransactionTypePriorityPipe implements PipeTransform {
    public transform(value: Optional<TransactionTypePriority>): string {
        switch (value) {
            case TransactionTypePriority.CASH_ADVANCES:
                return 'Cash Advances';

            case TransactionTypePriority.PURCHASE:
                return 'Purchase';

            case TransactionTypePriority.FEES_AND_CHARGES:
                return 'Fees & Charges';

            case TransactionTypePriority.FINANCIAL_CHARGES:
                return 'Financial Charges';

            default:
                return '';
        }
    }
}
