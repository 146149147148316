export interface MenuItem {
    title: string;
    icon: string;
    link?: string;
    section?: string;
    permissions?: string[];
    external?: boolean;
    children?: ChildMenuItem[];
}

interface ChildMenuItem {
    title: string;
    link: string;
    permissions?: string[];
}
