import { Routes } from '@angular/router';
import { LayoutComponent } from '@pages/layout/layout.component';
import { authGuard, permissionGuard } from '@lib/guards';
import { Permission } from '@lib/enums';
import { TermsAndConditionComponent } from '@lib/components/fib/terms-and-condition.component';

export const routes: Routes = [
    {
        path: 'auth/:tenant',
        loadChildren: async () => (await import('@pages/auth')).routes,
        canMatch: [authGuard({ requiresAuthentication: false })],
    },
    {
        path: 'terms-and-condition',
        component: TermsAndConditionComponent,
    },
    {
        path: '',
        component: LayoutComponent,
        canMatch: [authGuard()],
        children: [
            {
                path: '',
                loadChildren: async () => (await import('@pages/welcome-screen')).routes,
            },
            {
                path: 'report',
                data: { permission: [] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/analytics')).routes,
            },
            {
                path: 'home',
                data: { permission: [Permission.Home] },
                canActivate: [permissionGuard],
                loadComponent: async () => (await import('@pages/home/home.component')).HomeComponent,
            },
            {
                path: 'customers',
                data: { permission: [Permission.Customer] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/customer')).routes,
            },
            {
                path: 'card-product',
                data: { permission: [Permission.ProductManagement] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/card-products')).routes,
            },
            {
                path: 'cards',
                canActivate: [permissionGuard],
                data: { permission: [Permission.CustomerCards] },
                loadChildren: async () => (await import('@pages/cards')).routes,
            },
            {
                path: 'call-center',
                data: { permission: [Permission.CallCenter] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/call-center')).routes,
            },
            {
                path: 'control-center',
                data: { permission: [Permission.Settings] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/control-center')).routes,
            },
            {
                path: 'feedback',
                loadChildren: async () => (await import('@pages/feedback')).routes,
            },
            {
                path: 'access-management',
                data: { permission: [Permission.AccessManagement] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/access-management')).routes,
            },
            {
                path: 'product-settings',
                data: { permission: [Permission.ProductSettings] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/product-settings')).routes,
            },
            {
                path: 'transactions',
                data: { permission: [Permission.CustomerCards, Permission.CustomerCardsTransactions] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/transaction')).routes,
            },
            {
                path: 'bulk-upload-cash',
                data: { permission: [] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/bulk-upload-cash')).routes,
            },
            {
                path: 'atm',
                data: { permission: [Permission.Locations] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/atm')).routes,
            },
            {
                path: '**',
                redirectTo: ' ',
                pathMatch: 'full',
            },
        ],
    },
    { path: '**', pathMatch: 'full', redirectTo: '/auth/tenant0_api/login' },
];
