import { Pipe, PipeTransform } from '@angular/core';
import { Optional, UserTitle } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'userTitle',
})
export class UserTitlePipe implements PipeTransform {
    public transform(value: Optional<UserTitle>): string {
        switch (value) {
            case UserTitle.MR:
                return 'Mr';

            case UserTitle.MRS:
                return 'Mrs';

            case UserTitle.MISS:
                return 'Miss';

            default:
                return '';
        }
    }
}
