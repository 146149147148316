<div class="p-2 d-flex flex-column justify-content-center align-items-center rounded rounded-3">
    <div
        (click)="updateCardProductStatus(cardProductInfo.id, 'ACTIVE')"
        *ngIf="cardProductInfo.status !== 'ACTIVE'"
        class="mx-0 my-1 px-0 py-2 w-100 text-success pointer border rounded-pill d-flex flex-row justify-content-between"
    >
        <div class="w-25 d-flex flex-row justify-content-center align-items-center">
            <div class="border border-5 border-success rounded-circle"></div>
        </div>
        <p class="m-0 p-0 w-75 text-center">ACTIVE</p>
    </div>
</div>
