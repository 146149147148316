import { Pipe, PipeTransform } from '@angular/core';
import { PosEnvironment } from '@lib/enums';
import { Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'posEnvironment',
})
export class PosEnvironmentPipe implements PipeTransform {
    public transform(value: Optional<PosEnvironment>): string {
        switch (value) {
            case PosEnvironment.UNKNOWN:
                return 'Unknown';

            case PosEnvironment.ATTENDED_POS:
                return 'Attended POS';

            case PosEnvironment.ECOMMERCE:
                return 'Ecommerce';

            default:
                return '';
        }
    }
}
