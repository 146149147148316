import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { ITableTemplate } from '@lib/modules/table/interfaces';

@Component({
    selector: 'app-table-button',
    templateUrl: './table-button.component.html',
    styleUrls: ['./table-button.component.scss'],
})
export class TableButtonComponent implements ITableTemplate {
    @Input({ required: true }) tableButtonText = '';
    @Input() tableButtonFontIcon: Optional<string>;
    @Input() tableButtonSvgIcon: Optional<string>;
    @Input() tableButtonCloseIcon: Optional<boolean>;

    @Output() tableButtonClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() removeFilters: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild(TemplateRef<void>) public templateRef: Optional<TemplateRef<void>>;
}
