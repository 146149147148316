import { Component } from '@angular/core';
import { AngularMaterialModule } from 'src/app/material.module';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BaseComponent } from '../base';
import { AuthService } from '@lib/services';
import { finalize } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionSuccessModalComponent } from '../action-success-modal/action-success-modal.component';
import { ApiResponseError } from '@lib/interfaces';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

@Component({
    selector: 'app-change-password-modal',
    standalone: true,
    imports: [AngularMaterialModule, ReactiveFormsModule, MatPasswordStrengthModule],
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent extends BaseComponent {
    passwordForm: FormGroup;
    isLoading = false;
    showCurrentPassword = false;
    showNewPassword = false;
    showConfirmPassword = false;
    passwordStrength: string;

    constructor(public dialogRef: MatDialogRef<ChangePasswordModalComponent>, private authService: AuthService, private fb: FormBuilder, private _snackbar: MatSnackBar, private dialog: MatDialog) {
        super();

        this.passwordForm = fb.group(
            {
                old_password: ['', [Validators.required]],
                new_password: ['', [Validators.required,Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
                confirm_password: ['', [Validators.required]],
            },
            {
                validator: this.comparePasswords('new_password', 'confirm_password'),
            },
        );
    }

    matchPasswords(): boolean {
        return this.passwordForm.get('new_password')?.value === this.passwordForm.get('confirm_password')?.value;
    }

    changePassword(): void {
        if (this.passwordForm.invalid) return;
        if (!this.matchPasswords()) {
            return;
        }

        this.isLoading = true;
        const formdata = this.passwordForm.getRawValue();
        const adminId = localStorage.getItem('adminId');
        const bodyData = {
            old_password: formdata.old_password,
            new_password: formdata.new_password,
        };

        this.addSubscription(
            this.authService
                .changePassword(adminId, bodyData)
                .pipe(
                    finalize(() => {
                        this.isLoading = false;
                    }),
                )
                .subscribe({
                    next: () => {
                        this.dialogRef.close();
                        this.dialog.open(ActionSuccessModalComponent, {
                            data: { message: 'New Password Saved' },
                            width: '588px',
                            height: '24rem',
                        });
                    },
                    error: (errorResp: ApiResponseError) => {
                        if (errorResp.error && errorResp.error.detail === 'User name or password is incorrect') {
                            errorResp.error.detail = 'Current password is incorrect.';
                        }

                        this.createApiErrorSnackBar(this._snackbar, errorResp);
                    },
                }),
        );
    }

    comparePasswords(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    strengthChange(passwordRating: number | null): void {
        if (!this.passwordForm.get('new_password')?.value) {
            this.passwordStrength = '';
            return;
        }

        switch (passwordRating) {
            case 0:
                this.passwordStrength = 'Weak Password';
                break;
            case 20:
                this.passwordStrength = 'Weak Password';
                break;
            case 40:
                this.passwordStrength = 'Weak Password';
                break;
            case 60:
                this.passwordStrength = 'Weak Password';
                break;
            case 80:
                this.passwordStrength = 'Weak Password';
                break;
            case 100:
                this.passwordStrength = 'Strong password';
                break;
            default:
                this.passwordStrength = 'Invalid rating';
        }
    }
}
