import { Pipe, PipeTransform } from '@angular/core';
import { Optional, Period } from '@lib/interfaces';

@Pipe({
    name: 'period',
    standalone: true,
})
export class PeriodPipe implements PipeTransform {
    public transform(value: Optional<Period>): string {
        switch (value) {
            case Period.DAILY:
                return 'Daily';

            case Period.MONTHLY:
                return 'Monthly';

            case Period.YEARLY:
                return 'Yearly';

            case Period.ALL_TIME:
                return 'All Time';

            case Period.NUM_OF_DAYS:
                return 'Daily';

            default:
                return '';
        }
    }
}
