import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@lib/components/base';
import { ICardProduct } from '@lib/interfaces/card-product.interface';
import { ProductManagementService } from '@lib/services/product-management/product-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-change-card-product-status',
    templateUrl: './change-card-product-status.component.html',
    styleUrls: ['./change-card-product-status.component.scss'],
})
export class ChangeCardProductStatusComponent extends BaseComponent implements OnInit {
    @Output() updateCardProduct: EventEmitter<ICardProduct> = new EventEmitter();
    cardProductInfo: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private productManagementService: ProductManagementService, private readonly changeCardProductStatus: MatDialogRef<ChangeCardProductStatusComponent>, private _snackBar: MatSnackBar) {
        super();
        this.cardProductInfo = data;
    }
    ngOnInit(): void {}

    updateCardProductStatus(id: any, changeCardStatus: any) {
        const changeStatus = { status: changeCardStatus };
        this.addSubscription(
            this.productManagementService.updateCardProductDetails(id, changeStatus).subscribe({
                next: (data) => {
                    this.cardProductInfo = data;
                    this.updateCardProduct.emit(this.cardProductInfo);
                    this.changeCardProductStatus.close(this.cardProductInfo);
                },
                error: ({ error }) => {
                    this.changeCardProductStatus.close(this.cardProductInfo);
                    console.error('Error while updating account and pin settings:', error);
                },
            }),
        );
    }
}
