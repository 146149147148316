import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { CardEntry } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'cardEntry',
})
export class CardEntryPipe implements PipeTransform {
    public transform(value: Optional<CardEntry>): string {
        switch (value) {
            case CardEntry.UNKNOWN:
                return 'Unknown';

            case CardEntry.MANUAL_ENTRY:
                return 'Manual Entry';

            case CardEntry.MAGNETIC_STRIPE:
                return 'Magnetic Stripe';

            case CardEntry.ICC:
                return 'ICC';

            case CardEntry.CARD_ON_FILE:
                return 'Card On File';

            default:
                return '';
        }
    }
}
