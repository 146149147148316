import { Component, ElementRef, EventEmitter, Input, isDevMode, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

function getFormArray(size: number): FormArray {
    const arr = [];

    for (let i = 0; i < size; i++) {
        arr.push(new FormControl(''));
    }

    return new FormArray(arr);
}

@Component({
    selector: 'app-value-field-otp',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ValueFieldOTPComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: ValueFieldOTPComponent,
            multi: true,
        },
        {
            provide: 'BaseValueFieldComponent',
            useExisting: 'ValueFieldInputComponent',
        },
    ],
    templateUrl: './value-field-otp.component.html',
    styleUrls: ['./value-field-otp.component.scss'],
})
export class ValueFieldOTPComponent implements ControlValueAccessor, Validator {
    @Input()
    set size(size: number) {
        this.inputs = getFormArray(size);
        this.defaultSize = size;
    }

    @Output() OTPValue = new EventEmitter();
    @Output() submitOTP = new EventEmitter();
    @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;

    protected defaultSize = 6;
    protected scheduledFocus: number | null = null;
    protected inputs = getFormArray(this.defaultSize);

    protected onChange?: (value: string) => void;
    protected onTouched?: () => void;

    writeValue(value: string): void {
        if (isDevMode() && value?.length) {
            throw new Error('Otp input is not supposed to be prefilled with data');
        }

        this.inputs.setValue(new Array(this.defaultSize).fill(''));
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputs.disable();
        } else {
            this.inputs.enable();
        }
    }

    validate(control: AbstractControl<string, string>): ValidationErrors | null {
        if (!control.value || control.value.length < this.defaultSize) {
            return {
                otpInput: 'Value is incorrect',
            };
        }

        return null;
    }

    handleKeyDown(e: KeyboardEvent, idx: number) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            if (idx > 0) {
                this.scheduledFocus = idx - 1;
            }
        }
    }

    handleInput() {
        this.updateWiredValue();

        if (this.scheduledFocus != null) {
            this.focusInput(this.scheduledFocus);
            this.scheduledFocus = null;
        }
    }

    handleKeyPress(e: KeyboardEvent, idx: number) {
        const isDigit = /\d/.test(e.key);

        if (e.key === 'v' && e.metaKey) {
            return true;
        }

        if (isDigit && idx + 1 < this.defaultSize) {
            this.scheduledFocus = idx + 1;
        }

        if (isDigit && this.inputs.controls[idx].value) {
            this.inputs.controls[idx].setValue('');
        }

        return isDigit;
    }

    handlePaste(e: ClipboardEvent, idx: number) {
        e.preventDefault();

        if (idx !== 0) {
            return;
        }

        const pasteData = e.clipboardData?.getData('text');
        const regex = new RegExp(`\\d{${this.defaultSize}}`);

        if (!pasteData || !regex.test(pasteData)) {
            return;
        }

        for (let i = 0; i < pasteData.length; i++) {
            this.inputs.controls[i].setValue(pasteData[i]);
        }

        this.focusInput(this.inputEls.length - 1);
        this.updateWiredValue();
    }

    handleFocus(e: FocusEvent) {
        (e.target as HTMLInputElement).select();
    }

    focusInput(idx: number) {
        setTimeout(() => this.inputEls.get(idx)?.nativeElement.focus());
    }

    updateWiredValue() {
        setTimeout(() => {
            this.OTPValue.emit(this.inputs.value.join(''));
            if (this.inputs.controls[5].value !== '') this.submitOTP.emit();
            this.onChange?.(this.inputs.value.join(''));
        });
    }
}
