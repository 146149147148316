import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { WalletType } from '@lib/enums';

@Pipe({
  standalone: true,
  name: 'walletType'
})
export class WalletTypePipe implements PipeTransform {

  public transform(value: Optional<WalletType>): string {
    switch (value) {
        case WalletType.BILL_PAYMENT:
            return 'Bill Payment';
        case WalletType.CURRENCY_EXCHANGE:
            return 'Currency Exchange';
        case WalletType.FUNDS_TRANSFER:
          return 'Funds Transfer';
        case WalletType.LOAD_CHECKOUT:
          return 'Load Checkout';
        case WalletType.LOAD_LEAN:
          return 'Load Lean';  
        case WalletType.REMITTANCES:
          return 'Remittances';  
        default:
            return '';
    }
}

}
