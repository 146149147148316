import { Pipe, PipeTransform } from '@angular/core';
import { Optional, UserStatus } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
    public transform(value: Optional<UserStatus>, lang: 'assertive' | 'passive'): string {
        switch (value) {
            case UserStatus.PENDINGKYC:
                return 'Pending KYC';

            case UserStatus.PENDINGKYB:
                return 'Pending KYB';

            case UserStatus.ACTIVE:
                return lang === 'assertive' ? 'Activate' : 'Active';

            case UserStatus.SUSPENDED:
                return lang === 'assertive' ? 'Suspend' : 'Suspended';

            case UserStatus.TERMINATED:
                return lang === 'assertive' ? 'Terminate' : 'Terminated';

            case UserStatus.PENDINGIDVERIFICATION:
                return 'Pending IDV';

            default:
                return '';
        }
    }
}
