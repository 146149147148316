import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ICardProduct } from '@lib/interfaces/card-product.interface';
import { DeepPartial, ICard, ICreateCardRequest, ITransactionIndex } from '@lib/interfaces';
import { IFee, IFeeDetail, ISearchCashbackResponse, ISearchCashbackWalletRequest, ISearchTransactionRequest, ISearchTransactionResponse, ISearchWalletResponse } from '@lib/interfaces/transaction.interface';

@Injectable({
    providedIn: 'root',
})
export class CardManagementService {
    public environment_url: string;

    public constructor(private readonly http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    searchUsers(searchInput: string): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/users:search`, {
            mobile: searchInput,
            after: null,
            limit: 10,
            order_by: 'desc',
            sort_by: 'created',
        });
    }

    getUserDetails(userId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/users/${userId}`, {
            headers: new HttpHeaders({
                'X-Consumer-Username': 'tenant1',
            }),
        });
    }

    getAccountsById(accountId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/accounts/${accountId}`);
    }

    getAccountsByUserId(userId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/accounts?user_id=${userId}`);
    }

    getCardById(cardId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}`);
    }

    getCardsByUserId(userId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/cards?user_id=${userId}`);
    }

    getCardsInfoByCardId(cardId: string): Observable<ICardProduct> {
        return this.http.get<ICardProduct>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardId}`);
    }

    public createCard(cardData: DeepPartial<ICreateCardRequest>): Observable<ICard> {
        return this.http.post<ICard>(`${this.environment_url}nfront/v1/proxy/cards`, cardData, {
            headers: new HttpHeaders({
                'x-nymos-idempotency-key': Math.random(),
            }),
        });
    }

    public searchTransactions(searchPayload: DeepPartial<ISearchTransactionRequest>): Observable<ISearchTransactionResponse> {
        return this.http.post<ISearchTransactionResponse>(`${this.environment_url}nfront/v1/proxy/transactions:search`, searchPayload);
    }

    public searchWallet(searchPayload: DeepPartial<ISearchCashbackWalletRequest>): Observable<ISearchWalletResponse> {
        return this.http.post<ISearchWalletResponse>(`${this.environment_url}nfront/v1/transactions:search`, searchPayload);
    }

    public searchCashback(searchPayload: DeepPartial<ISearchCashbackWalletRequest>): Observable<ISearchCashbackResponse> {
        return this.http.post<ISearchCashbackResponse>(`${this.environment_url}nfront/v1/transactions:search`, searchPayload);
    }

    public getTransactionDetails(transactionId: string): Observable<ITransactionIndex> {
        return this.http.get<ITransactionIndex>(`${this.environment_url}nfront/v1/proxy/transactions/${transactionId}`);
    }

    fetchCardLimits(cardId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v2/proxy/cards/${cardId}:limitinfo`);
    }

    fetchCardAccount(cardId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}/accounts`);
    }

    createLimitOnCard(cardId: string, payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}:customlimit`, payload);
    }

    updateLimitOnCard(cardId: string, payload: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}:customlimit`, payload);
    }

    freezeCardStatus(cardId: string, payload: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}:statuschange`, payload);
    }

    getfeeList(cardProductId: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/fees`);
    }

    getCardProductfeeData(feeId: string): Observable<IFee[]> {
        return this.http.get<IFee[]>(`${this.environment_url}nfront/v1/proxy/fees/${feeId}`);
    }

    unlinkFees(cardproductId: any, payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardproductId}/fees:unlink`, payload);
    }

    updateFeeData(id: any, payload: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/proxy/fees/${id}`, payload);
    }

    getAllFeeList(payload: any): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/fees?limit=${payload.limit}&after=${payload.after}`);
    }

    createFeeList(payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/fees`, payload);
    }

    getFeeDetail(feeId: any): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/fees/${feeId}`);
    }
}
