import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { AuthExpiredByType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'authExpiredBy',
})
export class AuthExpiredByPipe implements PipeTransform {
    public transform(value: Optional<AuthExpiredByType>): string {
        switch (value) {
            case AuthExpiredByType.AUTO:
                return 'Auto';

            case AuthExpiredByType.MANUAL:
                return 'Manual';

            default:
                return '';
        }
    }
}
