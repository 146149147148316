import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { CardProductStatus } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'cardProductStatus',
})
export class CardProductStatusPipe implements PipeTransform {
    public transform(value: Optional<CardProductStatus>): string {
        switch (value) {
            case CardProductStatus.INACTIVE:
                return 'Inactive';

            case CardProductStatus.ACTIVE:
                return 'Active';

            case CardProductStatus.TERMINATED:
                return 'Terminated';

            default:
                return '';
        }
    }
}
