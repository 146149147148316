import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomersBeneficiaryService {

  public readonly environment_url: string;
  public headers = new HttpHeaders({
    'x-nfront-device-id': '10.0.85.111'
  });

  public constructor(private readonly http: HttpClient) {
    this.environment_url = environment.api_url;
  }

  getBeneficiaryDetails(userId:string,sub_category_display_name:string|null=null): Observable<any> {
    let user_id = userId;
    let queryParams = '';
    if(sub_category_display_name){
      queryParams = `&subcategory_display_name=${sub_category_display_name}`;
    }else{
      // queryParams = `&country=PAK`;
    }
    return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/remittances/beneficiary?user_id=${user_id}${queryParams}`, {
      headers: this.headers,
    });
  }
}

