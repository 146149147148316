export enum CustomerStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    SUSPENDED = 'Suspended',
    TERMINATED = 'Terminated',
    PENDINGKYC = 'Pending KYC',
    PENDINGKYB = 'Pending KYB',
    VERIFIED = 'Verified',
    PENDINGIDVERIFICATION = 'Pending IDV',
    LOCK = 'Locked',
}

export enum CustomerStatusLabels {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    SUSPENDED = 'Suspended',
    TERMINATED = 'Terminated',
    PENDINGKYC = 'Pending KYC',
    PENDINGKYB = 'Pending KYB',
    PENDINGIDVERIFICATION = 'Pending ID Verification',
    LOCK = 'Locked',
}
