<div class="cash-pickup-table">
    <app-table
        [dataColumns]="columns"
        [dataDefaultColumns]="defaultColumns"
        [dataHasMoreRecords]="hasMoreRecords"
        [dataLoading]="cashPickupListLoading"
        [dataSource]="cashPickupList"
        class="card-table"
        dataAbsenceText="No Cash Pickups"
        dataName="Cash Pickups"
        svgIcon="card-table"
    >
        <ng-template [appTableColumnDataSource]="cashPickupList" appTableColumn="created" let-card>
            <p>{{ card.created | date }}</p>
        </ng-template>
    </app-table>
</div>
