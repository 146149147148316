import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { EcommerceSecurityLevel } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'ecommerceSecurityLevel',
})
export class EcommerceSecurityLevelPipe implements PipeTransform {
    public transform(value: Optional<EcommerceSecurityLevel>): string {
        switch (value) {
            case EcommerceSecurityLevel.THREE_DS_ONE:
                return '3DS1';

            case EcommerceSecurityLevel.THREE_DS_TWO:
                return '3DS2';

            case EcommerceSecurityLevel.THREE_DS_TWO_POINT_TWO:
                return '3DS2.2';

            default:
                return '';
        }
    }
}
