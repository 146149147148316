import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    private readonly _scrollBottom: Subject<boolean> = new Subject<boolean>();

    public get scrollBottom(): Subject<boolean> {
        return this._scrollBottom;
    }

    public bottomReached(): void {
        this._scrollBottom.next(true);
    }

    public bottomLost(): void {
        this._scrollBottom.next(false);
    }
}
