<app-table
    (dataLoadMore)="searchCashbacks()"
    (dataSort)="applySort($event)"
    [dataColumns]="columns"
    [dataDefaultColumns]="defaultColumns"
    [dataHasMoreRecords]="hasMoreRecords"
    [dataLoading]="loading"
    [dataSortableColumns]="sortableColumns"
    [dataSource]="cashbacks"
    class="transaction-table"
    dataAbsenceText="No Cash Back"
    dataName="{{ cashbackHeading }}"
>
    <ng-template [appTableColumnDataSource]="cashbacks" appTableColumn="transaction_timestamp" let-transaction>
        <p>{{ transaction.transaction_timestamp | date : 'MMM dd, yyyy hh:mm aaa' | optionalPlaceholder }}</p>
    </ng-template>
</app-table>