<div [ngClass]="{ 'text-center': !(limit ?? false) }" class="velocity-limit-widget mb-3 position-relative">
    <div *ngIf="limit">
        <div class="w-header d-flex">
            <div class="w-100">
                <div class="d-flex align-items-start justify-content-between mb-3 pb-1">
                    <div class="label small text-secondary lh-base mb-0">{{ limit.description.split('|')[0] }}</div>
                    <span *ngIf="isEditIconShow">
                        <a type="button" (click)="revertLimit(limit)" class="deleteBtn ms-4" *ngIf="limit?.linked_to === 'CARD'">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.5 6V0.665625L2.52812 2.69375C3.99062 1.04375 6.11875 0 8.5 0C12.9187 0 16.5 3.58125 16.5 8C16.5 12.4187 12.9187 16 8.5 16C6.29062 16 4.29062 15.1062 2.84375 13.6562L4.25937 12.2437C5.34375 13.3281 6.84375 14 8.5 14C11.8125 14 14.5 11.3156 14.5 8C14.5 4.68437 11.8125 2 8.5 2C6.67188 2 5.05 2.82812 3.95312 4.11875L5.83438 6H0.5Z"
                                    fill="#ED6C68"
                                />
                            </svg>
                        </a>
                    </span>
                </div>
                <div class="d-flex align-items-start">
                    <div *ngIf="!isEditable" class="value fw-semibold lh-1-8">
                        <span>{{ limit.max_amount | number : '1.2-2' }} {{ limit.currency }}</span>
                        <span class="valueLine">/</span>
                        <span class="totalValue fw-semibold">{{ leftLimit | number : '1.2-2' }} {{ limit.currency }}</span>
                    </div>

                    <div *ngIf="isEditable" class="limitForm">
                        <form [formGroup]="limitForm">
                            <div class="material-textfield">
                                <input class="rounded-2 border px-2" formControlName="limit_amount" type="text" />
                            </div>
                        </form>
                    </div>
                    <div class="actions">
                        <div *ngIf="isEditIconShow">
                            <!-- <mat-icon (click)="revertLimit(limit)" *ngIf="limit?.linked_to === 'CARD'" class="fs-5 mr-10 cursor-pointer text-secondary" title="Revert">autorenew</mat-icon> -->
                            <a (click)="editLimit(limit)" type="button" class="editAmount">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path
                                        d="M11.2296 3.50652L12.9946 5.27069M12.3646 1.95236L7.59214 6.72486C7.34554 6.9711 7.17737 7.28484 7.1088 7.62652L6.66797 9.83319L8.87464 9.39152C9.2163 9.32319 9.52964 9.15569 9.7763 8.90902L14.5488 4.13652C14.6922 3.99311 14.806 3.82285 14.8836 3.63547C14.9612 3.44809 15.0012 3.24726 15.0012 3.04444C15.0012 2.84162 14.9612 2.64079 14.8836 2.45341C14.806 2.26603 14.6922 2.09577 14.5488 1.95236C14.4054 1.80894 14.2351 1.69518 14.0478 1.61756C13.8604 1.53995 13.6595 1.5 13.4567 1.5C13.2539 1.5 13.0531 1.53995 12.8657 1.61756C12.6783 1.69518 12.5081 1.80894 12.3646 1.95236Z"
                                        stroke="#2D365C"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M13.3346 11.4993V13.9993C13.3346 14.4414 13.159 14.8653 12.8465 15.1779C12.5339 15.4904 12.11 15.666 11.668 15.666H2.5013C2.05928 15.666 1.63535 15.4904 1.32279 15.1779C1.01023 14.8653 0.834637 14.4414 0.834637 13.9993V4.83268C0.834637 4.39065 1.01023 3.96673 1.32279 3.65417C1.63535 3.34161 2.05928 3.16602 2.5013 3.16602H5.0013"
                                        stroke="#2D365C"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div *ngIf="isEditable && !actionLoading" class="editable d-flex ms-3">
                            <mat-icon (click)="modifyLimit(limit)" class="fs-5 mr-10 cursor-pointer text-secondary" title="Save">check</mat-icon>
                            <mat-icon (click)="closeEditor()" class="fs-5 cursor-pointer text-secondary" title="Cancel">close</mat-icon>
                        </div>
                        <app-spinner [isLoading]="actionLoading" />
                    </div>
                </div>
            </div>
        </div>
        <div class="w-body progress-bar">
            <mat-progress-bar [color]="color" [value]="consumedLimitPercentage" mode="determinate"></mat-progress-bar>
        </div>
    </div>

    <app-spinner [isLoading]="limit ? false : true" />
</div>
