import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-toggle',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
    @Output() valueChangeEmitter = new EventEmitter();
    @Input() checked = false;
    @Input() disabled = false;
    @Input() id = '';

    onCheckboxChange(event: Event): void {
        this.valueChangeEmitter.emit((event.target as HTMLInputElement).checked);
    }
}
