import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabCashPickupComponent } from './components/tab-cash-pickup/tab-cash-pickup.component';
import { TabBankTransferComponent } from './components/tab-bank-transfer/tab-bank-transfer.component';

@Component({
  selector: 'app-tab-remittance',
  standalone: true,
  imports: [CommonModule,TabCashPickupComponent,TabBankTransferComponent],
  templateUrl: './tab-remittance.component.html',
  styleUrls: ['./tab-remittance.component.scss']
})
export class TabRemittanceComponent {

  @Input() customerId = '';
  protected tabHeaders: Array<any> = [
    { tabName: 'Cash Pickup'},
    { tabName: 'Bank Transfer'}
  ];
  protected activeTabIndex = 0;

  protected switchTab(activeTabIndex: number): void {
    this.activeTabIndex = activeTabIndex;
  }
}
