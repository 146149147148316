import { BaseComponent } from '@lib/components/base/base.component';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Nullable } from '@lib/interfaces';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export abstract class BaseValueFieldComponent extends BaseComponent {
    @ViewChild('fieldEditor') public readonly fieldEditorTemplate: TemplateRef<void>;

    protected controlValueChangeSubscription: Nullable<Subscription> = null;

    protected isInvalid(control: AbstractControl): boolean {
        return (control.touched || control.dirty) && control.invalid;
    }

    protected validationErrors(controlDisplayName: string, validationErrorObject: Nullable<ValidationErrors>): Array<string> {
        const errors: Array<string> = [];

        if (!validationErrorObject) return errors;

        for (const [errorName, errorObject] of Object.entries(validationErrorObject)) {
            if (this.errorIs<{ min: number }>('min', errorName, errorObject)) {
                errors.push(`${controlDisplayName} cannot be less than "${errorObject.min}"`);

                continue;
            }

            if (this.errorIs<{ max: number }>('max', errorName, errorObject)) {
                errors.push(`${controlDisplayName} cannot be greater than "${errorObject.max}"`);

                continue;
            }

            if (this.errorIs<{ requiredLength: number }>('minlength', errorName, errorObject)) {
                errors.push(`${controlDisplayName} cannot be less than "${errorObject.requiredLength}" characters`);

                continue;
            }

            if (this.errorIs<{ requiredLength: number }>('maxlength', errorName, errorObject)) {
                errors.push(`${controlDisplayName} cannot be greater than "${errorObject.requiredLength}" characters`);

                continue;
            }

            if (this.errorIs<{ required: true }>('required', errorName, errorObject)) {
                errors.push(`${controlDisplayName} is required`);

                continue;
            }

            if (this.errorIs<{ email: true }>('email', errorName, errorObject)) {
                errors.push(`${controlDisplayName} must be a valid email`);

                continue;
            }

            if (this.errorIs<{ requiredPattern: string }>('pattern', errorName, errorObject)) {
                errors.push(`${controlDisplayName} is not valid`);

                continue;
            }

            if (typeof errorObject === 'string') errors.push(errorObject);
        }

        return errors;
    }

    protected createMinifiedName(name: string): string {
        const minifiedSegments: Array<string> = [];

        for (const segment of name.split(/[ \-_]/)) {
            if (segment === '') continue;

            minifiedSegments.push(segment.toLowerCase());
        }

        return minifiedSegments.join('-');
    }

    private errorIs<T>(errorNameAssert: string, errorName: string, _: any): _ is T {
        return errorName === errorNameAssert;
    }
}
