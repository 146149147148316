export enum TNotificationsCategory {
    THREE_D_SECURE_OTP = 'THREE_D_SECURE_OTP',
    ITSP_OTP = 'ITSP_OTP',
}

export interface INotifications {
    id: string;
    language: string;
    text: string;
    category: TNotificationsCategory;
    card_product_id: string;
    created: number;
    modified: boolean;
}

export interface INotificationCardProductDropdown {
    id: string;
    description: string;
}

export type IMutateNotifications = Omit<INotifications, 'created' | 'modified'>;
