<div class="value-field">
    <div *ngIf="!editMode">
        <ng-container *ngIf="valueFields.has('display'); else defaultValueTemplate" [ngTemplateOutlet]="getValueFieldType('display')" />
        <ng-template #defaultValueTemplate>
            <p class="text-truncate value-field-text">{{ value | optionalPlaceholder }}</p>
        </ng-template>
    </div>

    <div *ngIf="editMode && valueFields.has('editField')">
        <ng-container [ngTemplateOutlet]="getValueFieldType('editField')"></ng-container>
    </div>
</div>
