import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { PaymentDistributionOrder } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'paymentDistributionOrder',
})
export class PaymentDistributionOrderPipe implements PipeTransform {
    public transform(value: Optional<PaymentDistributionOrder>): string {
        switch (value) {
            case PaymentDistributionOrder.TRANSACTION_TYPE:
                return 'Transaction Type';

            default:
                return '';
        }
    }
}
