import type { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TemplateRef } from '@angular/core';

export interface IEntity {
    id: string;
    description: string;
}

export type IEntities = IEntity[];

export interface IConstDict {
    key: string;
    value: string;
    renderTenants?: any;
}

export interface IListResponse<T> {
    after: string;
    has_more: boolean;
    data: Array<T>;
}

export type Key<T> = keyof T;

export type Nullable<T> = T | null;

export type Optional<T> = T | undefined;

export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : Optional<T>;

export type Delegate<TArgs extends Array<unknown>, TReturn> = (...args: TArgs) => TReturn;

export type PrimitiveControl = string | number | boolean | Date | TemplateRef<void>;

export type IFormGroupSchema<T> = {
    [K in Key<T>]: IControlSchema<T[K]>;
};

export type IFormArraySchema<T> = IControlSchema<T>;

export type IControlSchema<T> = NonNullable<T> extends PrimitiveControl ? FormControl<T> : NonNullable<T> extends Array<infer R> ? FormArray<IFormArraySchema<R>> : NonNullable<T> extends object ? FormGroup<IFormGroupSchema<NonNullable<T>>> : never;
