import { Component, OnInit } from '@angular/core';
import { BaseComponent, HeaderComponent, SideNavComponent } from '@lib/components';
import { SettingsService } from '@lib/services/settings/settings.service';
import { ScrollService } from '@lib/services/scroll.service';
import { Router, NavigationEnd, RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'src/app/material.module';

@Component({
    standalone: true,
    imports: [RouterModule, AngularMaterialModule, SideNavComponent, HeaderComponent],
    selector: 'app-home',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit {
    protected isWelcomeScreen = false;
    protected isCollapseNav = false;

    constructor(private readonly scrollService: ScrollService, private readonly settingService: SettingsService, private readonly router: Router) {
        super();
        this.setWelcomeScreen();
    }

    ngOnInit(): void {
        this.getTenantSettings();
    }

    protected matDrawerContentScroll(event: Event): void {
        const matDrawerContent: HTMLElement = event.target as HTMLElement;
        const targetScrollBottom: number = matDrawerContent.scrollTop + matDrawerContent.clientHeight + 50; // Adjust scroll trigger threshold
        if (targetScrollBottom >= matDrawerContent.scrollHeight) this.scrollService.bottomReached();
    }

    private getTenantSettings(): void {
        this.settingService.getTenantSetting().subscribe({
            next: ({ metadata }): void => {
                this.settingService.settingsDetails.emit(metadata[0]);
            },
        });
    }

    private setWelcomeScreen(): void {
        this.addSubscription(
            this.router.events.subscribe((event): void => {
                if (event instanceof NavigationEnd) {
                    this.isWelcomeScreen = event.url === '/';
                }
            }),
        );
    }

    onCollapseStatusChange(collapseStatus: boolean) {
        this.isCollapseNav = collapseStatus;
    }
}
