<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex flex-row align-items-center">
            <div *ngFor="let inputOption of inputOptions; first as first; last as last" class="d-flex flex-row align-items-center">
                <input
                    [class.disabled]="control.disabled"
                    [formControl]="control"
                    [checked]="inputOption.value"
                    [id]="createInputOptionIdentifier(inputOption)"
                    [name]="createMinifiedName(controlDisplayName)"
                    type="checkbox"
                />
                <label [class.disabled]="control.disabled" [for]="createInputOptionIdentifier(inputOption)" class="mx-2">
                    <ng-container [ngTemplateOutlet]="inputOption.templateRef" />
                </label>
            </div>
        </div>
        <ul *ngIf="isInvalid(control)" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>
</ng-template>
