import { Component, Input } from '@angular/core';
import { BaseValueFieldComponent } from '@lib/modules/value-field/base/base-value-field.component';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Nullable, Optional } from '@lib/interfaces';

export type FormControlInput<T> = FormControl<Optional<Nullable<T>>>;

@Component({ template: '' })
export abstract class SimpleControlFieldComponent<T> extends BaseValueFieldComponent {
    @Input({ required: true }) public controlDisplayName: string;

    private _control: FormControlInput<T>;

    public get control(): FormControlInput<T> {
        return this._control;
    }

    @Input({ required: true })
    public set control(control: FormControlInput<T>) {
        this._control = control;

        if (!this.controlChanges) return;

        this.controlChanges.next(control);
    }

    private _controlChanges: Subject<FormControlInput<T>> = new Subject<FormControlInput<T>>();

    protected get controlChanges(): Subject<FormControlInput<T>> {
        return this._controlChanges;
    }
}
