import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IRole, IRoleList } from '@lib/interfaces/role.interface';
import { IPasswordPolicy } from '@lib/interfaces/policy.interface';
import { IUserList } from '@lib/interfaces';

@Injectable({
    providedIn: 'root',
})
export class AccessManagementService {
    reloadAccountsList: EventEmitter<boolean> = new EventEmitter();
    private _roles: Array<IRole> = [];
    environment_url: string;
    nonInterceptorHttp: HttpClient;

    public set roles(roles: Array<IRole>) {
        this._roles = roles;
    }

    public get roles(): Array<IRole> {
        return this._roles;
    }

    constructor(private http: HttpClient, private httpHandler: HttpBackend) {
        this.environment_url = environment.api_url;
        this.nonInterceptorHttp = new HttpClient(httpHandler);
    }

    fetchViewRoles(payload: string): Observable<IRoleList> {
        return this.http.get<IRoleList>(`${this.environment_url}nfront/v1/roles?${payload}`);
    }

    public getRole(roleId: string): Observable<IRole> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/roles/${roleId}`);
    }

    public addRole(payload: any): Observable<IRole> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/roles`, payload);
    }

    public editRole(roleId: string, payload: any): Observable<IRole> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/roles/${roleId}`, payload);
    }

    public getPermissions(): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/permissions?channel=BACKOFFICE`);
    }

    fetchUsers(payload: string): Observable<IUserList> {
        return this.http.get<IUserList>(`${this.environment_url}nfront/v1/admins?${payload}`);
    }

    fetchUserById(userId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/admins/${userId}`);
    }

    createUser(payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/admins`, payload);
    }

    createAgent(payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/admins/agents`, payload);
    }

    updateUser(userId: string, payload: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/admins/${userId}`, payload);
    }

    getActivePasswordPolicy(isAdminPasswordPolicy = false): Observable<any> {
        const url = isAdminPasswordPolicy ? 'admins/passwordpolicy' : 'signup/passwordpolicies';

        return this.nonInterceptorHttp.get<any>(`${this.environment_url}nfront/v1/${url}:active`, {
            headers: new HttpHeaders({
                'X-Consumer-Username': localStorage.getItem('tenantKey') || '',
            }),
        });
    }

    updatePasswordPolicy(policyPayload: IPasswordPolicy, policyId: string, isAdminPasswordPolicy = false): Observable<any> {
        const url = isAdminPasswordPolicy ? 'admins/passwordpolicy' : 'signup/passwordpolicies';

        return this.http.put<any>(`${this.environment_url}nfront/v1/${url}/${policyId}`, policyPayload);
    }
}
