export interface IAuthInterface {
    username: string;
    password: string;
}

export interface ISignUpInterface {
    username: string;
    phone: string;
    firstName: string;
    lastName: string;
    password: string;
}

export interface ISetPasswordInterface {
    password: string;
    confirmPassword: string;
}

export interface ILoginResponse {
    user_id: string;
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
    flow?: string;
}

interface IAdmin {
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    id: string;
    roles: string[];
    status: string;
    verified_mobile: string;
    verified_email: string;
    created: string;
    modified: string;
    two_fa_required: boolean;
}

interface ICustomToken {
    token: string;
    provider: string;
    scopes: string;
}

export interface IAuthResponse {
    id: string;
    admin_id: string;
    admin: IAdmin;
    flow: string;
    custom_token: ICustomToken;
}
