import { Pipe, PipeTransform } from '@angular/core';
import { Gender, Optional } from '@lib/interfaces';

@Pipe({
    name: 'gender',
    standalone: true,
})
export class GenderPipe implements PipeTransform {
    public transform(value: Optional<Gender>): string {
        switch (value) {
            case Gender.MALE:
                return 'Male';

            case Gender.FEMALE:
                return 'Female';

            default:
                return '';
        }
    }
}
