import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[appTableColumn]',
})
export class TableColumnDirective<T> {
    @Input({ required: true }) public appTableColumn = '';
    @Input({ required: true }) public appTableColumnDataSource: Array<T> = [];
    @Input() public preventAction = false;

    public constructor(public readonly templateRef: TemplateRef<{ $implicit: T }>) {}

    static ngTemplateContextGuard<T>(_: TableColumnDirective<T>, __: unknown): __ is { $implicit: T } {
        return true;
    }
}
