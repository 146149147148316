<div class="container p-2 my-5">
    <div class="text-center">
        <h2 class="mb-4">Terms and Conditions for Agents Providing Services under the Fawri Brand</h2>
        <h3 class="mb-4">(Faysal Islamic Bank Sudan)</h3>
    </div>

    <div class="m-4">
        <div class="my-2">
            <span class="my-2">1. Definition of Terms</span>
            <div>
                Agent: Refers to any individual or entity authorized by Fawri, under the supervision of Faysal Islamic Bank Sudan, to offer financial services to
                customers.
            </div>
            <div>Fawri: Refers to the brand operated by Faysal Islamic Bank Sudan that provides financial services to customers.</div>
            <div>
                Services: Includes the various banking and financial transactions the agent is authorized to conduct on behalf of Fawri, such as deposits, withdrawals,
                payments, and other relevant services.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">2. Appointment of Agent</span>
            <div>
                The Agent is authorized to provide financial services under the brand name Fawri on behalf of Faysal Islamic Bank Sudan. The Agent shall comply with all
                relevant regulations and guidelines set by Faysal Islamic Bank and the Central Bank of Sudan. The appointment as an Agent is non-exclusive, and Fawri
                retains the right to appoint other agents at its discretion.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">3. Scope of Services</span>
            <div>The Agent shall provide the agreed-upon services, which include but are not limited to:</div>
            <ul>
                <li>Facilitating customer deposits and withdrawals.</li>
                <li>Processing payments and transfers on behalf of customers.</li>
                <li>Assisting in account opening processes.</li>
                <li>Providing customer support for Fawri services.</li>
            </ul>
            <div>The Agent agrees to carry out these services in accordance with the operating guidelines and systems provided by Fawri.</div>
        </div>
        <div class="my-2">
            <span class="my-2">4. Agent Obligations</span>
            <div>
                The Agent must ensure that all services are conducted ethically, professionally, and transparently. The Agent must maintain accurate records of all
                transactions and submit them to Fawri or Faysal Islamic Bank as required. The Agent must provide appropriate identification and receipts to customers for
                each transaction. The Agent must comply with the Know Your Customer (KYC) requirements as mandated by Faysal Islamic Bank and Sudanese law. The Agent
                shall not provide services outside the scope authorized by Fawri.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">5. Compliance with Legal and Regulatory Framework</span>
            <div>
                The Agent agrees to comply with all applicable laws, including anti-money laundering (AML), counter-terrorism financing (CTF), and other banking
                regulations in Sudan. The Agent must report any suspicious transactions to Fawri and cooperate with any investigations or audits.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">6. Use of Brand and Trademarks</span>
            <div>
                The Agent is authorized to use the Fawri brand and logo solely for the purpose of delivering services under this agreement. The Agent shall not
                misrepresent its relationship with Fawri or Faysal Islamic Bank. Any misuse of the Fawri brand, logo, or intellectual property will lead to immediate
                termination of the agreement.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">7. Confidentiality</span>
            <div>
                The Agent agrees to maintain confidentiality regarding all customer information, transaction details, and any sensitive information obtained while
                performing duties. The Agent shall not disclose any such information to third parties without the written consent of Fawri or unless required by law.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">8. Fees and Compensation</span>
            <div>
                The Agent shall be entitled to commissions or fees as per the agreed schedule between Fawri and the Agent. All commissions will be paid after the
                successful completion and verification of services rendered by the Agent.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">9. Performance Monitoring</span>
            <div>
                Fawri reserves the right to monitor the Agent's performance, including auditing transactions and inspecting records at any time. The Agent must cooperate
                with any review, investigation, or inquiry initiated by Fawri or Faysal Islamic Bank.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">10. Termination of Agreement</span>
            <div>
                Fawri reserves the right to terminate this agreement at any time with immediate effect if the Agent breaches any terms of this agreement. The Agent may
                terminate this agreement with a [30-day] written notice, provided all pending obligations and accounts are settled.
            </div>
        </div>
        <div class="my-2">
            <span class="my-2">11. Limitation of Liability</span>
            <div>
                The Agent acknowledges that they are acting as an independent contractor and not an employee of Fawri or Faysal Islamic Bank. Fawri and Faysal Islamic
                Bank are not liable for any losses or damages incurred by the Agent in the course of delivering services, unless due to negligence on the part of Fawri.
            </div>
        </div>
        <div class="my-2 mb-4">
            <span class="my-2">12. Governing Law</span>
            <div>
                This agreement shall be governed and interpreted in accordance with the laws of Sudan, including any specific regulations of the Central Bank of Sudan.
            </div>
        </div>
    </div>
</div>
