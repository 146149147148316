import { IBalance } from './account.interface';
import { CashAdvanceLimitType } from '@lib/enums';
import { IEntities } from './common.interface';

export enum CardType {
    PHYSICAL = 'PHYSICAL',
    VIRTUAL = 'VIRTUAL',
}

export enum CardShippingMethod {
    COURIER = 'COURIER',
}

export enum CardStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    TERMINATED = 'TERMINATED',
}

export enum CardStatusReason {
    I0 = 'I0',
    I1 = 'I1',
    I2 = 'I2',
    I3 = 'I3',
    A0 = 'A0',
    A1 = 'A1',
    S0 = 'S0',
    S1 = 'S1',
    S2 = 'S2',
    T0 = 'T0',
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
    T4 = 'T4',
    T5 = 'T5',
}

export const cardStatusReasonInfo: IEntities = [
    { id: 'A0', description: 'First time activation.' },
    { id: 'A1', description: 'Resumed from Suspended status: when card is moved back to ACTIVE status.' },
    { id: 'A3', description: 'Activated by admin.' },
    { id: 'S0', description: 'Temporary suspension: initiated by the user or system user or the system.' },
    { id: 'S1', description: 'PIN retries exhausted: when bad PIN retries are exhausted.' },
    { id: 'S2', description: 'Card validity expired.' },
    { id: 'S3', description: 'Admin rejected due to ID verification failed.' },
    { id: 'S4', description: 'Suspended by admin.' },
    { id: 'S5', description: 'Admin rejected due to ID verification failed.' },
    { id: 'S6', description: 'Suspended by admin.' },
    { id: 'T0', description: 'Card is lost.' },
    { id: 'T1', description: 'Card is stolen.' },
    { id: 'T2', description: 'Card is expired.' },
    { id: 'T3', description: 'Card is damaged.' },
    { id: 'T4', description: 'Card is closed by user.' },
    { id: 'T5', description: 'Card is closed by issuer.' },
    { id: 'T7', description: 'Admin terminated due to limited time exceeded and action taken for ID verification fail.' },
    { id: 'T8', description: 'Terminated by admin.' },
    { id: 'T9', description: 'Admin terminated due to ID verification failed.' },
    { id: 'T10', description: 'Admin terminated due to limited time exceeded and action taken for KYB fail.' },
    { id: 'T11', description: 'Terminated by Admin.' },
    { id: 'T12', description: 'System terminated due to ID verification rejected.' },
    { id: 'T13', description: 'System terminated due to ID verification suspected.' },
    { id: 'T14', description: 'Failed KYC.' },
    { id: 'T15', description: 'Terminated by admin.' },
    { id: 'T16', description: 'Terminated by admin.' },
    { id: 'T17', description: 'Admin terminated due to ID verification failed.' },
    { id: 'T18', description: 'Admin terminated due to KYC failed.' },
];

export enum FulfillmentStatus {
    CREATED = 'CREATED',
    ORDERED = 'ORDERED',
}

export interface ICard {
    id: string;
    user_id: string;
    card_type: CardType;
    card_product_id: string;
    reference_card_id: string;
    bulk_issuance_id: string;
    name_on_card: string;
    shipping_details: IShipping;
    status: CardStatus;
    status_reason: CardStatusReason;
    fulfillment_status: FulfillmentStatus;
    bin: string;
    last_four: string;
    expiry_date: string | number;
    pin_retries: number;
    proxy_number: string;
    created: string;
    modified: string;
    extra_embossing_line: string;
    card_soft_expiry: string;
    card_product_name: string;
    auth_settings: ICardAuth;
    credit_settings: ICreditSettings;
    ledger_balances: IBalance;
    currencies: string[];
    is_account_linked: boolean;
    modified_by:string;
}

export interface ICreditSettings {
    next_due_date: string;
    assignedCreditLimit: number;
    type: string;
}

export interface IShipping {
    method: CardShippingMethod;
    return_address: IShippingAddress;
    shipping_address: IShippingAddress;
}

export interface IShippingAddress {
    first_name: string;
    middle_name: string;
    last_name: string;
    mobile: string;
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
}

export interface ICardAuth {
    allow_atm: boolean;
    allow_pos: boolean;
    allow_chip: boolean;
    allow_magstripe: boolean;
    allow_chip_fallback: boolean;
    allow_without_pin: boolean;
    allow_ecommerce_non_3DS: boolean;
    allow_ecommerce_3DS: boolean;
    allow_ecommerce_without_CVV: boolean;
    allow_account_verification_without_CVV: boolean;
    allow_quasi_cash: boolean;
    allow_scheme_account_funding: boolean;
    allow_scheme_credits: boolean;
    allow_contactless: boolean;
    allowed_mccs_id: string;
    allowed_countries_id: string;
}

export interface ICreateCardRequest {
    user_id: string;
    card_type: CardType;
    card_product_id: string;
    reference_card_id: string;
    bulk_issuance_id: string;
    name_on_card: string;
    shipping_details: IShipping;
    dry_run: boolean;
    card_validity_term: number;
    extra_embossing_line: string;
    credit_settings: ICardCreditSetting;
}

export interface ICardCreditSetting {
    statement_date: number;
    assigned_credit_limit: number;
    cash_advance: ICardCashAdvance;
}

export interface ICardCashAdvance {
    cash_adv_limit_type: CashAdvanceLimitType;
    cash_adv_limit_value: number;
}

export interface ICardAdvancedSearch {
    last_four: string;
    bin: string;
}

export interface ICardStatusChange {
    status: string;
    status_reason_code: string;
    comment: string;
    modified_by:string;
}

export interface ICardAprDetails {
    balance_type: string;
    interest_rate: string;
    balance_subject_to_interest: string;
    interest_charged: string;
}

export interface IStatement {
    statement_date: string;
    statement_start_date: string;
    statement_end_date: string;
    statement_balance: string;
    purchase_balance: string;
    cash_advance_balance: string;
    fees_charged: string;
    minimum_payment_amount: string;
    due_date: string;
    available_credit_limit: string;
    available_cash_advance_limit: string;
    total_payment_amount: string;
    remaining_minimum_payment: string;
    remaining_statement_balance: string;
    apr_details: ICardAprDetails[];
}

export interface IAccountStatement {
    Account_ID: string;
    statement_currency_code: string;
    statements: IStatement[];
}

export interface IStatementPayload {
    account_id: string;
    no_of_statements: string;
    statement_date: string;
}

export interface IAccountStatementForm {
    year: string;
    month: string;
}
