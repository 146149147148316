export interface IOTPConfiguration {
    id: string;
    operation_type: OperationType;
    operation_code: OperationCode;
    is_active: boolean;
    bypass_on_trusted_device: boolean;
    otp_types: string[];
    duration: number;
    length: number;
    max_retries: number;
    format: string;
    is_split: boolean;
    alert_type: string[];
    rate_limit: number;
    lock_duration: number;
}

export interface IOTPConfigurationList {
    data: IOTPConfiguration[];
    after: string;
    has_more: boolean;
}

export enum OperationType {
    TRANSACTION = 'TRANSACTION',
}

export enum OperationCode {
    VERIFY_USER = 'VERIFY_USER',
    LOGIN = 'LOGIN',
    RESET_PASSWORD = 'RESET_PASSWORD',
    SET_PASSWORD = 'SET_PASSWORD',
    REVEAL_CARD = 'REVEAL_CARD',
    CHANGE_PIN = 'CHANGE_PIN',
    SHOW_PIN = 'SHOW_PIN',
    ACTIVATE_CARD = 'ACTIVATE_CARD',
    FUNDS_TRANSFER = 'FUNDS_TRANSFER',
    DEFAULT = 'DEFAULT',
    REACTIVATE_CARD = 'REACTIVATE_CARD',
    BLOCK_CARD = 'BLOCK_CARD',
    MC_CROSS_BORDER = 'MC_CROSS_BORDER',
    BILLERS_PAYMENT = 'BILLERS_PAYMENT',
    CL_TOPUP = 'CL_TOPUP',
    CL_FIRSTTOPUP = 'CL_FIRSTTOPUP',
    CL_WITHDRAWAL = 'CL_WITHDRAWAL',
    USER_ALIAS_CREATE = 'USER_ALIAS_CREATE',
    USER_ALIAS_CHANGE = 'USER_ALIAS_CHANGE',
    CHECKOUT_PAYMENT = 'CHECKOUT_PAYMENT',
    UPDATE_BALANCE_LIMIT = 'UPDATE_BALANCE_LIMIT',
    UPDATE_VELOCITY_LIMIT = 'UPDATE_VELOCITY_LIMIT',
}

export enum OTPConfigurationViewMode {
    View,
    List,
    Create,
    Edit,
}
