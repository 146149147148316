<div class="limit-widget border rounded-3 p-4 mb-3">
    <div class="w-header" [ngClass]="{ 'mb-4': isHideLeftLimit || isHideLeftLimit }">
        <div>
            <div class="label small text-secondary lh-base">{{ limit.description }}</div>
            <div class="value fw-semibold">{{ limit.max_amount | number : '1.2-2' }} {{ limit.currency }}</div>
        </div>
        <mat-slide-toggle [ngModel]="matToggleValue" (change)="toggleMatSlideButton.emit($event)" *ngIf="isToggleButton" class="custom-mat-slide"></mat-slide-toggle>
    </div>
    <div class="w-body progress-bar mb-4" *ngIf="isHideProgressBar">
        <mat-progress-bar mode="determinate" [color]="color" [value]="consumedLimitPercentage"></mat-progress-bar>
    </div>
    <div class="w-footer d-flex justify-content-between" *ngIf="isHideLeftLimit">
        <div class="spent">
            <div class="value fw-semibold">{{ limit.consumed_limit | number : '1.2-2' }} {{ limit.currency }}</div>
            <div class="label small text-secondary">Spent</div>
        </div>
        <div class="left">
            <div class="value fw-semibold">{{ leftLimit | number : '1.2-2' }} {{ limit.currency }}</div>
            <div class="label small text-secondary text-end">Left</div>
        </div>
    </div>
</div>
