import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { Nullable, Optional } from '@lib/interfaces';
import { BaseComponent } from '@lib/components/base';
import { TValueFieldType, ValueFieldDirective } from '@lib/modules/value-field/directives';
import { BaseValueFieldComponent } from '@lib/modules/value-field/base';

@Component({
    selector: 'app-value-field',
    templateUrl: './value-field.component.html',
    styleUrls: ['./value-field.component.scss'],
})
export class ValueFieldComponent extends BaseComponent implements AfterViewInit {
    @Input() public value: Optional<Nullable<string | number>> = '';
    @Input() public editMode = false;

    @ContentChildren(ValueFieldDirective) protected valueFieldContents: Optional<QueryList<ValueFieldDirective>>;

    protected valueFields: Map<TValueFieldType, TemplateRef<void>> = new Map<TValueFieldType, TemplateRef<void>>();

    public constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    public ngAfterViewInit(): void {
        this.initializeValueFields();
    }

    protected getValueFieldType(valueFieldType: TValueFieldType): TemplateRef<void> {
        const templateRef: Optional<TemplateRef<void>> = this.valueFields.get(valueFieldType);

        if (templateRef) return templateRef;

        throw new Error(`Value field type ${valueFieldType} not set!`);
    }

    private initializeValueFields(): void {
        if (!this.valueFieldContents) return;

        for (const valueFieldContent of this.valueFieldContents) {
            if (valueFieldContent.appValueField === 'display') {
                const displayTemplate: Nullable<TemplateRef<void>> = valueFieldContent.displayTemplate;

                if (!displayTemplate) throw new Error('Display template unavailable!');

                this.valueFields.set(valueFieldContent.appValueField, displayTemplate);

                continue;
            }

            const valueFieldTemplate: Nullable<BaseValueFieldComponent> = valueFieldContent.editableField;

            if (!valueFieldTemplate) throw new Error('Value field unavailable!');

            this.valueFields.set(valueFieldContent.appValueField, valueFieldTemplate.fieldEditorTemplate);
        }

        this.changeDetectorRef.detectChanges();
    }
}
