import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { IValueFieldOption, ValueFieldOptionDirective } from '@lib/modules/value-field/directives';
import { BaseValueFieldComponent, SimpleControlFieldComponent } from '@lib/modules/value-field/base';

@Component({
    selector: 'app-value-field-radio',
    templateUrl: './value-field-radio.component.html',
    styleUrls: ['./value-field-radio.component.scss'],
    providers: [{ provide: BaseValueFieldComponent, useExisting: ValueFieldRadioComponent }],
})
export class ValueFieldRadioComponent<T> extends SimpleControlFieldComponent<T> implements AfterContentInit {
    @ContentChildren(ValueFieldOptionDirective<T>) protected valueFieldOptionList: Optional<QueryList<ValueFieldOptionDirective<T>>>;

    protected inputOptions: Array<IValueFieldOption<T>> = [];

    public ngAfterContentInit(): void {
        this.initializeInputOptions();
    }

    protected createInputOptionIdentifier(inputOption: IValueFieldOption<T>): string {
        return this.createMinifiedName(`${this.controlDisplayName}-${<string>inputOption.value}`);
    }

    private initializeInputOptions(): void {
        if (!this.valueFieldOptionList) return;

        for (const { appValueFieldOption, templateRef } of this.valueFieldOptionList) {
            this.inputOptions.push({ value: appValueFieldOption, templateRef });
        }
    }
}
