import { Pipe, PipeTransform } from '@angular/core';
import { IndustryType, Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'industryType',
})
export class IndustryTypePipe implements PipeTransform {
    public transform(value: Optional<IndustryType>): string {
        switch (value) {
            case IndustryType.I01:
                return 'Gambling/Casinos';

            case IndustryType.I02:
                return 'Defence/Military goods or Arms Dealers and Manufacturers';

            case IndustryType.I03:
                return 'Exchange Companies / Bureaus de Change, Money Changers, Hawala dealers / Other Finance Companies (For e.g. Derivatives Trading, Hedge Funds, Unregistered/Pvt Collective Investment Schemes)';

            case IndustryType.I04:
                return 'Real Estate Brokers / Real Estate Agencies / Real Estate Developers (Non * Govt)';

            case IndustryType.I05:
                return 'Charity Organizations, Religious/Social/Non * Profit Organizations, Cooperative/Social/Professional Societies & associations';

            case IndustryType.I06:
                return 'Auction Houses';

            case IndustryType.I07:
                return 'Dealers in Luxury Goods: (Definition of Luxury Goods include: Precious metals & Stones, Jewelry & Antiques, Art, Furs, Yachts , Executive/Private Jets)';

            case IndustryType.I08:
                return 'Trust Service Providers, Lawyers, Accountants, Tax Advisers, Auditors';

            case IndustryType.I09:
                return 'Private Banking Relationship';

            case IndustryType.I11:
                return 'Agriculture, Forestry, Fishing, and Hunting';

            case IndustryType.I12:
                return 'Mining, Quarrying, and Oil and Gas Extraction';

            case IndustryType.I13:
                return 'Utilities (water, gas, electricity)';

            case IndustryType.I14:
                return 'Telecommunications';

            case IndustryType.I15:
                return 'Construction';

            case IndustryType.I16:
                return 'Manufacturing';

            case IndustryType.I17:
                return 'Wholesale Trade';

            case IndustryType.I18:
                return 'Retail Trade and Commerce';

            case IndustryType.I19:
                return 'Transportation and Warehousing';

            case IndustryType.I20:
                return 'Technology';

            case IndustryType.I21:
                return 'Finance and Insurance';

            case IndustryType.I22:
                return 'Real Estate and Rental and Leasing';

            case IndustryType.I23:
                return 'Professional, Scientific, and Technical Services';

            case IndustryType.I24:
                return 'Management of Companies and Enterprises';

            case IndustryType.I25:
                return 'Educational Services';

            case IndustryType.I26:
                return 'Health Care and Social Assistance';

            case IndustryType.I27:
                return 'Arts, Entertainment, and Recreation';

            case IndustryType.I28:
                return 'Hotels and Food Services';

            case IndustryType.I29:
                return 'Public Administration';

            case IndustryType.I30:
                return 'Administrative and Support and Waste Management';

            case IndustryType.I00:
                return 'Other';

            default:
                return '';
        }
    }
}
