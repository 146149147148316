import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-coming-soon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent {
    @Input() subHeading: string | undefined;
    @Input() image: string | undefined;
}
