import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { PaymentTransactionType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'paymentTransactionType',
})
export class PaymentTransactionTypePipe implements PipeTransform {
    public transform(value: Optional<PaymentTransactionType>): string {
        switch (value) {
            case PaymentTransactionType.OCT:
                return 'Oct';

            case PaymentTransactionType.CARD_TOPUP:
                return 'Card Top Up';

            case PaymentTransactionType.WALLET_CREDIT:
                return 'Wallet Credit';

            default:
                return '';
        }
    }
}
