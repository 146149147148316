<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center position-relative">
        <div class="d-flex flex-row align-items-center">
            <div
                #selectBox
                #selectBoxTrigger="matMenuTrigger"
                (keydown.space)="selectBoxTrigger.toggleMenu()"
                (menuClosed)="cleanUpMenuScrollSubscription()"
                (menuOpened)="performPostOptionMenuOpeningTasks()"
                [class.select-box-disabled]="control.disabled"
                [class.select-box-invalid]="isInvalid(control)"
                [matMenuTriggerFor]="selectOptionMenu"
                class="w-100 select-box value-field-text"
                tabindex="0"
            >
                <ng-container *ngIf="selectedOption; else emptyValue" [ngTemplateOutlet]="selectedOption.templateRef"></ng-container>
                <ng-template #emptyValue>
                    <span class="select-box-placeholder">{{ placeholderText }}</span>
                </ng-template>
            </div>
            <mat-icon (click)="selectBoxTrigger.toggleMenu()" *ngIf="control.enabled" class="position-absolute select-icon" fontIcon="expand_more"></mat-icon>
        </div>
        <ul *ngIf="isInvalid(control)" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>

    <mat-menu #selectOptionMenu class="select-option" (closed)="menuClosed()">
        <div class="p-1" (click)="$event.stopPropagation()" *ngIf="isSearchDropdown">
          <input class="select-option-input w-100" type="text" #inputField (input)="handleInputChange($event)" (keydown)="$event.stopPropagation()"/>
        </div>
        <div
            (click)="setSelectedOption(inputOption)"
            (keydown.enter)="setSelectedOption(inputOption)"
            *ngFor="let inputOption of inputOptions"
            [disableRipple]="true"
            mat-menu-item
            tabindex="-1"
        >
            <ng-container [ngTemplateOutlet]="inputOption.templateRef"></ng-container>
        </div>
        <div *ngIf="optionsLoading" [disableRipple]="true" [disabled]="true" class="d-flex flex-row justify-content-center" mat-menu-item>
            <div class="option-loader rounded-circle"></div>
        </div>
    </mat-menu>
</ng-template>
