<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center value-field-switch">
        <label class="switch">
            <input [formControl]="control" [id]="createMinifiedName(controlDisplayName)" [name]="createMinifiedName(controlDisplayName)" type="checkbox" />
            <span [class.disabled]="control.disabled" [ngStyle]="{ 'background-color': control.value ? switchOnColor : switchOffColor }" class="slider round"></span>
        </label>
        <ul *ngIf="isInvalid(control)" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>
</ng-template>
