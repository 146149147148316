import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    public environment_url: string;
    constructor(private http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    fetchTransactionsCount(duration: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/transactions:count?duration=${duration}`);
    }
}
