import { Pipe, PipeTransform } from '@angular/core';
import { Optional, TransactionScope } from '@lib/interfaces';

@Pipe({
    name: 'transactionScope',
    standalone: true,
})
export class TransactionScopePipe implements PipeTransform {
    public transform(value: Optional<Array<TransactionScope>>): string {
        if (!value) return '';

        return value.length === 1 ? this.prepareTransactionScopeString(value[0]) : 'Combined Limit';
    }

    private prepareTransactionScopeString(transactionScope: TransactionScope): string {
        switch (transactionScope) {
            case TransactionScope.WITHDRAWALS:
                return 'Withdrawals';

            case TransactionScope.PURCHASES:
                return 'Purchases';

            case TransactionScope.OUTGOING_TRANSFERS:
                return 'Outgoing Transfers';

            case TransactionScope.UNLOADS:
                return 'Unloads';

            case TransactionScope.SCHEME_ACCOUNT_FUNDING:
                return 'Scheme Account Funding';

            case TransactionScope.QUASI_CASH:
                return 'Quasi Cash';

            case TransactionScope.PURCHASES_CONTACTLESS_NO_PIN:
                return 'Purchases Contactless No Pin';

            case TransactionScope.LOADS:
                return 'Loads';

            case TransactionScope.INCOMING_TRANSFERS:
                return 'Incoming Transfers';

            case TransactionScope.SCHEME_CREDITS:
                return 'Scheme Credits';
        }
    }
}
