import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { CardProductSubType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'cardProductSubType',
})
export class CardProductSubTypePipe implements PipeTransform {
    public transform(value: Optional<CardProductSubType>): string {
        switch (value) {
            case CardProductSubType.SECURE:
                return 'Secure';

            case CardProductSubType.REVOLVING:
                return 'Revolving';

            default:
                return '';
        }
    }
}
