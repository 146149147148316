import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { PinType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'pinType',
})
export class PinTypePipe implements PipeTransform {
    public transform(value: Optional<PinType>): string {
        switch (value) {
            case PinType.ONLINE_PIN_ONLY:
                return 'Online Pin Only';

            case PinType.BOTH_ONLINE_OFFLINE:
                return 'Both Online & Offline';

            default:
                return '';
        }
    }
}
