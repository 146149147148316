import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IListResponse, Nullable } from '@lib/interfaces';
import { TColumn, TColumnSort } from '@lib/modules/table/components';
import { TableModule } from '@lib/modules';
import { CustomersBeneficiaryService } from '@lib/services/customers/customers-beneficiary.service';
import { BaseComponent } from '@lib/components';

@Component({
  selector: 'app-tab-cash-pickup',
  standalone: true,
  imports: [CommonModule,TableModule],
  templateUrl: './tab-cash-pickup.component.html',
  styleUrls: ['./tab-cash-pickup.component.scss']
})
export class TabCashPickupComponent extends BaseComponent implements OnInit {

  @Input() customerId = '';
  public cashPickupList: Array<any> = [];
  public cashPickupListLoading = false;
  public hasMoreRecords = false;
  protected columns: Array<TColumn> = [
    'name',
    'email',
    'phone',
    'nickname',
    'bank',
    'form_of_payment',
    'country',
    'category',
    'category_display_name',
    'subcategory',
    'subcategory_display_name',
    'instrument_currency',
    'instrument_type',
    'instrument_value',
    'relationship',
    'source_of_funds',
    'is_deleted',
    'status',
    'created'
  ];
  protected defaultColumns: Array<string> = ['name', 'email', 'phone', 'status', 'created'];
  protected sortableColumns: Array<string> = ['created'];

  constructor(private customerBeneficiaryService:CustomersBeneficiaryService){
    super();
  }
  private fetchCashPickups(limit = 10): void {
    this.addSubscription(
        this.customerBeneficiaryService.getBeneficiaryDetails(this.customerId,'Cash_Pickup').subscribe({
            next: (aliasListResponse: IListResponse<any>): void => {
                this.hasMoreRecords = aliasListResponse.has_more;
                this.cashPickupList = [...aliasListResponse.data];
                this.cashPickupListLoading = false;
            },
            error: (): void => {
                this.cashPickupListLoading = false;
            },
        }),
    );
  }

  ngOnInit(): void {
    this.cashPickupListLoading = true;
    this.fetchCashPickups(10);
  }
}
