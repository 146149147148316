<!-- <h1 class="p-5 text-center color-primary fw-semibold">Coming Soon</h1> -->

<div class="app-coming-soon d-flex">
    <div class="content-container m-auto">
        <h1 class="heading">Coming Soon</h1>
        <p class="sub-heading" *ngIf="subHeading">{{ subHeading }}</p>

        <div class="image-wrapper" *ngIf="image">
            <img [src]="'../../../../assets/' + image" alt="" />
        </div>
    </div>
</div>
