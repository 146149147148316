import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseValueFieldComponent, SimpleControlFieldComponent } from '@lib/modules/value-field/base';

@Component({
    selector: 'app-value-field-stepper',
    templateUrl: './value-field-stepper.component.html',
    styleUrls: ['./value-field-stepper.component.scss'],
    providers: [{ provide: BaseValueFieldComponent, useExisting: ValueFieldStepperComponent }],
})
export class ValueFieldStepperComponent extends SimpleControlFieldComponent<number> implements OnInit {
    @Input() public minValue = -Infinity;
    @Input() public maxValue = Infinity;

    protected get minButtonDisabled(): boolean {
        return this.control.disabled || this.control.value === this.minValue;
    }

    protected get maxButtonDisabled(): boolean {
        return this.control.disabled || this.control.value === this.maxValue;
    }

    public ngOnInit(): void {
        this.initializeValue();
    }

    protected incrementCounter(): void {
        const currentValue: number = this.currentOrDefaultValue();

        if (currentValue === this.maxValue) return;

        this.control.setValue(currentValue + 1);
        this.control.markAsDirty();
    }

    protected decrementCounter(): void {
        const currentValue: number = this.currentOrDefaultValue();

        if (currentValue === this.minValue) return;

        this.control.setValue(currentValue - 1);
        this.control.markAsDirty();
    }

    private currentOrDefaultValue(): number {
        return this.control.value ?? 0;
    }

    private initializeValue(): void {
        this.control.setValue(this.currentOrDefaultValue());

        this.control.addValidators(Validators.min(this.minValue));

        this.control.addValidators(Validators.max(this.maxValue));

        this.control.updateValueAndValidity();
    }
}
