import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { StatementCycle } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'statementCycle',
})
export class StatementCyclePipe implements PipeTransform {
    public transform(value: Optional<StatementCycle>): string {
        switch (value) {
            case StatementCycle.MONTHLY:
                return 'Monthly';

            case StatementCycle.WEEKLY:
                return 'Weekly';

            default:
                return '';
        }
    }
}
