import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseUnderscore',
    standalone: true,
})
export class ParseUnderscoreStringPipe implements PipeTransform {
    public transform(underscoreValue: string): string {
        const titleCasePipe: TitleCasePipe = new TitleCasePipe();
        const withoutUnderscore: string = underscoreValue.replaceAll('_', ' ');

        return titleCasePipe.transform(withoutUnderscore);
    }
}
