import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { FxRateTransactionType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'fxRateTransactionType',
})
export class FxRateTransactionTypePipe implements PipeTransform {
    public transform(value: Optional<FxRateTransactionType>): string {
        switch (value) {
            case FxRateTransactionType.SWEEP:
                return 'Sweep';

            case FxRateTransactionType.TRANSFERS:
                return 'Transfers';

            default:
                return '';
        }
    }
}
