import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appDisableButtonIfUnauthorized]',
})
export class AccessControlDisableButtonDirective implements OnInit {
    @HostBinding('disabled') disabled!: boolean;
    @Input() appDisableButtonIfUnauthorized!: string;
    @Input() setDisabledProperty!: boolean;

    ngOnInit(): void {
        this.canModify(this.appDisableButtonIfUnauthorized);
    }

    private canModify(permissions: string) {
        if (!this.setDisabledProperty) {
            const allPermissions = localStorage.getItem('permissions')?.split(',');
            if (allPermissions && allPermissions.some((permission) => permission === permissions)) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        } else {
            this.disabled = true;
        }
    }
}
