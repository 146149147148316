<div class="dropdown" (mouseenter)="state = 0" (mouseleave)="state = 1">
    <label for="dropdown-btn" *ngIf="label">{{ label }}</label>
    <button id="dropdown-btn" type="button" class="dropdownbtn" [disabled]="isDisabled" (click)="showMenu = !showMenu" [style.width]="width">
        <div class="dropdownbtn-content">
            <div id="displayText" [ngClass]="{ 'select-placeholder': !selectedItem?.description }">
                {{ selectedItem?.description || 'Select' }}
            </div>
            <div class="arrow-icon-holder">
                <mat-icon class="arrow-icon" svgIcon="arrow-up" [svgIcon]="showMenu ? 'arrow-up' : 'arrow-down'"></mat-icon>
            </div>
        </div>
    </button>
    <div id="dropdown" class="dropdown-content" [ngClass]="{ 'position-sticky': sticky }" [class.show]="showMenu">
        <a *ngFor="let d of data" (click)="valueChange(d)">{{ d.description }}</a>
    </div>
</div>
