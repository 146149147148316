import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabAliasComponent } from './components/tab-alias/tab-alias.component';
import { TabMobileNumberComponent } from './components/tab-mobile-number/tab-mobile-number.component';

@Component({
  selector: 'app-tab-p2p',
  standalone: true,
  imports: [CommonModule,TabAliasComponent,TabMobileNumberComponent],
  templateUrl: './tab-p2p.component.html',
  styleUrls: ['./tab-p2p.component.scss']
})
export class TabP2pComponent {

  @Input() customerId = '';
  protected tabHeaders: Array<any> = [
    { tabName: 'Alias'},
    { tabName: 'Mobile Number'}
  ];
  protected activeTabIndex = 0;

  protected switchTab(activeTabIndex: number): void {
    this.activeTabIndex = activeTabIndex;
  }

}
