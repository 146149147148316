import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
    DeepPartial,
    IAprDetails,
    ICardAuth,
    ICardProduct,
    ICardProductsQueryParams,
    ICardSkin,
    ICharge,
    IChargeQueryParams,
    IFee,
    IFxRateGroupListResponse,
    IGetCardProductMetadata,
    ILimit,
    ILimitList,
    ILimitQueryParams,
    ILinkUnlinkChargeRequest,
    IListResponse,
    IMutateCharge,
    IMutateLimit,
    INotifications,
    LimitType,
    LinkOrUnlink,
} from '@lib/interfaces';
import { IWebHooks } from '@lib/interfaces/web-hook.interface';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProductManagementService {
    public environment_url: string;
    public httpClient: HttpClient;

    public constructor(private readonly http: HttpClient, private httpHandler: HttpBackend) {
        this.environment_url = environment.api_url;
        this.httpClient = new HttpClient(httpHandler);
    }

    /**
     * CARD PRODUCT API METHODS
     */
    public fetchCardProducts(cardProductsQueryParams: DeepPartial<ICardProductsQueryParams>): Observable<IListResponse<ICardProduct>> {
        const url: URL = new URL(`${this.environment_url}nfront/v1/proxy/cardproducts`);

        const queryParams: URLSearchParams = new URLSearchParams(
            Object.entries(cardProductsQueryParams).map(([key, value]: [string, string | number]): [string, string] => {
                return [key, value.toString()];
            }),
        );

        url.search = queryParams.toString();

        return this.http.get<IListResponse<ICardProduct>>(url.toString());
    }

    public updateCardProductDetails(id: string, payload: DeepPartial<ICardProduct>): Observable<ICardProduct> {
        return this.http.put<ICardProduct>(`${this.environment_url}nfront/v1/proxy/cardproducts/${id}`, payload);
    }

    public updateAuthControlDetails(cardId: string, payload: DeepPartial<ICardAuth>): Observable<ICardAuth> {
        return this.http.put<ICardAuth>(`${this.environment_url}nfront/v1/proxy/cards/${cardId}/authsettings`, payload);
    }

    /**
     *  CARD PRODUCT LIMITS API METHODS
     */
    public fetchCardProductLimitsDetailsById(id: string, limitType: LimitType): Observable<ILimit> {
        return this.http.get<ILimit>(`${this.environment_url}nfront/v1/proxy/${limitType}/${id}`);
    }

    public updateCardProductLimitsDetailsById(id: string, limitType: LimitType, payload: any): Observable<ILimit> {
        return this.http.put<ILimit>(`${this.environment_url}nfront/v1/proxy/${limitType}/${id}`, payload);
    }

    public fetchProductLimits(cardProductId: string, limitType: LimitType): Observable<Array<string>> {
        return this.http.get<Array<string>>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/${limitType}`);
    }

    public fetchLimits(limitType: LimitType): Observable<Array<ILimit>> {
        return this.http.get<IListResponse<ILimit>>(`${this.environment_url}nfront/v1/proxy/${limitType}?limit=100`).pipe(
            map(({ data }: IListResponse<ILimit>) => {
                return data;
            }),
        );
    }

    public fetchLimitsList(limitType: LimitType, after: string): Observable<ILimitList> {
        return this.http.get<ILimitList>(`${this.environment_url}nfront/v1/proxy/${limitType}?limit=100&after=${after}`).pipe(
            map((limitList: ILimitList) => {
                return limitList;
            }),
        );
    }

    public unlinkLimitFromCardProduct(cardProductId: string, limitIds: Array<string>, limitType: LimitType): Observable<Array<string>> {
        return this.http.post<Array<string>>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/${limitType}:unlink`, { ids: limitIds }).pipe(
            map((remainingLimits: Array<string>) => {
                return remainingLimits;
            }),
        );
    }

    public linkLimitToCardProduct(cardProductId: string, limitIds: Array<string>, limitType: LimitType): Observable<Array<string>> {
        return this.http.post<Array<string>>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/${limitType}:link`, { ids: limitIds }).pipe(
            map((linkedLimits: Array<string>) => {
                return linkedLimits;
            }),
        );
    }

    public getConditionsAdv(): Observable<any> {
        return this.http.get<ILimit>(`${this.environment_url}nfront/v1/proxy/velocitylimits/conditiontypes`);
    }

    /**
     * PRODUCT SETTING API METHODS
     */
    public fetchProductSettingLimits(limitQueryParams: DeepPartial<ILimitQueryParams>, limitType: LimitType): Observable<IListResponse<ILimit>> {
        const url: URL = new URL(`${this.environment_url}nfront/v1/proxy/${limitType}`);
        const queryParams: URLSearchParams = new URLSearchParams(
            Object.entries(limitQueryParams).map(([key, value]: [string, string | number]): [string, string] => {
                return [key, value.toString()];
            }),
        );

        url.search = queryParams.toString();

        return this.http.get<IListResponse<ILimit>>(url.toString());
    }

    public createProductSettingLimit(limitPayload: DeepPartial<IMutateLimit>, limitType: LimitType): Observable<ILimit> {
        return this.http.post<ILimit>(`${this.environment_url}nfront/v1/proxy/${limitType}`, limitPayload);
    }

    public updateProductSettingLimit(limitId: string, limitPayload: DeepPartial<IMutateLimit>, limitType: LimitType): Observable<ILimit> {
        return this.http.put<ILimit>(`${this.environment_url}nfront/v1/proxy/${limitType}/${limitId}`, limitPayload);
    }

    public fetchProductSettingCharges(chargeQueryParams: DeepPartial<IChargeQueryParams>): Observable<IListResponse<ICharge>> {
        const url: URL = new URL(`${this.environment_url}nfront/v1/proxy/charges`);

        const queryParams: URLSearchParams = new URLSearchParams(
            Object.entries(chargeQueryParams).map(([key, value]: [string, string | number]): [string, string] => {
                return [key, value.toString()];
            }),
        );

        url.search = queryParams.toString();

        return this.http.get<IListResponse<ICharge>>(url.toString());
    }

    public createProductSettingCharge(chargePayload: DeepPartial<IMutateCharge>): Observable<ICharge> {
        return this.http.post<ICharge>(`${this.environment_url}nfront/v1/proxy/charges`, chargePayload);
    }

    public updateProductSettingCharge(chargeId: string, chargePayload: DeepPartial<IMutateCharge>): Observable<ICharge> {
        return this.http.put<ICharge>(`${this.environment_url}nfront/v1/proxy/charges/${chargeId}`, chargePayload);
    }

    public fetchNotifications(): Observable<Array<INotifications>> {
        return this.http.get<Array<INotifications>>(`${this.environment_url}nfront/v1/proxy/notificationtemplates`);
    }

    public createProductSettingNotifications(payload: DeepPartial<INotifications>): Observable<INotifications> {
        return this.http.post<INotifications>(`${this.environment_url}nfront/v1/proxy/notificationtemplates`, payload);
    }

    public updateProductSettingNotifications(notificationId: string, notificationPayload: DeepPartial<INotifications>): Observable<INotifications> {
        return this.http.put<INotifications>(`${this.environment_url}nfront/v1/proxy/notificationtemplates/${notificationId}`, notificationPayload);
    }

    /**
     * CARD PRODUCT CHARGE API METHODS
     */
    public fetchAllCardProductCharges(after = ''): Observable<IListResponse<ICharge>> {
        return this.http.get<IListResponse<ICharge>>(`${this.environment_url}nfront/v1/proxy/charges?limit=100&after=${after}`);
    }

    public fetchCardProductCharges(cardProductId: string): Observable<Array<string>> {
        return this.http.get<Array<string>>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/charges`);
    }

    public linkOrUnlinkChargeToCardProduct(cardProductId: string, linkOrUnlink: LinkOrUnlink, linkChargeRequest: ILinkUnlinkChargeRequest): Observable<Array<string>> {
        return this.http.post<Array<string>>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/charges:${linkOrUnlink}`, linkChargeRequest);
    }

    /**
     * CARD PRODUCT SKIN DESIGN METHODS
     */
    public fetchCardDesignsMetaDataById(cardProductId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/card_designs/metadata`);
    }

    public uploadCardSkin(productId: string, payload: any, designId?: string): Observable<any> {
        if (designId) {
            return this.http.put<any>(`${this.environment_url}nfront/v1/proxy/cardproducts/${productId}/card_designs/${designId}`, payload);
        } else {
            return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/cardproducts/${productId}/card_designs`, payload);
        }
    }

    public getCardSkin(productId: string, designId: string): Observable<ICardSkin> {
        return this.http.get<ICardSkin>(`${this.environment_url}nfront/v1/proxy/cardproducts/${productId}/card_designs/${designId}`);
    }

    public getCardSkinMetadata(productId: string): Observable<IGetCardProductMetadata> {
        return this.http.get<IGetCardProductMetadata>(`${this.environment_url}nfront/v1/proxy/cardproducts/${productId}/card_designs/metadata`);
    }

    public createProduct(product: DeepPartial<ICardProduct>): Observable<ICardProduct> {
        return this.http.post<ICardProduct>(`${this.environment_url}nfront/v1/proxy/cardproducts`, product);
    }

    public getFXRateGroups(): Observable<IFxRateGroupListResponse> {
        return this.httpClient.get<IFxRateGroupListResponse>(`${this.environment_url}nfront/v1/proxy/fxrates/groups`);
    }

    // Web Hooks
    public fetchWebHooks(): Observable<Array<IWebHooks>> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/webhooks`);
    }

    public createWebHook(payload: DeepPartial<IWebHooks>): Observable<IWebHooks> {
        return this.http.post<IWebHooks>(`${this.environment_url}nfront/v1/proxy/webhooks`, payload);
    }

    public updateWebHook(webHookId: string, WebHookPayload: DeepPartial<IWebHooks>): Observable<IWebHooks> {
        return this.http.put<IWebHooks>(`${this.environment_url}nfront/v1/proxy/webhooks/${webHookId}`, WebHookPayload);
    }

    public pingWebHook(webHookId: string, payload: string): Observable<IWebHooks> {
        return this.http.post<IWebHooks>(`${this.environment_url}nfront/v1/proxy/webhooks/${webHookId}:ping`, payload);
    }

    public createAprDetails(cardproductId: string, payload: IAprDetails[]): Observable<IAprDetails> {
        return this.http.post<IAprDetails>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardproductId}/:apr_profile`, payload);
    }

    public linkFees(cardProductId: string, payload: any) {
        return this.http.post(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/fees:link`, payload);
    }

    public fetchProductFees(cardProductId: string): Observable<Array<string>> {
        return this.http.get<Array<string>>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/fees`);
    }

    public fetchFees(after: string): Observable<IListResponse<IFee>> {
        return this.http.get<IListResponse<IFee>>(`${this.environment_url}nfront/v1/proxy/fees?limit=100&after=${after}`).pipe(
            map((feeList: IListResponse<IFee>) => {
                return feeList;
            }),
        );
    }

    public fetchFeeById(feeId: string) {
        return this.http.get<IFee>(`${this.environment_url}nfront/v1/proxy/fees/${feeId}`);
    }
    public fetchChargeById(chargeId: any) {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/charges/${chargeId}`);
    }
}
