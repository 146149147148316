import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { MessageType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'messageType',
})
export class MessageTypePipe implements PipeTransform {
    public transform(value: Optional<MessageType>): string {
        switch (value) {
            case MessageType.AUTHORIZATION:
                return 'Authorization';

            case MessageType.AUTHORIZATION_ADVICE:
                return 'Authorization Advice';

            case MessageType.REVERSAL:
                return 'Reversal';

            case MessageType.FINANCIAL:
                return 'Financial';

            case MessageType.FINANCIAL_ADVICE:
                return 'Financial Advice';

            case MessageType.REVERSAL_ADVICE:
                return 'Reversal Advice';

            case MessageType.CLEARING:
                return 'Clearing';

            case MessageType.CLEARING_REVERSAL:
                return 'Clearing Reversal';

            case MessageType.API:
                return 'API';

            default:
                return '';
        }
    }
}
