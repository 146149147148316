<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center">
        <div class="d-flex flex-row align-items-center rounded-pill px-1 value-field-stepper">
            <button
                (click)="decrementCounter()"
                [class.action-button-disabled]="minButtonDisabled"
                [disabled]="minButtonDisabled"
                class="me-2 action-button"
                mat-mini-fab
            >
                <mat-icon svgIcon="remove"></mat-icon>
            </button>
            <span class="mx-2 fw-semibold stepper-value">{{ control.value }}</span>
            <button
                (click)="incrementCounter()"
                [class.action-button-disabled]="maxButtonDisabled"
                [disabled]="maxButtonDisabled"
                class="ms-2 action-button"
                mat-mini-fab
            >
                <mat-icon svgIcon="add"></mat-icon>
            </button>
        </div>
        <ul *ngIf="isInvalid(control)" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>
</ng-template>
