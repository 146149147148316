import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerStatus, CustomerStatusLabels } from '@lib/enums/customer.enum';
import { AngularMaterialModule } from '../../../material.module';
import { Utils } from '@lib/utils/utils';
import { Permission } from '@lib/enums/permissions.enum';

interface StatusEnum {
    [key: string]: CustomerStatus;
}

interface StatusLabelsEnum {
    [key: string]: CustomerStatusLabels;
}

@Component({
    selector: 'app-status',
    standalone: true,
    imports: [CommonModule, AngularMaterialModule],
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
    constructor(private utils: Utils) {}

    @Input() status?: string = '';
    @Input() svgIcon?: string = '';
    @Input() isDropdown?: boolean = false;
    @Input() isCenterText?: boolean = false;
    @Input() placeholder = '';
    statusEnum: StatusEnum = CustomerStatus;
    statusEnumLabels: StatusLabelsEnum = CustomerStatusLabels;
    hasPermission = this.utils.getPermissions(Permission.CustomerChangeStatus);
}
