<div class="billers-table">
    <app-table
        [dataColumns]="columns"
        [dataDefaultColumns]="defaultColumns"
        [dataHasMoreRecords]="hasMoreRecords"
        [dataLoading]="billerListLoading"
        [dataSource]="billerList"
        class="card-table"
        dataAbsenceText="No Billers"
        dataName="Billers"
        svgIcon="card-table"
    >
        <ng-template [appTableColumnDataSource]="billerList" appTableColumn="created" let-card>
            <p>{{ card.created | date }}</p>
        </ng-template>
    </app-table>
</div>
