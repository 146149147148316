import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ICardAuth } from '@lib/interfaces';

@Injectable({
    providedIn: 'root',
})
export class CustomersCardDetailsService {
    public readonly environment_url: string;

    public constructor(private readonly http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    public fetchAuthSettings(id: string): Observable<ICardAuth> {
        return this.http.get<ICardAuth>(`${this.environment_url}nfront/v1/proxy/cards/${id}/authsettings`);
    }

    public updateAuthSettings(id: string, authDetails: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/proxy/cards/${id}/authsettings`, {
            ...authDetails,
        });
    }
}
