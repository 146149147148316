import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { CashAdvanceLimitType } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'cashAdvanceLimitType',
})
export class CashAdvanceLimitTypePipe implements PipeTransform {
    public transform(value: Optional<CashAdvanceLimitType>): string {
        switch (value) {
            case CashAdvanceLimitType.FIXED:
                return 'Fixed';

            case CashAdvanceLimitType.PERCENTAGE:
                return 'Percentage';

            default:
                return '';
        }
    }
}
