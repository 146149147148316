<div class="mobile-table">
    <app-table
        [dataColumns]="columns"
        [dataDefaultColumns]="defaultColumns"
        [dataHasMoreRecords]="hasMoreRecords"
        [dataLoading]="mobileNumberListLoading"
        [dataSource]="mobileNumberList"
        class="card-table"
        dataAbsenceText="No Mobile Numbers"
        dataName="Mobile Numbers"
        svgIcon="card-table"
    >
        <ng-template [appTableColumnDataSource]="mobileNumberList" appTableColumn="created" let-card>
            <p>{{ card.created | date }}</p>
        </ng-template>
    </app-table>
</div>
