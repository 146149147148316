<div class="app-table">
    <mat-card class="p-3 rounded-3 row-gap-3">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center app-table-details">
                <div
                    *ngIf="svgIcon || fontIcon || tableIcon"
                    [ngClass]="{ 'svg-icon': !!svgIcon, 'font-icon': !!fontIcon }"
                    class="app-table-details-icon d-flex align-items-center"
                >
                    <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon" />
                    <mat-icon *ngIf="fontIcon" [fontIcon]="fontIcon" />
                    <mat-icon *ngIf="tableIcon">
                        <ng-container [ngTemplateOutlet]="tableIcon.templateRef"></ng-container>
                    </mat-icon>
                </div>
                <p class="mx-2 w-100 overflow-hidden app-table-header">{{ dataName }}</p>
            </div>
            <div class="d-flex overflow-y-hidden overflow-x-auto column-gap-1 app-table-buttons">
                <ng-container *ngFor="let tableFilterTemplate of tableFilterTemplates" [ngTemplateOutlet]="tableFilterTemplate"></ng-container>
                <ng-container *ngFor="let tableButtonTemplate of tableButtonTemplates" [ngTemplateOutlet]="tableButtonTemplate"></ng-container>
                <button
                    *ngIf="dataSortableColumns.length > 0"
                    [matTooltip]="columnSortText"
                    [ngClass]="{ 'app-table-buttons-highlight': currentColumnSort }"
                    class="d-flex justify-content-around align-items-center rounded-pill fw-semibold app-table-buttons-filter"
                >
                    <mat-icon class="app-table-buttons-icon" svgIcon="app-sort-icon" />
                    <span [matMenuTriggerFor]="sortOptionsMenu" class="app-table-buttons-text">{{ columnSortText }}</span>
                    <mat-icon (click)="setColumnSort()" *ngIf="currentColumnSort" class="app-table-buttons-icon-close" svgIcon="close"></mat-icon>
                </button>
                <button
                    *ngIf="dataDefaultColumns.length > 0"
                    [matMenuTriggerFor]="columnOptionsMenu"
                    class="p-0 d-flex flex-row justify-content-center align-items-center rounded-circle app-table-buttons-filter app-table-buttons-more"
                >
                    <mat-icon>more_horiz</mat-icon>
                </button>
            </div>
        </div>
        <mat-card appearance="outlined" class="p-3 rounded-3">
            <div #matTable class="w-100 overflow-x-auto">
                <table
                    (cdkDropListDropped)="reArrangeDragList(currentOnScreenColumns, $event)"
                    (mousedown)="mouseGrabbed = true"
                    (mouseup)="mouseGrabbed = false"
                    [class.app-table-grabbing]="mouseGrabbed"
                    [dataSource]="dataSource"
                    cdkDropList
                    cdkDropListOrientation="horizontal"
                    mat-table
                >
                    <ng-container *ngFor="let column of expandedColumns" [matColumnDef]="column.key">
                        <th *matHeaderCellDef cdkDrag mat-header-cell>
                            <span class="text-nowrap overflow-hidden" *ngIf="!isObjectTypeColumn(column.key)">{{ column.displayName }}</span>
                            <input
                                type="checkbox"
                                [checked]="allRecordsSelectedChanged"
                                [disabled]="allRecordsProcessed"
                                *ngIf="isObjectTypeColumn(column.key)"
                                (change)="onCheckboxChange($event)"
                            />
                        </th>
                        <td (click)="triggerRowAction(column.key, row)" *matCellDef="let row; dataSource: dataSource" class="text-nowrap overflow-hidden" mat-cell>
                            <ng-container
                                *ngIf="hasColumnTemplate(column.key); else defaultTemplate"
                                [ngTemplateOutletContext]="{ $implicit: row }"
                                [ngTemplateOutlet]="getColumnTemplate(column.key).templateRef"
                            ></ng-container>
                            <ng-template #defaultTemplate>
                                <span>{{ getColumnValue(row, column.key) | optionalPlaceholder }}</span>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="dataShowActionIcon" [matColumnDef]="actionColumnKey">
                        <th *matHeaderCellDef class="text-nowrap overflow-hidden" mat-header-cell></th>
                        <td (click)="triggerRowAction(actionColumnKey, row)" *matCellDef="let row; dataSource: dataSource" class="text-nowrap overflow-hidden" mat-cell>
                            <ng-container
                                *ngIf="hasColumnTemplate(actionColumnKey); else defaultActionTemplate"
                                [ngTemplateOutletContext]="{ $implicit: row }"
                                [ngTemplateOutlet]="getColumnTemplate(actionColumnKey).templateRef"
                            ></ng-container>

                            <ng-template #defaultActionTemplate>
                                <mat-icon class="app-table-action-icon" fontIcon="arrow_forward_ios" />
                            </ng-template>
                        </td>
                    </ng-container>

                    <tr *matNoDataRow>
                        <td *ngIf="!dataLoading" [attr.colspan]="currentOnScreenColumns.length">{{ dataAbsenceText }}</td>
                    </tr>
                    <tr *matHeaderRowDef="currentOnScreenColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: currentOnScreenColumns" mat-row></tr>
                </table>

                <app-spinner [isLoading]="dataLoading" minHeight="10rem" />

                <app-table-lazyload (bottomScroll)="dataLoadMore.emit()" [hasMoreRecords]="!dataLoading && dataHasMoreRecords" *ngIf="dataHasMoreRecords" />
            </div>
        </mat-card>
    </mat-card>

    <div #downArrow class="down-arrow" (click)="scrollToBottom()" [matTooltip]="dataHasMoreRecords ? 'Load more data' : ''" *ngIf="dataHasMoreRecords">
        <mat-icon>arrow_downward</mat-icon>
    </div>
    <mat-menu #sortOptionsMenu class="table-column-options">
        <div [disableRipple]="true" [disabled]="true" mat-menu-item>
            <span class="options-title">Sort by</span>
        </div>
        <div (click)="$event.stopPropagation()">
            <div (click)="setColumnSort()" [disableRipple]="true" mat-menu-item>
                <span class="w-100 d-flex flex-row align-items-center">
                    <input [checked]="!currentColumnSort" type="radio" />
                    <span class="ms-2">Default</span>
                </span>
            </div>
            <div
                (click)="setColumnSort(sortableColumn.key, sortableColumn.displayName)"
                *ngFor="let sortableColumn of expandedSortableColumns"
                [disableRipple]="true"
                mat-menu-item
            >
                <span class="w-100 d-flex flex-row align-items-center">
                    <input [checked]="currentColumnSort && currentColumnSort.key === sortableColumn.key" type="radio" />
                    <span class="ms-2">{{ sortableColumn.displayName }}</span>
                </span>
            </div>
        </div>
        <hr *ngIf="!!currentColumnSort" />
        <div (click)="$event.preventDefault()" *ngIf="!!currentColumnSort">
            <div (click)="setColumnSort(columnSortDirection.ASC)" [disableRipple]="true" class="w-100 d-flex align-items-center justify-content-start" mat-menu-item>
                <mat-icon fontIcon="arrow_upward" />
                <span>Oldest first</span>
            </div>
            <div (click)="setColumnSort(columnSortDirection.DESC)" [disableRipple]="true" class="w-100 d-flex align-items-center justify-content-start" mat-menu-item>
                <mat-icon fontIcon="arrow_downward" />
                <span>Newest first</span>
            </div>
        </div>
    </mat-menu>

    <mat-menu #columnOptionsMenu class="table-column-options">
        <div [disableRipple]="true" [disabled]="true" mat-menu-item>
            <span class="options-title">Options</span>
        </div>
        <button [disableRipple]="true" [matMenuTriggerFor]="columnManagementMenu" mat-menu-item>
            <span class="w-100">Manage Columns</span>
        </button>
        <button (click)="resetTableColumns()" [disableRipple]="true" [disabled]="tableColumnsInDefaultState" mat-menu-item>
            <span class="w-100">Reset Table to Default</span>
        </button>
    </mat-menu>

    <mat-menu #columnManagementMenu class="table-column-options">
        <div [disableRipple]="true" [disabled]="true" mat-menu-item>
            <span class="options-title">Mange Columns</span>
        </div>
        <div
            (cdkDropListDropped)="reArrangeDragList(expandedColumns, $event)"
            (click)="$event.stopPropagation()"
            (mousedown)="mouseGrabbed = true"
            (mouseup)="mouseGrabbed = false"
            [class.mat-option-grabbing]="mouseGrabbed"
            cdkDropList
        >
            <div (click)="setColumnOnScreenState(column)" *ngFor="let column of expandedColumns" [disableRipple]="true" cdkDrag mat-menu-item>
                <span class="w-100 d-flex flex-row align-items-center">
                    <input [checked]="column.onScreen" type="checkbox" />
                    <span class="ms-2">{{ column.displayName }}</span>
                </span>
            </div>
        </div>
    </mat-menu>
</div>
