<ng-template>
    <button
        (click)="tableButtonClick.emit()"
        [ngClass]="{ 'app-table-buttons-highlight': tableButtonCloseIcon }"
        class="d-flex justify-content-around align-items-center rounded-pill fw-semibold app-table-buttons-filter"
    >
        <mat-icon *ngIf="tableButtonSvgIcon" [svgIcon]="tableButtonSvgIcon" class="app-table-buttons-icon" />
        <mat-icon *ngIf="tableButtonFontIcon" [fontIcon]="tableButtonFontIcon" class="app-table-buttons-icon" />
        <span class="app-table-buttons-text">{{ tableButtonText }}</span>
        <mat-icon (click)="removeFilters.emit(); $event.stopPropagation()" *ngIf="tableButtonCloseIcon" class="app-table-buttons-icon-close" svgIcon="close"></mat-icon>
    </button>
</ng-template>
