import { Pipe, PipeTransform } from '@angular/core';
import { AccountType, Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'accountType',
})
export class AccountTypePipe implements PipeTransform {
    public transform(accountType: Optional<AccountType>): string {
        switch (accountType) {
            case AccountType.PREPAID:
                return 'Prepaid';

            case AccountType.COLLATERAL:
                return 'Collateral';

            case AccountType.CREDIT:
                return 'Credit';

            case AccountType.SECURITY:
                return 'Security';

            default:
                return '';
        }
    }
}
