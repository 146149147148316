import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { TableFilterComponent } from '@lib/modules/table/components';
import { ITableTemplate } from '@lib/modules/table/interfaces';
import { AngularMaterialModule } from 'src/app/material.module';

@Component({
    selector: 'app-filter-wrapper',
    standalone: true,
    templateUrl: './filter-wrapper.component.html',
    styleUrls: ['./filter-wrapper.component.scss'],
    imports: [AngularMaterialModule],
})
export class FilterWrapperComponent implements AfterViewInit {
    protected tableFilterTemplates: Array<TemplateRef<void>> = [];

    @ViewChild('tableFilterTemplate') tableFilterTemplate: TemplateRef<void>;
    @ContentChildren(TableFilterComponent) protected tableFilters: Optional<QueryList<ITableTemplate>>;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}
    ngAfterViewInit() {
        this.createTemplatesFor(this.tableFilters, this.tableFilterTemplates);

        this.changeDetectorRef.detectChanges();
    }

    private createTemplatesFor(tableTemplates: Optional<QueryList<ITableTemplate>>, templateRefList: Array<TemplateRef<void>>): void {
        if (!tableTemplates) return;

        for (const tableTemplate of tableTemplates) {
            if (!tableTemplate.templateRef) continue;

            templateRefList.push(tableTemplate.templateRef);
        }
    }
}
