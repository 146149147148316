import { Pipe, PipeTransform } from '@angular/core';
import { IConstDict, Optional } from '@lib/interfaces';

@Pipe({
    name: 'dictionary',
    standalone: true,
})
export class DictionaryPipe implements PipeTransform {
    public transform(value: Optional<string>, dictionary: Array<IConstDict>): string {
        if (!value) return '';

        const foundValue: Optional<IConstDict> = dictionary.find((entry: IConstDict): boolean => entry.key === value);

        return foundValue ? foundValue.value : '';
    }
}
