import { ICard } from './card.interface';
import {
    APRName,
    APRType,
    ApplicableOn,
    CardProductStatus,
    CardProductSubType,
    CardProductType,
    CashAdvanceLimitType,
    EffectiveFrom,
    FCMethodName,
    FxRateTransactionType,
    InterestTriggerDate,
    MinimumPaymentCalculationMethod,
    PaymentDistributionOrder,
    PaymentTransactionType,
    PinType,
    StatementCycle,
    TransactionTypePriority,
} from '@lib/enums';

export interface ICardProduct {
    id: string;
    description: string;
    status: CardProductStatus;
    currency_settings: Array<ICurrencySetting>;
    credit_settings: ICreditSetting;
    card_settings: ICardSetting;
    account_settings: IAccountSetting;
    pin_settings: IPinSetting;
    auth_settings: IAuthSetting;
    created: string;
    modified: string;
    pan_format_token: string;
    track_format_token: string;
    proxy_format_token: string;
    type: CardProductType;
    sub_type: CardProductSubType;
    is_multi_currency_product: boolean;
    wallet_settings: IWalletSetting;
    fxrates_settings: Array<IFxRatesSetting>;
    metadata?: ICardProductMetadata[];
    apr_profiles?: IAprDetails[];
}

export interface ICurrencySetting {
    supported_currency: string;
    settlement_currency: string;
    priority: number;
    status: CardProductStatus;
    allow_loads: boolean;
    allow_unloads: boolean;
    allow_scheme_debit_txn: boolean;
    allow_scheme_credit_txn: boolean;
    allow_incoming_transfer: boolean;
    allow_outgoing_transfer: boolean;
    allow_incoming_sweeps: boolean;
    allow_outgoing_sweeps: boolean;
    allow_initial_load: boolean;
}

export interface IPaymentSetting {
    payment_trans_types: Array<PaymentTransactionType>;
    payment_distribution_order: PaymentDistributionOrder;
    transaction_type_priority: Array<TransactionTypePriority>;
}

export interface IMinimumPaymentSetting {
    minimum_payment_calculation_method: MinimumPaymentCalculationMethod;
    percentage: number;
    minimum_payment_threshold: number;
}

export interface ICreditSetting {
    statement_cycle: StatementCycle;
    statement_on: number;
    grace_period: number;
    max_credit_limit: number;
    min_credit_limit: number;
    payment_due_on: string;
    cash_adv_limit_type: CashAdvanceLimitType;
    cash_adv_limit_value: number;
    cash_adv_limit_roundup_factor: string;
    allow_account_level_definition: boolean;
    allow_additional_card: boolean;
    payment_settings: IPaymentSetting;
    minimum_payment_settings: IMinimumPaymentSetting;
}

export interface IPanRange {
    start_range: number;
    end_range: number;
}

export interface IAprDetails {
    fc_method_name: FCMethodName;
    apr_value: number;
    apr_name: APRName;
    apr_type: APRType;
    effective_from?: EffectiveFrom;
    effective_date?: string;
    applicable_on?: ApplicableOn;
    interest_trigger_date?: InterestTriggerDate;
    profile_name: string;
    apr_profiles_with_errors?: {
        errors: string[];
    }[];
}

export interface ICardSetting {
    bin: string;
    pan_length: number;
    pan_range: Array<IPanRange>;
    card_validity_term: number;
    service_code: string;
    allow_new_card_creation: boolean;
    num_of_physical_cards_per_user: number;
    num_of_virtual_cards_per_user: number;
    initial_status_of_physical_card: CardProductStatus;
    initial_status_of_virtual_card: CardProductStatus;
    generate_proxy_number: boolean;
    create_account_with_card: boolean;
    design_code: string;
    soft_expiry_period: number;
}

export interface IAccountSetting {
    link_account_to_card: boolean;
    num_of_cards_per_account: number;
}

export interface IPinSetting {
    pin_type: PinType;
    pin_length: number;
    pin_retries: number;
}

export interface IAuthSetting {
    allow_atm: boolean;
    allow_pos: boolean;
    allow_chip: boolean;
    allow_magstripe: boolean;
    allow_chip_fallback: boolean;
    allow_without_pin: boolean;
    allow_ecommerce_non_3DS: boolean;
    allow_ecommerce_3DS: boolean;
    allow_ecommerce_without_CVV: boolean;
    allow_account_verification_without_CVV: boolean;
    allow_quasi_cash: boolean;
    allow_scheme_account_funding: boolean;
    allow_scheme_credits: boolean;
    allow_contactless: boolean;
    allow_initial_load: boolean;
    auth_aging: number;
    atc_threshold: string;
    allowed_mccs_id: string;
    allowed_countries_id: string;
}

export interface IWalletSetting {
    initial_num_of_wallets_offered: number;
    maximum_active_wallets_allowed: number;
    maximum_wallets_allowed_in_sweep: number;
}

export interface IFxRatesSetting {
    transaction_type: FxRateTransactionType;
    rate_group_id: string;
}

export interface ICardProductMetadata {
    content_type: string;
    created: Date;
    file_id: string;
    file_name: string;
    id: string;
    is_default: boolean;
    modified: Date;
    product_id: string;
    size: number;
    skinImgSrc: string;
}

export interface ICardProductData {
    cardProductMetadata: ICardProductMetadata[];
    cardProductId: string;
    card: ICard;
}

export interface ICardSkinMetadata {
    location: string;
    file_path: string;
    file_name: string;
    original_file_name: string;
    content_type: string;
    md5: string;
    etag: string;
    size: number;
    created: Date;
}

export interface ICardSkin {
    content: string;
    id: string;
    user_id: string;
    metadata: ICardSkinMetadata;
}

export interface ICardProductsQueryParams {
    limit: number;
    after: string;
}

export type ICardProductDesignValue = Omit<ICardProductMetadata, 'skinImgSrc'>;

export interface IGetCardProductMetadata {
    product_id: string;
    values: Array<ICardProductDesignValue>;
}

export interface IFxRateGroupListResponse {
    data: Array<IFxRateGroup>;
}

export interface IFxRateGroup {
    id: string;
    providerId: string;
    providerGroupId: string;
    status: CardProductStatus;
    isDefault: boolean;
    created: string;
    modified: string;
}
