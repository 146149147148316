import { Pipe, PipeTransform } from '@angular/core';
import { ChargeFrequency, Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'chargeFrequency',
})
export class ChargeFrequencyPipe implements PipeTransform {
    public transform(value: Optional<ChargeFrequency>): string {
        switch (value) {
            case ChargeFrequency.MONTHLY:
                return 'Monthly';

            case ChargeFrequency.YEARLY:
                return 'Yearly';

            default:
                return '';
        }
    }
}
