import { Component, Input } from '@angular/core';
import { BaseValueFieldComponent, SimpleControlFieldComponent } from '@lib/modules/value-field/base';
import { Nullable, Optional } from '@lib/interfaces';

type ValueFieldType = string;

@Component({
    selector: 'app-value-field-textarea',
    templateUrl: './value-field-textarea.component.html',
    styleUrls: ['./value-field-textarea.component.scss'],
    providers: [{ provide: BaseValueFieldComponent, useExisting: ValueFieldTextareaComponent }],
})
export class ValueFieldTextareaComponent<T extends ValueFieldType> extends SimpleControlFieldComponent<T> {
    @Input() public placeholder = '';
    @Input() public disableErrorMessage = false;

    protected get placeholderText(): string {
        if (this.placeholder) return this.placeholder;

        return this.controlDisplayName;
    }

    protected setControlValue(event: Event): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        const inputValue: string | number = inputElement.value;

        this.control.setValue(inputValue as Optional<Nullable<T>>);

        this.control.markAsDirty();
    }
}
