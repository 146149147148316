<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center">
        <textarea
            (focus)="control.markAsTouched()"
            (input)="setControlValue($event)"
            [attr.placeholder]="placeholderText"
            [class.disabled]="control.disabled"
            [class.input-invalid]="isInvalid(control)"
            [disabled]="control.disabled"
            [id]="createMinifiedName(controlDisplayName)"
            [name]="createMinifiedName(controlDisplayName)"
            [value]="control.value ?? ''"
            class="w-100 textarea value-field-text"
        ></textarea>
        <ul *ngIf="isInvalid(control) && !disableErrorMessage" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>
</ng-template>
