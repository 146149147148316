import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScrollService } from '@lib/services/scroll.service';
import { BaseComponent } from '@lib/components';
import { throttleTime } from 'rxjs';

@Component({
    selector: 'app-table-lazyload',
    templateUrl: './table-lazyload.component.html',
    styleUrls: ['./table-lazyload.component.scss'],
})
export class TableLazyloadComponent extends BaseComponent {
    @Input({ required: true }) public hasMoreRecords = false;

    @Output() public bottomScroll: EventEmitter<void> = new EventEmitter<void>();

    protected showLoader = false;

    public constructor(private readonly scrollService: ScrollService) {
        super();

        this.subscribeToBottomScrollEvent();
    }

    private subscribeToBottomScrollEvent(): void {
        this.addSubscription(
            this.scrollService.scrollBottom.pipe(throttleTime(50)).subscribe((bottomReached: boolean): void => {
                if (!this.hasMoreRecords) {
                    this.showLoader = false;

                    return;
                }

                this.showLoader = bottomReached;

                if (bottomReached) this.bottomScroll.emit();
            }),
        );
    }
}
