import { Pipe, PipeTransform } from '@angular/core';
import { ChannelScope, Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'channelScope',
})
export class ChannelScopePipe implements PipeTransform {
    public transform(value: Optional<ChannelScope>): string {
        switch (value) {
            case ChannelScope.VISA:
                return 'Visa';

            case ChannelScope.MASTERCARD:
                return 'Mastercard';

            case ChannelScope.WESTERNUNION:
                return 'Western Union';

            default:
                return '';
        }
    }
}
