import { IFormGroupSchema, DeepPartial } from './common.interface';

export interface IUser {
    created: string;
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    mobile: string;
    modified: string;
    roles: Array<string>;
    status: EUserStatus;
    two_fa_required: boolean;
    verified_email: string;
    verified_mobile: string;
}

export enum EUserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'SUSPENDED',
    TERMINATED = 'TERMINATED',
}

export interface IAdminProfile {
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    status: EUserStatus;
    two_fa_required: boolean;
}

export interface IUserList {
    data: IUser[];
    after: string;
    has_more: boolean;
}

export type IUserAdvanceFilterFields = Pick<IUserQueryParams, 'first_name' | 'last_name' | 'mobile_number' | 'email'>;

export type IUserQueryParamsForm = IFormGroupSchema<DeepPartial<IUserQueryParams>>;

export interface IUserQueryParams {
    first_name: string;
    last_name: string;
    mobile_number: string;
    email: string;
    status: EUserStatus;
    limit: number;
    after: string;
}

export interface AdvanceSearchModalData {
    filtersChanged: boolean;
    isFilterApplied: boolean;
}
