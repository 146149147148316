import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@lib/components';
import { TColumn, TColumnSort } from '@lib/modules/table/components';
import { ApiResponseError, ISearchBooleanQueryMust, ISearchBooleanQueryMustBoolShould, ISearchWalletResponse, IWalletIndex, Nullable, WalletTypes } from '@lib/interfaces';
import { Permission, WalletType } from '@lib/enums';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CardManagementService } from '@lib/services';
import { NgOptimizedImage } from '@angular/common';
import { TableModule } from '@lib/modules';
import { AngularMaterialModule } from '../../../../material.module';
import { MessageTypePipe, OptionalPlaceholderPipe, SourceChannelPipe, TransactionStatusPipe, TransactionTypePipe } from '@lib/pipes';
import { finalize } from 'rxjs';
import { TransactionIconComponent } from '@lib/svg';
import { ICursorForm, ISearchBooleanQueryForm, ISearchBooleanQueryMustFormArray, ISearchCashbackWalletRequestForm,TransactionSearchService } from '@pages/transaction/services';
import { WalletTypePipe } from "../../../pipes/wallet-type/wallet-type.pipe";
import { Router } from '@angular/router';
import { Utils } from '@lib/utils';

@Component({
    standalone: true,
    selector: 'app-wallet',
    templateUrl: './wallet.component.html',
    styleUrls: ['./wallet.component.scss'],
    imports: [AngularMaterialModule, TableModule, TransactionStatusPipe, OptionalPlaceholderPipe, NgOptimizedImage, TransactionTypePipe, TransactionIconComponent, MessageTypePipe, SourceChannelPipe, WalletTypePipe],
})
export class WalletComponent extends BaseComponent implements OnInit  {
    
    @Input() public filters: Array<ISearchBooleanQueryMust> = [];
    @Input() public isOpenedFromCardOverview = true;
    protected readonly transactionWalletTypes: Array<WalletType> = Object.values(WalletType);
    
    protected columns: Array<TColumn> = [
        {fee_amount: 'Fee Amount' },
        {from_amount:'From Amount'},
        {from_currency: 'From Currency'},
        {id: 'ID'},
        {notes: 'Notes'},
        {recipient_account_id: 'Recipient Account ID'},
        {recipient_alias: 'Recipient Alias'},
        {recipient_mobile: 'Recipient Mobile'},
        {recipient_name: 'Recipient Name'},
        {recipient_user_id: 'Recipient User ID'},
        {rrn:'RRN'},
        {sender_account_id: 'Sender Account ID'},
        {sender_card_last4: 'Sender Card Last Four'},
        {sender_name: 'Sender Name'},
        {sender_user_id: 'Sender User ID'},
        {status_code: 'Status Code' },
        {to_amount: 'To Amount'},
        {to_currency: 'To Currency' },
        {transaction_timestamp: 'Date & Time' },
        {type: 'Type' },
    ];
    protected defaultColumns: Array<string> = ['transaction_timestamp','type','recipient_name','sender_name', 'notes','status_code'];
    protected sortableColumns: Array<string> = [];
    protected wallets: Array<IWalletIndex> = [];
    protected hasMoreRecords = true;
    protected loading = false;
    protected walletHeading: string;
    private apiInProgress = false;
    private searchPayload: FormGroup<ISearchCashbackWalletRequestForm> = this.createSearchWalletFormGroup();

    public constructor(
        private readonly utils: Utils,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly matSnackBar: MatSnackBar,
        private readonly cardManagementService: CardManagementService,
        private readonly transactionSearchService: TransactionSearchService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.walletHeading = `Wallet`;
        this.applyBuiltInFilters();
        this.reFetchWallets();
    }

    protected searchWallets(): void {
        if (this.apiInProgress) return;

        this.apiInProgress = true;

        this.addSubscription(
            this.cardManagementService
                .searchWallet(this.searchPayload.value)
                .pipe(
                    finalize((): void => {
                        this.loading = false;
                        this.apiInProgress = false;
                    }),
                )
                .subscribe({
                    next: ({ data, paging }: ISearchWalletResponse): void => {
                        if (!this.isOpenedFromCardOverview) {
                            this.wallets = data.slice(0, 5);
                            this.hasMoreRecords = false;
                        } else {
                            this.wallets = [...this.wallets, ...data];

                            if (!paging) {
                                this.hasMoreRecords = false;

                                return;
                            }
                            this.searchPayload.controls.cursors?.controls.after?.setValue(paging.cursors.after);
                        }
                    },
                    error: (error: ApiResponseError): void => {
                        this.hasMoreRecords = false;

                        this.createApiErrorSnackBar(this.matSnackBar, error);
                    },
                }),
        );
    }

    protected applySort(sortBy: Nullable<TColumnSort>): void {
        this.clearWallets();
        if(sortBy){
            this.searchPayload.controls.sort_by?.setValue(sortBy.key);
            this.searchPayload.controls.order_by?.setValue(sortBy.sort);
        }
    }

    private reFetchWallets(): void {
        this.loading = true;
        this.hasMoreRecords = true;

        this.searchWallets();
    }

    private clearWallets(): void {
        this.searchPayload.controls.cursors?.setValue({
            after:''
        });
        this.wallets = [];
    }

    private createSearchWalletFormGroup(): FormGroup<ISearchCashbackWalletRequestForm> {
        return this.formBuilder.group<ISearchCashbackWalletRequestForm>({
            cursors:this.formBuilder.group<ICursorForm>({
                after:this.formBuilder.nonNullable.control<string>('')
            }),
            limit: this.formBuilder.nonNullable.control<number>(10),
            sort_by: this.formBuilder.nonNullable.control<string>('transaction_timestamp'),
            order_by: this.formBuilder.nonNullable.control<string>('desc'),
            boolean_query:this.formBuilder.group<ISearchBooleanQueryForm>({
                must:this.formBuilder.array<ISearchBooleanQueryMustFormArray>([])
            })
        });
    }

    private applyBuiltInFilters(): void {
        this.filters.forEach((filter:ISearchBooleanQueryMust) => {
            this.transactionSearchService.addCashbackWalletFilter(this.searchPayload,filter);
        });
    }

    protected applyProductTypeFilter(walletTypes: Array<string>): void {
        this.clearWallets();
        this.searchPayload.controls.boolean_query?.controls.must?.clear();
        let index = this.filters.findIndex((filter) => filter.bool.should.find((shouldFilter) => shouldFilter.fieldName == 'type'));
        let typesArray:Array<ISearchBooleanQueryMustBoolShould> = [];
        walletTypes.forEach((type:string) => {
            let typeObj:ISearchBooleanQueryMustBoolShould = {
                "queryObjectType": "match",
                "fieldName": "type",
                "fieldValue": type
            }
            typesArray.push(typeObj);
        });
        this.filters[index].bool.should = typesArray.length == 0 ? WalletTypes : typesArray;
        this.applyBuiltInFilters();
        this.reFetchWallets();
    }
    protected async goToTransactionDetail(transaction: IWalletIndex): Promise<void> {
        const canViewDetails: boolean = this.utils.getPermissions(Permission.TransactionDetails);

        if (!canViewDetails) {
            this.matSnackBar.open('You do not have permission to view transaction details', 'Close', { duration: 6000 });
            return;
        }

        await this.router.navigate(['transactions', transaction.id]);
    }
}