import { Component, Input } from '@angular/core';
import { BaseValueFieldComponent, SimpleControlFieldComponent } from '@lib/modules/value-field/base';
import { Nullable, Optional } from '@lib/interfaces';

type ValueFieldType = string | number | boolean;
type ValueFieldLiteralType = 'text' | 'number' | 'email' | 'password' | 'date';

@Component({
    selector: 'app-value-field-input',
    templateUrl: './value-field-input.component.html',
    styleUrls: ['./value-field-input.component.scss'],
    providers: [{ provide: BaseValueFieldComponent, useExisting: ValueFieldInputComponent }],
})
export class ValueFieldInputComponent<T extends ValueFieldType> extends SimpleControlFieldComponent<T> {
    @Input() public inputType: ValueFieldLiteralType = 'text';
    @Input() public placeholder = '';
    @Input() public step = 1;
    @Input() public min = -Infinity;
    @Input() public max = Infinity;
    @Input() public minLength = 0;
    @Input() public maxLength = Infinity;
    @Input() public minDate:string|null = null;
    @Input() public isReadOnly: boolean= false;

    protected get placeholderText(): string {
        if (this.placeholder) return this.placeholder;

        return this.controlDisplayName;
    }

    protected setControlValue(event: Event): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        let inputValue: string | number = inputElement.value;

        if (this.inputType === 'number') inputValue = parseFloat(inputValue);

        this.control.setValue(inputValue as Optional<Nullable<T>>);

        this.control.markAsDirty();
    }
}
