import { Component, Input } from '@angular/core';
import { BaseValueFieldComponent, SimpleControlFieldComponent } from '@lib/modules/value-field/base';

@Component({
    selector: 'app-value-field-switch',
    templateUrl: './value-field-switch.component.html',
    styleUrls: ['./value-field-switch.component.scss'],
    providers: [{ provide: BaseValueFieldComponent, useExisting: ValueFieldSwitchComponent }],
})
export class ValueFieldSwitchComponent extends SimpleControlFieldComponent<boolean> {
    @Input() public switchOnColor = '#1A2967';
    @Input() public switchOffColor = '#A4ACB9';
}
