import { Pipe, PipeTransform } from '@angular/core';

export enum CardScheme {
    UNION_PAY_INTERNATIONAL = 'UPI',
    MASTERCARD = 'MASTERCARD',
    VISA = 'VISA',
    DINERS_CLUB_INTERNATIONAL = 'DCI',
    OTHER = 'OTHER',
}

@Pipe({
    standalone: true,
    name: 'cardScheme',
})
export class CardSchemePipe implements PipeTransform {
    public transform(value: string): CardScheme {
        const bin: number = +value;

        if (isNaN(bin)) throw new Error('Invalid BIN');

        if ((bin >= 222100 && bin <= 272099) || (bin >= 510000 && bin <= 559999)) return CardScheme.MASTERCARD;

        if (bin >= 360000 && bin <= 369999) return CardScheme.DINERS_CLUB_INTERNATIONAL;

        if (bin >= 400000 && bin <= 499999) return CardScheme.VISA;

        if (bin >= 620000 && bin <= 629999) return CardScheme.UNION_PAY_INTERNATIONAL;

        return CardScheme.OTHER;
    }
}
