// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: false,
  api_url: 'https://api.stg.platform.ae-1.nymcard.com/',
  version: 'beta',
  apiKey: 'tenant0_api',
  xConsumerUsername: 'tenant0_api',
  agentRoleId: ''
};

export const tenantSetting = [
  { key: 'tenant45', name: 'akeedpay' },
  { key: 'tenant47', name: 'nowmoney' },
  { key: 'tenant46', name: 'beyond' },
  { key: 'tenant44', name: 'shukur' },
  { key: 'tenant43', name: 'yggmgp' },
  { key: 'tenant41', name: 'nublo' },
  { key: 'tenant42', name: 'hesabpay' },
  { key: 'tenant40', name: 'incneo' },
  { key: 'tenant39', name: 'creditperchargeup' },
  { key: 'tenant37', name: 'nasswallet' },
  { key: 'tenant35', name: 'meezan' },
  { key: 'tenant36', name: 'instapay' },
  { key: 'tenant34', name: 'spotii' },
  { key: 'tenant32', name: 'telda' },
  { key: 'tenant33', name: 'mamopay' },
  { key: 'tenant31', name: 'easytip' },
  { key: 'tenant30', name: 'bankkoyuzefoo' },
  { key: 'tenant29', name: 'mercury' },
  { key: 'tenant27', name: 'zywa' },
  { key: 'tenant28', name: 'wally' },
  { key: 'tenant26', name: 'myffin' },
  { key: 'tenant25', name: 'paysky' },
  { key: 'tenant24', name: 'blink' },
  { key: 'tenant23', name: 'jinglepay' },
  { key: 'tenant22', name: 'finwell' },
  { key: 'tenant21', name: 'tenant21' },
  { key: 'tenant20', name: 'f5' },
  { key: 'tenant5', name: 'tenant5' },
  { key: 'tenant19', name: 'crunch' },
  { key: 'tenant18', name: 'samsung' },
  { key: 'tenant15', name: 'tenant15' },
  { key: 'tenant14', name: 'plutocard' },
  { key: 'tenant13', name: 'savii' },
  { key: 'tenant12', name: 'bankiom' },
  { key: 'tenant11', name: 'pemo' },
  { key: 'tenant10', name: 'dinarii' },
  { key: 'tenant9', name: 'nexxopay' },
  { key: 'tenant8', name: 'savmoney' },
  { key: 'tenant7', name: 'tenant7' },
  { key: 'tenant6', name: 'xpence' },
  { key: 'tenant1', name: 'tenant1' },
  { key: 'tenant3', name: 'tenant3' },
  { key: 'tenant2', name: 'tenant2' },
  { key: 'tenant0', name: 'tenant0' },
];

export const customerSettings = {
    fib: 'tenant6',
    samsung: 'tenant18',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
