<div class="tab-limits shadow-none">
    <div class="tab-header d-flex align-items-center justify-content-between border-0">
        <div class="tab-pill-container d-flex rounded-pill p-1">
            <div
                (click)="switchTab(tabIndex)"
                *ngFor="let tabHeader of tabHeaders; index as tabIndex"
                [class.tab-pill-active]="tabIndex === activeTabIndex"
                class="tab-pill fw-semibold pointer d-flex justify-content-center align-items-center px-3"
            >
                <p>{{ tabHeader.tabName }}</p>
            </div>
        </div>
    </div>
    <div *ngIf="activeTabIndex === 0" class="tab-body px-4 position-relative">
        <app-tab-p2p [customerId]="customerId"></app-tab-p2p>
    </div>
    <div *ngIf="activeTabIndex === 1" class="tab-body px-4 position-relative">
        <app-tab-remittance [customerId]="customerId"></app-tab-remittance>
    </div>
    <div *ngIf="activeTabIndex === 2" class="tab-body px-4 position-relative">
        <app-tab-billers [customerId]="customerId"></app-tab-billers>
    </div>
</div>
