import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'transactionStatus',
})
export class TransactionStatusPipe implements PipeTransform {
    public transform(statusCode: Optional<string>): string {
        if (!statusCode) return '';

        if (statusCode.startsWith('0')) return 'Success';

        if (statusCode.startsWith('1')) return 'Failed';

        return '';
    }
}
