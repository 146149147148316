import { Pipe, PipeTransform } from '@angular/core';
import { ChargeType, Optional, Type } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'chargeType',
})
export class ChargeTypePipe implements PipeTransform {
    public transform(value: Optional<ChargeType | Type>): string {
        switch (value) {
            case ChargeType.PHYSICAL_CARD_REPLACEMENT:
                return 'Physical Card Replacement';

            case ChargeType.VIRTUAL_CARD_REPLACEMENT:
                return 'Virtual Card Replacement';

            case ChargeType.MAINTENANCE:
                return 'Maintenance';

            case ChargeType.CHANGE_PIN:
                return 'Change PIN';

            case ChargeType.LATE_PAYMENT:
                return 'Late Payment';

            case ChargeType.DELIVERY:
                return 'Delivery';

            case ChargeType.PHYSICAL_CARD_ISSUANCE:
                return 'Physical Card Issuance';

            case ChargeType.VIRTUAL_CARD_ISSUANCE:
                return 'Virtual Card Issuance';

            case Type.FLAT:
                return 'Flat';

            case Type.PERCENTAGE:
                return 'Percentage';

            case Type.MAX_PERC_OR_FLAT:
                return 'Max Percentage Or Flat';

            case Type.MIN_PERC_OR_FLAT:
                return 'Min Percentage Or Flat';

            default:
                return '';
        }
    }
}
