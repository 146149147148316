import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { DeepPartial } from '@lib/interfaces';
import { IOTPConfiguration, IOTPConfigurationList } from '@lib/interfaces/otp-configuration.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProductSettingsService {
    public environment_url: string;
    public httpClient: HttpClient;

    public constructor(private readonly http: HttpClient, private httpHandler: HttpBackend) {
        this.environment_url = environment.api_url;
        this.httpClient = new HttpClient(httpHandler);
    }

    public fetchOTPSettings(): Observable<IOTPConfigurationList> {
        return this.http.get<IOTPConfigurationList>(`${this.environment_url}nfront/v1/otp/settings`);
    }

    public fetchOTPSettingById(id: string): Observable<IOTPConfiguration> {
        return this.http.get<IOTPConfiguration>(`${this.environment_url}nfront/v1/otp/settings/${id}`);
    }

    public createOTPSetting(payload: DeepPartial<IOTPConfiguration>): Observable<IOTPConfiguration> {
        return this.http.post<IOTPConfiguration>(`${this.environment_url}nfront/v1/otp/settings`, payload);
    }

    public editOTPSetting(id: string, payload: DeepPartial<IOTPConfiguration>): Observable<IOTPConfiguration> {
        return this.http.put<IOTPConfiguration>(`${this.environment_url}nfront/v1/otp/settings/${id}`, payload);
    }
}
