import { Pipe, PipeTransform } from '@angular/core';
import { CardType, Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'cardType',
})
export class CardTypePipe implements PipeTransform {
    public transform(value: Optional<CardType>): string {
        switch (value) {
            case CardType.PHYSICAL:
                return 'Physical';

            case CardType.VIRTUAL:
                return 'Virtual';

            default:
                return '';
        }
    }
}
