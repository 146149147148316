export enum Duration {
    Day = 'day',
    Month = 'month',
    Quarter = 'quarter',
    HalfYear = 'halfyear',
    Year = 'year',
}

export enum GraphType {
    Count = 'count',
    Amount = 'amount',
}

export enum Status {
    Approved = 'approved',
    Declined = 'declined',
    Settled = 'settled',
}
