import { NgOptimizedImage } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { AngularMaterialModule } from 'src/app/material.module';

type TSpinnerMinHeight = 'fit-content' | `${number}rem`;

@Component({
    selector: 'app-spinner',
    standalone: true,
    imports: [AngularMaterialModule, NgOptimizedImage],
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
    @Input({ required: true }) public isLoading = false;
    @Input() public minHeight: TSpinnerMinHeight = '25rem';

    @HostBinding('style.min-height')
    public get hostMinHeight(): TSpinnerMinHeight {
        return !this.isLoading ? '0rem' : this.minHeight;
    }
}
