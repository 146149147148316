import { Directive, Input } from '@angular/core';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { CdkCellDef } from '@angular/cdk/table';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[matCellDef]',
    providers: [{ provide: CdkCellDef, useExisting: TypedMatCellDefDirective }],
})
export class TypedMatCellDefDirective<T> extends MatCellDef {
    @Input({ required: true }) public matCellDefDataSource!: Array<T> | Observable<Array<T>> | MatTableDataSource<T>;

    static ngTemplateContextGuard<T>(dir: TypedMatCellDefDirective<T>, ctx: unknown): ctx is { $implicit: T; index: number } {
        return true;
    }
}
