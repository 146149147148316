<ng-template class="app-table-filter">
    <button
        [matMenuTriggerFor]="tableFilterMenu"
        [matTooltip]="filterText"
        [ngClass]="{ 'app-table-buttons-highlight': appliedFilters.length }"
        class="d-flex justify-content-around align-items-center rounded-pill fw-semibold app-table-buttons-filter"
    >
        <mat-icon *ngIf="filterSvgIcon" [svgIcon]="filterSvgIcon" class="app-table-buttons-icon" />
        <mat-icon *ngIf="filterFontIcon" [fontIcon]="filterFontIcon" class="app-table-buttons-icon" />
        <span class="app-table-buttons-text">{{ filterText }}</span>
        <mat-icon (click)="removeFilters($event)" *ngIf="appliedFilters.length" class="app-table-buttons-icon-close" svgIcon="close"></mat-icon>
    </button>
    <mat-menu #tableFilterMenu class="table-column-options">
        <div disableRipple disabled mat-menu-item>
            <span class="options-title">{{ filterName }}</span>
        </div>
        <div (click)="$event.stopPropagation()">
            <div (click)="removeAllFilters()" *ngIf="filterType === 'radio'" disableRipple mat-menu-item>
                <span class="w-100 d-flex flex-row align-items-center">
                    <input [checked]="noFilterSet" type="radio" />
                    <span class="ms-2">All</span>
                </span>
            </div>
            <div (click)="setFilter(filterIndex)" *ngFor="let tableFilter of filterForm.controls.filters.controls; index as filterIndex" disableRipple mat-menu-item>
                <span class="w-100 d-flex flex-row align-items-center">
                    <input [checked]="tableFilter.controls.checked.value" [type]="filterType" [value]="tableFilter.controls.value.value" />
                    <span class="ms-2">
                        <ng-container [ngTemplateOutlet]="tableFilter.controls.template.value"></ng-container>
                    </span>
                </span>
            </div>
        </div>
        <div *ngIf="filterForm.dirty" class="mt-2 filter-actions" disableRipple mat-menu-item>
            <div class="w-100 my-2 d-flex flex-row align-items-center">
                <button (click)="cancelFilter()" class="mx-1 rounded-pill filter-actions-cancel" mat-flat-button>
                    <span class="px-2 text-black">Cancel</span>
                </button>
                <button (click)="applyFilter()" class="mx-1 rounded-pill filter-actions-apply" mat-flat-button>
                    <span class="px-2 text-white">Apply</span>
                </button>
            </div>
        </div>
    </mat-menu>
</ng-template>
