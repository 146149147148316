import { Directive, Input, TemplateRef } from '@angular/core';

export interface IValueFieldOption<T> {
    value: T;
    templateRef: TemplateRef<void>;
}

@Directive({
    selector: '[appValueFieldOption]',
})
export class ValueFieldOptionDirective<T> {
    @Input({ required: true }) public appValueFieldOption: T;

    public constructor(public readonly templateRef: TemplateRef<void>) {}
}
