export interface IPasswordPolicy {
    id?: string;
    type?: PolicyType;
    name: string;
    description: string;
    is_active: boolean;
    is_default?: boolean;
    settings: IPolicySetting;
    rules: Array<IPolicyRule>;
}
export interface IPasscodePolicy {
    id?: string;
    type?: PolicyType;
    name: string;
    description: string;
    is_active: boolean;
    is_default?: boolean;
    settings: IPolicySetting;
    rules: Array<IPolicyRule>;
}

export enum PolicyStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export enum PolicyType {
    PASSWORD = 'PASSWORD',
    PASSCODE = 'PASSCODE',
}

export interface IPolicySetting {
    password_expiration_days: number;
    password_reuse_restriction_number: number;
    invalid_login_attempts_number: number;
    invalid_login_attempts_lock_minutes: number;
    min_length: number;
    max_length: number;
}

export interface IPolicyRule {
    name: string;
    description: string;
    regex: string;
}
