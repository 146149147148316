<mat-drawer-container class="app-container">
    <mat-drawer #matDrawer [mode]="'side'" [opened]="!isWelcomeScreen" class="app-side-nav" [ngClass]="{ collapsed: isCollapseNav }">
        <app-side-nav (collapseStatusChange)="onCollapseStatusChange($event)"></app-side-nav>
    </mat-drawer>
    <mat-drawer-content
        #matDrawerContent
        (scroll)="matDrawerContentScroll($event)"
        [style.margin-left.px]="isWelcomeScreen ? 0 : isCollapseNav ? 70 : 240"
        class="drawer-content"
    >
        <app-header (open)="matDrawer.toggle()" *ngIf="!isWelcomeScreen"></app-header>
        <div [ngClass]="{ 'main-body': !isWelcomeScreen }">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
