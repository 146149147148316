import { Component, EventEmitter, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../material.module';
import { MenuItem } from '@lib/interfaces/menu-items.interface';
import { SettingsService } from '@lib/services/settings/settings.service';
import { environment } from '@env/environment';
import { Permission } from '@lib/enums/permissions.enum';
import { lowerMenuItems, menuItems } from '@lib/constants/sidemenu.constants';
@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, AngularMaterialModule],
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
    @Output() collapseStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    isCollapseNav = false;

    tenantKey='';
    imgUrl = '';
    activeTabForTenant0 =['Call Center','Customers','Cards','Access Management']
    version = environment.version;
    constructor(private router: Router, public settingService: SettingsService) {
         this.tenantKey = localStorage.getItem('tenantKey') || '';
         this.activeTabForTenant0 =   localStorage.getItem('id') =='administrator' ?  ['Call Center','Customers','Cards','Access Management'] : ['Call Center','Customers','Cards'];
        if (this.tenantKey.startsWith('tenant37')) {
            this.imgUrl = '../../assets/tenant37/logo.png';
        } else {
            this.imgUrl = '../../assets/logo-white.png';
            // this.settingService.settingsDetails.subscribe({
            //   next: (item: any) => {
            //     this.imgUrl = item.logo_file_name;
            //   },
            // });
        }
    }
    panelOpenState = false;
    menuItems = menuItems;
    lowerMenuItems = lowerMenuItems;

    checkExpandedItem(item: string): boolean {
        return this.router.url.includes(item);
    }

    collapseNav() {
        this.isCollapseNav = true;
        this.collapseStatusChange.emit(this.isCollapseNav);
    }

    unCollapseNav() {
        this.isCollapseNav = false;
        this.collapseStatusChange.emit(this.isCollapseNav);
    }

    toggleCollapseNav() {
        this.isCollapseNav = !this.isCollapseNav;
        this.collapseStatusChange.emit(this.isCollapseNav);
    }

    handleMenuClick(item: MenuItem) {
        if (this.isCollapseNav && item.title === 'Settings') {
            this.router.navigate(['/control-center/settings']);
        }
    }

    handleExpandClick(item: MenuItem, event: MouseEvent) {
        if (this.isCollapseNav && item.title === 'Settings') {
            event.stopPropagation();
            this.router.navigate(['/control-center/settings']);
        }
    }
}
