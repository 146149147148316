<div
    class="status-wrapper"
    [title]="statusEnumLabels[status ?? '']"
    [ngClass]="{
        'status-active': status === 'ACTIVE' || status === 'VERIFIED',
        'status-inactive': status === 'INACTIVE',
        'status-suspended': status === 'SUSPENDED' || status === 'INACTIVE',
        'status-blocked': status === 'TERMINATED' || status === 'BLOCKED',
        'status-locked': status === 'LOCK',
        'status-pendingkyc': status === 'PENDINGKYC',
        'status-pendingidv': status === 'PENDINGIDVERIFICATION',
        'status-pendingkyb': status === 'PENDINGKYB'
    }"
>
    <div class="d-flex align-items-center" [ngClass]="{ 'center-text': isCenterText }">
        <div class="status" *ngIf="!svgIcon; else iconBlock"></div>
        <div class="status-text">{{ statusEnum[status ?? ''] }}</div>
        <div *ngIf="!statusEnum[status ?? '']" class="placeholder">{{ placeholder }}</div>
    </div>
    <mat-icon *ngIf="isDropdown && hasPermission && statusEnum[status ?? '']" class="arrow-down-secondary" svgIcon="arrow-down-secondary"></mat-icon>
</div>
<ng-template #iconBlock>
    <mat-icon class="done" svgIcon="verified"></mat-icon>
</ng-template>
