import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { ParseUnderscoreStringPipe } from '@lib/pipes';
import { Key, Nullable, Optional } from '@lib/interfaces';
import { BaseComponent } from '@lib/components/base';
import { TableButtonComponent, TableFilterComponent } from '@lib/modules/table/components';
import { TableColumnDirective, TableIconDirective } from '@lib/modules/table/directives';
import { ITableTemplate } from '@lib/modules/table/interfaces';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export type TColumnTemplate<T> = { preventAction: boolean; templateRef: TemplateRef<{ $implicit: T }> };
export type TExpandedColumn = { key: string; displayName: string; onScreen: boolean };
export type TColumnSort = Omit<TExpandedColumn, 'onScreen'> & { sort: SortDirection };
export type TColumnDefaultSort = Omit<TColumnSort, 'displayName'>;
export type TColumn = string | Record<string, string>;
export type TDataSource<T> = Array<T> | Observable<Array<T>>;

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> extends BaseComponent implements OnInit, AfterContentInit, AfterViewInit {
    @Input({ required: true }) public dataName = 'Data';
    @Input({ required: true }) public dataSource: TDataSource<T> = [];
    @Input({ required: true }) public dataColumns: Array<TColumn> = [];
    @Input({ required: true }) public dataLoading = false;
    @Input() allRecordsSelectedChanged = false;
    @Input() public svgIcon: Optional<string>;
    @Input() public fontIcon: Optional<string>;
    @Input() public dataHasMoreRecords = false;
    @Input() public dataAbsenceText = 'No data';
    @Input() public dataDefaultColumns: Array<string> = [];
    @Input() public dataSortableColumns: Array<string> = [];
    @Input() public dataDefaultSort: Nullable<TColumnDefaultSort> = null;
    @Input() public dataShowActionIcon = false;
    @Input() public allRecordsProcessed = false;
    @Output() public dataRowAction: EventEmitter<T> = new EventEmitter<T>();
    @Output() public dataLoadMore: EventEmitter<void> = new EventEmitter<void>();
    @Output() public dataSort: EventEmitter<Nullable<TColumnSort>> = new EventEmitter<Nullable<TColumnSort>>();
    @Output() public checkboxChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() allRecordsSelectedChangedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ContentChild(TableIconDirective) protected tableIcon: Optional<TableIconDirective>;
    @ContentChildren(TableFilterComponent) protected tableFilters: Optional<QueryList<ITableTemplate>>;
    @ContentChildren(TableButtonComponent) protected tableButtons: Optional<QueryList<ITableTemplate>>;
    @ContentChildren(TableColumnDirective) protected tableColumns: Optional<QueryList<TableColumnDirective<T>>>;

    @ViewChild('matTable') matTable!: ElementRef;
    
    protected readonly actionColumnKey = 'action';
    protected expandedColumns: Array<TExpandedColumn> = [];
    protected readonly columnTemplates: Map<string, TColumnTemplate<T>> = new Map<string, TColumnTemplate<T>>();

    protected expandedSortableColumns: Array<TExpandedColumn> = [];
    protected readonly columnSortDirection: typeof SortDirection = SortDirection;
    protected currentColumnSort: Nullable<TColumnSort> = null;

    protected currentOnScreenColumns: Array<string> = [];
    protected tableColumnsInDefaultState = true;

    protected tableFilterTemplates: Array<TemplateRef<void>> = [];
    protected tableButtonTemplates: Array<TemplateRef<void>> = [];

    protected mouseGrabbed = false;
    protected get columnSortText(): string {
        const columnSortText = 'Sort by';

        if (!this.currentColumnSort) return columnSortText;

        return `${columnSortText} ${this.currentColumnSort.displayName} ${this.currentColumnSort.sort === SortDirection.ASC ? '(Oldest first)' : '(Newest first)'}`;
    }

    public constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.resetTableColumns();

        this.expandSortableColumns();

        this.setDefaultColumnSort();
    }

    public ngAfterContentInit(): void {
        this.createOverriddenColumnsTemplateMap();
    }

    public ngAfterViewInit(): void {
        this.createTemplatesFor(this.tableFilters, this.tableFilterTemplates);

        this.createTemplatesFor(this.tableButtons, this.tableButtonTemplates);

        this.scrollToTop();

        this.changeDetectorRef.detectChanges();
    }

    protected reArrangeDragList<TList>(list: Array<TList>, event: CdkDragDrop<void>): void {
        moveItemInArray(list, event.previousIndex, event.currentIndex);
    }

    protected hasColumnTemplate(column: string): boolean {
        return this.columnTemplates.has(column);
    }

    protected getColumnTemplate(column: string): TColumnTemplate<T> {
        const columnTemplate: Optional<TColumnTemplate<T>> = this.columnTemplates.get(column);

        if (columnTemplate) return columnTemplate;

        throw new Error(`Column template with column name "${column}" not found!`);
    }

    protected getColumnValue(row: T, column: string): string {
        return <string>row[<Key<T>>column];
    }

    protected setColumnOnScreenState(column: TExpandedColumn): void {
        this.tableColumnsInDefaultState = false;

        if (column.onScreen) {
            const indexOfCurrentColumnOnScreen: number = this.currentOnScreenColumns.indexOf(column.key);
            this.currentOnScreenColumns.splice(indexOfCurrentColumnOnScreen, 1);
        } else {
            this.currentOnScreenColumns.splice(this.dataShowActionIcon ? -2 : -1, 0, column.key);
        }

        this.expandedColumns = this.expandedColumns.map((expandedColumn: TExpandedColumn): TExpandedColumn => {
            if (expandedColumn.key !== column.key) return expandedColumn;

            expandedColumn.onScreen = !column.onScreen;

            return expandedColumn;
        });
    }

    protected resetTableColumns(): void {
        this.tableColumnsInDefaultState = true;

        this.expandColumns();

        this.setDefaultCurrentColumnsOnScreen();
    }

    protected setColumnSort(): void;
    protected setColumnSort(key: string, displayName: string, sortDirection?: SortDirection): void;
    protected setColumnSort(key: SortDirection): void;
    protected setColumnSort(key?: string | SortDirection, displayName?: string, sortDirection: SortDirection = SortDirection.ASC): void {
        if (!key) this.currentColumnSort = null;

        if (key === SortDirection.ASC || key === SortDirection.DESC) {
            if (!this.currentColumnSort) return;

            this.currentColumnSort.sort = key;
        }

        if (typeof key === 'string' && displayName) this.currentColumnSort = { key, displayName, sort: sortDirection };

        this.dataSort.emit(this.currentColumnSort);
    }

    protected triggerRowAction(columnKey: Nullable<string>, dataRow: T): void {
        if (!columnKey || !this.hasColumnTemplate(columnKey)) return this.dataRowAction.emit(dataRow);

        const column: TColumnTemplate<T> = this.getColumnTemplate(columnKey);
        if (column.preventAction) return;

        this.dataRowAction.emit(dataRow);
    }

    protected createColumnFromAutoDisplayNameColumnType(column: string): TExpandedColumn {
        const displayName: string = new ParseUnderscoreStringPipe().transform(column);

        return {
            key: column,
            displayName,
            onScreen: this.dataDefaultColumns.includes(column),
        };
    }

    protected setDefaultColumnSort(): void {
        if (!this.dataDefaultSort) return;

        const sortableColumn: Optional<TExpandedColumn> = this.expandedSortableColumns.find((sortableColumn: TExpandedColumn): boolean => sortableColumn.key === this.dataDefaultSort?.key);

        if (!sortableColumn) return;

        this.currentColumnSort = { key: sortableColumn.key, displayName: sortableColumn.displayName, sort: this.dataDefaultSort.sort };
    }

    private createTemplatesFor(tableTemplates: Optional<QueryList<ITableTemplate>>, templateRefList: Array<TemplateRef<void>>): void {
        if (!tableTemplates) return;

        for (const tableTemplate of tableTemplates) {
            if (!tableTemplate.templateRef) continue;

            templateRefList.push(tableTemplate.templateRef);
        }
    }

    private createOverriddenColumnsTemplateMap(): void {
        if (!this.tableColumns) return;

        this.tableColumns.forEach(({ appTableColumn, templateRef, preventAction }: TableColumnDirective<T>): void => {
            this.columnTemplates.set(appTableColumn, { templateRef, preventAction });
        });
    }

    private expandColumns(): void {
        this.expandedColumns = this.dataColumns.map((dataColumn: TColumn): TExpandedColumn => {
            if (typeof dataColumn === 'string') {
                return this.createColumnFromAutoDisplayNameColumnType(dataColumn);
            }

            return this.createColumnFromCustomDisplayNameColumnType(dataColumn);
        });
    }

    private expandSortableColumns(): void {
        this.expandedSortableColumns = this.expandedColumns.filter((expandedColumn: TExpandedColumn): boolean => this.dataSortableColumns.includes(expandedColumn.key));
    }

    private setDefaultCurrentColumnsOnScreen(): void {
        this.currentOnScreenColumns = this.dataDefaultColumns.length === 0 ? this.expandedColumns.map((column: TExpandedColumn): string => column.key) : [...this.dataDefaultColumns];

        if (this.dataShowActionIcon) this.currentOnScreenColumns = [...this.currentOnScreenColumns, this.actionColumnKey];
    }

    private createColumnFromCustomDisplayNameColumnType(column: Record<string, string>): TExpandedColumn {
        const columnKey: string = Object.keys(column)[0];

        return {
            key: columnKey,
            displayName: column[columnKey],
            onScreen: this.dataDefaultColumns.includes(columnKey),
        };
    }

    protected isObjectTypeColumn(columnKey: string): boolean {
        if (columnKey === 'checkbox') return true;
        else return false;
    }

    public onCheckboxChange(event: any): void {
        this.checkboxChanged.emit(event.target.checked);
    }

    public emitAllRecordsSelectedChanged(value: boolean): void {
        this.allRecordsSelectedChangedChange.emit(value);
    }

    protected scrollToBottom() {
        // this.dataLoadMore.emit(); // only fetches more data
        const lastRow = this.matTable.nativeElement.querySelector('tbody').querySelector('tr:last-child');
        if (lastRow) {
            lastRow.scrollIntoView({ behavior: 'smooth' });
        };

    }

    protected scrollToTop() {
        document.querySelector(".header")?.scrollIntoView();
    }
}
