import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../../../material.module';
import { OptionalPlaceholderPipe } from '@lib/pipes';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SpinnerComponent } from '@lib/components/spinner/spinner.component';
import { TableButtonComponent, TableComponent, TableFilterComponent, TableLazyloadComponent } from '@lib/modules/table/components';
import { TableColumnDirective, TableFilterDirective, TableIconDirective, TypedMatCellDefDirective } from '@lib/modules/table/directives';

@NgModule({
    imports: [AngularMaterialModule, DragDropModule, OptionalPlaceholderPipe, SpinnerComponent],
    declarations: [TableComponent, TableFilterComponent, TableButtonComponent, TableLazyloadComponent, TableColumnDirective, TableFilterDirective, TypedMatCellDefDirective, TableIconDirective],
    exports: [TableComponent, TableFilterComponent, TableButtonComponent, TableLazyloadComponent, TableColumnDirective, TableFilterDirective, TypedMatCellDefDirective, TableIconDirective],
})
export class TableModule {}
