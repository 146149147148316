import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ConfirmDialog {
    title: string;
    description: string;
    confirmBtnText: string;
    cancelBtnText: string;
}
@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialog, public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

    protected setConfirmed(isConfirmed: boolean) {
        this.dialogRef.close(isConfirmed);
    }
}
