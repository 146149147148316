import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
    standalone: true,
    selector: '[appBackButton]',
})
export class BackButtonDirective {
    public constructor(private readonly location: Location) {}

    @HostListener('click')
    public buttonClick(): void {
        this.location.back();
    }
}
