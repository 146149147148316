<div class="bank-transfer-table">
    <app-table
        [dataColumns]="columns"
        [dataDefaultColumns]="defaultColumns"
        [dataHasMoreRecords]="hasMoreRecords"
        [dataLoading]="bankTransferListLoading"
        [dataSource]="bankTransferList"
        class="card-table"
        dataAbsenceText="No Bank Transfers"
        dataName="Bank Transfers"
        svgIcon="card-table"
    >
        <ng-template [appTableColumnDataSource]="bankTransferList" appTableColumn="created" let-card>
            <p>{{ card.created | date }}</p>
        </ng-template>
    </app-table>
</div>
