import { Pipe, PipeTransform } from '@angular/core';
import { Nullable, Optional } from '@lib/interfaces';

@Pipe({
    standalone: true,
    name: 'optionalPlaceholder',
})
export class OptionalPlaceholderPipe implements PipeTransform {
    public transform<T extends string | number | boolean>(value: Nullable<Optional<T>>): T {
        if (value === undefined || value === null) return <T>'N/A';

        if (typeof value === 'string' && value.trim() === '') return <T>'N/A';

        if (typeof value === 'number' && isNaN(value)) return <T>'N/A';

        return value;
    }
}
