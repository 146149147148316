import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@lib/interfaces';
import { MinimumPaymentCalculationMethod } from '@lib/enums';

@Pipe({
    standalone: true,
    name: 'minPaymentCalcMethod',
})
export class MinPaymentCalcMethodPipe implements PipeTransform {
    public transform(value: Optional<MinimumPaymentCalculationMethod>): string {
        switch (value) {
            case MinimumPaymentCalculationMethod.PERCENTAGE_OF_STATEMENT_BALANCE:
                return 'Percentage Of Statement Balance';

            default:
                return '';
        }
    }
}
