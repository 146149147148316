import { Pipe, PipeTransform } from '@angular/core';
import { Period, TransactionScope } from '@lib/interfaces';
import { TransactionScopePipe } from '@lib/pipes/transaction-scope/transaction-scope.pipe';
import { PeriodPipe } from '@lib/pipes/period/period.pipe';

interface ITransactionScopeInput {
    period: Period;
    transaction_scope: Array<TransactionScope>;
}

@Pipe({
    name: 'velocityLimitSubtitle',
    standalone: true,
})
export class VelocityLimitSubtitlePipe implements PipeTransform {
    public transform({ period, transaction_scope }: ITransactionScopeInput): string {
        const periodPipe: PeriodPipe = new PeriodPipe();
        const transactionScopePipe: TransactionScopePipe = new TransactionScopePipe();

        const periodString: string = periodPipe.transform(period);
        const transactionScopeString: string = transactionScopePipe.transform(transaction_scope);

        return `${periodString} ${transactionScopeString}`;
    }
}
