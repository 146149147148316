export interface IAllowedCountryListQueryParams {
    limit: number;
    after: string;
}

export interface IAllowedMccListQueryParams {
    limit: number;
    after: string;
    name: string;
    mcc_id: string;
}

export interface IAllowedMccItem {
    mcc_id: string;
    allow: boolean;
}

export interface IAllowedCountry {
    id: string;
    name: string;
    allow: boolean;
    active: boolean;
    country_codes: Array<string>;
    created: string;
    modified: string;
}

export interface IAllowedMcc {
    id: string;
    name: string;
    active: boolean;
    mccs: Array<IAllowedMccItem>;
    created: string;
    modified: string;
}

export type IMutateAllowedMcc = Omit<IAllowedMcc, 'created' | 'modified'>;

export type IMutateAllowedCountry = Omit<IAllowedCountry, 'created' | 'modified'>;
