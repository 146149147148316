import { Pipe, PipeTransform } from '@angular/core';
import { OperationCode } from '@lib/interfaces/otp-configuration.interface';
import { Optional } from '@lib/interfaces';

@Pipe({
    name: 'otpOperationCode',
    standalone: true,
})
export class OtpOperationCodePipe implements PipeTransform {
    public transform(value: Optional<OperationCode>): string {
        switch (value) {
            case OperationCode.ACTIVATE_CARD:
                return 'Activate Card';

            case OperationCode.CHANGE_PIN:
                return 'Change PIN';

            case OperationCode.FUNDS_TRANSFER:
                return 'Funds Transfer';

            case OperationCode.LOGIN:
                return 'Login';

            case OperationCode.RESET_PASSWORD:
                return 'Reset Password';

            case OperationCode.REVEAL_CARD:
                return 'Reveal Card';

            case OperationCode.SET_PASSWORD:
                return 'Set Password';

            case OperationCode.SHOW_PIN:
                return 'Show PIN';

            case OperationCode.VERIFY_USER:
                return 'Verify User';

            case OperationCode.DEFAULT:
                return 'Default';

            case OperationCode.REACTIVATE_CARD:
                return 'Reactivate Card';

            case OperationCode.BLOCK_CARD:
                return 'Block Card';

            case OperationCode.MC_CROSS_BORDER:
                return 'MC Cross Border';

            case OperationCode.BILLERS_PAYMENT:
                return 'Billers Payment';

            case OperationCode.CL_TOPUP:
                return 'CL Topup';

            case OperationCode.CL_FIRSTTOPUP:
                return 'CL First Topup';

            case OperationCode.CL_WITHDRAWAL:
                return 'CL Withdrawal';

            case OperationCode.USER_ALIAS_CREATE:
                return 'User Alias Create';

            case OperationCode.USER_ALIAS_CHANGE:
                return 'User Alias Change';

            case OperationCode.CHECKOUT_PAYMENT:
                return 'Checkout Payment';

            case OperationCode.UPDATE_BALANCE_LIMIT:
                return 'Update Balance Limit';

            case OperationCode.UPDATE_VELOCITY_LIMIT:
                return 'Update Velocity Limit';

            default:
                return '';
        }
    }
}
