import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-limit-widget',
    standalone: true,
    imports: [CommonModule, MatProgressBarModule, MatSlideToggleModule, FormsModule],
    templateUrl: './limit-widget.component.html',
    styleUrls: ['./limit-widget.component.scss'],
})
export class LimitWidgetComponent implements OnInit {
    @Input() limit: any;
    @Input() isToggleButton = false;
    @Input() matToggleValue = false;
    @Input() isHideProgressBar = true;
    @Input() isHideLeftLimit = true;
    @Output() toggleMatSlideButton = new EventEmitter<any>();
    color: string | undefined;
    consumedLimitPercentage: number | undefined;
    leftLimit: number | undefined;

    ngOnInit(): void {
        this.consumedLimitPercentage = (100 * this.limit.consumed_limit) / this.limit.max_amount;

        if (this.consumedLimitPercentage < 50) this.color = 'accent';
        if (this.consumedLimitPercentage > 50) this.color = 'primary';
        if (this.consumedLimitPercentage > 80) this.color = 'warn';

        this.leftLimit = this.limit.max_amount - this.limit.consumed_limit;
    }
}
